import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    buttonNavigate: {
      background: theme.palette.forms.buttonMenuBg,
      borderRadius: 12,
      padding: 16,
      color: '#51136C',
      height: '100%',
      maxWidth: 300,
      minHeight: 150,
      '&:hover': {
        background: theme.palette.forms.buttonMenuBg,
        opacity: 0.8,
      },
    },
    imgButton: {
      marginBottom: 16,
      width: '100%',
      height: 'auto',
      maxWidth: 100,
    },
  }),
  { index: 1 },
);

export default useStyles;
