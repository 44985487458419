import { makeStyles } from '@mui/styles';
import { styles } from './Styles';

const useStyles = makeStyles(
  () => ({
    msgBox: {
      color: '#454545',
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 15,
    },
    marginButton: {
      marginTop: 24,
    },
    ...styles,
  }),
  { index: 1 },
);

export default useStyles;
