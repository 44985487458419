import React from 'react';
import { useLoadingStyles } from 'styles';

const Loading = () => {
  const styles = useLoadingStyles();

  return (
    <div className={styles.loadingBox}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default Loading;
