import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    mainBox: {
      marginTop: '-80px !important',
      padding: 40,
      paddingTop: 80,
      background: 'linear-gradient(180deg, #8825B2 0%, #003E82 100%)',
      paddingBottom: 40,
      minHeight: '100vh',
      display: 'flex',
      '@media only screen and (max-width : 1000px)': {
        marginTop: '0px !important',
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    traumappDesktopImage: {
      width: '100%',
      minWidth: 300,
      [theme.breakpoints.down('sm')]: {
        width: '80%',
      },
    },
    columnsBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: 20,
      },
    },
    titleDesktop: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: 32,
      letterSpacing: '0.2em',
      color: '#FFF',
      marginBottom: 30,
      '@media only screen and (max-width : 900px)': {
        fontSize: 28,
      },
      '@media only screen and (max-width : 850px)': {
        fontSize: 26,
      },
    },
    dscDesktop: {
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: 18,
      letterSpacing: '0.03em',
      color: '#FFF',
      lineHeight: '32px',
      '@media only screen and (max-width : 900px)': {
        fontSize: 16,
      },
    },
    separator: {
      width: '50%',
      borderColor: '#51136C',
      margin: '30px 0px',
    },
    otherVersions: {
      fontWeight: '500',
      fontSize: 16,
      color: '#FFF',
      textDecoration: 'underline',
      transition: '0.5s',
      '&:hover': {
        color: '#FFFFFF90',
      },
      '@media only screen and (max-width : 900px)': {
        fontSize: 14,
      },
    },
    buttonPrincipal: {
      background: '#49ABED',
      color: '#FFF',
      width: '90%',
      minWidth: 'fit-content',
      padding: '15px 35px',
      '&:hover': {
        background: '#49ABED90',
      },
    },
    buttonDownloadAny: {
      background: '#B459DB',
      color: '#FFF',
      width: 150,
      padding: 15,
      marginTop: 25,
      '&:hover': {
        background: '#B459DB90',
      },
    },
    containerBox: {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        flexWrap: 'initial',
      },
    },
    systemIcons: {
      height: 30,
      cursor: 'pointer',
      margin: 10,
      marginTop: 20,
    },
    mobileDetected: {
      color: '#FF6262',
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 30,
    },
  }),
  { index: 1 },
);

export default useStyles;
