import React from 'react';
import { Typography } from '@mui/material';

const Paragraph = ({ children, className, ...props }) => {
  return (
    <Typography
      component="p"
      variant="body1"
      className={className}
      align="justify"
      {...props}>
      {children}
    </Typography>
  );
};

export default Paragraph;
