import axios from 'axios';
import { objectToFormData } from './index';

const responseHandler = response => {
  const { data, status } = response;

  if (status === 200) {
    return data;
  }

  return Promise.reject({ data, status });
};

const API = {};
const baseURL = `${process.env.REACT_APP_API}/~api/`;

const axiosInstance = axios.create({
  baseURL,
  validateStatus() {
    return true;
  },
});

axiosInstance.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.headers['Content-Type'] = 'multipart/form-data';
      config.data = objectToFormData(config.data);
    }

    return config;
  },
  error => Promise.reject(error),
);
axiosInstance.interceptors.response.use(responseHandler, error => {
  let msg = 'Error Interno';

  if (error.toJSON().message === 'Network Error') {
    msg = 'Error de conexión';
  }
  return Promise.reject({ data: { msg }, status: 504 });
});

// AUTH
API.login = params => {
  return axiosInstance.post(`loginweb/login`, params);
};

API.sendCodeForgotPassword = params => {
  return axiosInstance.post(`loginweb/forgot`, params);
};

API.resendValidationCode = params => {
  return axiosInstance.post(`loginweb/resend`, params);
};

API.activateCodeValidation = params => {
  return axiosInstance.post(`loginweb/activate`, params);
};

API.changeUserPassword = params => {
  return axiosInstance.post(`loginweb/change`, params);
};

API.InsertInitialRegister = params => {
  return axiosInstance.post(`loginweb/insert`, params);
};

API.userRegister = params => {
  return axiosInstance.post(`loginweb/saveregister`, params);
};

API.logout = params => axiosInstance.post(`loginweb/logout`, params);
API.validateSessionById = sessionid =>
  axiosInstance.get(`users/sessionvalid?sessionid=${sessionid}`);

// USER
API.getUserEntry = sessionid =>
  axiosInstance.get(`users/entry?sessionid=${sessionid}`);
API.getUserResume = sessionid =>
  axiosInstance.get(`users/resume?sessionid=${sessionid}`);
API.updateUserPassword = params =>
  axiosInstance.post(`users/updatepwd`, params);
API.updateUserBySessionId = params =>
  axiosInstance.post(`users/update`, params);

// CONTACT SUPPORT
API.contactSupport = contact => axiosInstance.post(`contact/contact`, contact);

// MEMBERSHIP
API.getMembershipEntry = (sessionid, id) =>
  axiosInstance.get(`membership/entry?sessionid=${sessionid}&id=${id}`);

// PAYMENT
API.savePayment = payment => axiosInstance.post(`payment/insert`, payment);
API.validatePayment = (sessionid, membershipid) =>
  axiosInstance.get(
    `payment/validate?sessionid=${sessionid}&membershipid=${membershipid}`,
  );

// Dates
API.getDateList = (
  sessionid,
  numofrecords,
  offset,
  date,
  order = 0,
  filter = '',
  status = '',
) =>
  axiosInstance.get(
    `dates/list?sessionid=${sessionid}&numofrecords=${numofrecords}&offset=${offset}&date=${date}&order=${order}&filter=${filter}&status=${status}`,
  );
API.getFullCalendar = (sessionid, month, year) =>
  axiosInstance.get(
    `dates/calendar?sessionid=${sessionid}&month=${month}&year=${year}`,
  );
API.createUpdateDate = params => axiosInstance.post(`dates/update`, params);
API.updatStatusDate = params =>
  axiosInstance.post(`dates/updatestatus`, params);
API.getDateById = (sessionid, id) =>
  axiosInstance.get(`dates/detail?sessionid=${sessionid}&id=${id}`);
API.deleteDateById = params => axiosInstance.post('dates/delete', params);

// Patients
API.getPatientList = (
  sessionid,
  numofrecords = 10,
  offset = 0,
  filter = '',
  order = '',
  waitlist = '',
  datesActive = '',
) =>
  axiosInstance.get(
    `patients/list?sessionid=${sessionid}&numofrecords=${numofrecords}&offset=${offset}&filter=${filter}&order=${order}&waitlist=${waitlist}&datesActive=${datesActive}`,
  );
API.createUpdatePatients = params =>
  axiosInstance.post('patients/update', params);
API.getPatientById = (sessionid, id) =>
  axiosInstance.get(`patients/entry?sessionid=${sessionid}&id=${id}`);

// Recipes
API.getRecipeList = (
  sessionid,
  patientid,
  numofrecords = 10,
  offset = 0,
  filter = '',
  order = '',
) =>
  axiosInstance.get(
    `recipes/list?sessionid=${sessionid}&patientid=${patientid}&numofrecords=${numofrecords}&offset=${offset}&filter=${filter}&order=${order}`,
  );
API.deleteRecipeByID = params => axiosInstance.post('recipes/delete', params);
API.createUpdateRecipe = params => axiosInstance.post(`recipes/update`, params);

// Historias
API.getHistorialList = (
  sessionid,
  patientid,
  numofrecords = 10,
  offset = 0,
  filter = '',
  order = '',
) =>
  axiosInstance.get(
    `historial/list?sessionid=${sessionid}&patientid=${patientid}&numofrecords=${numofrecords}&offset=${offset}&filter=${filter}&order=${order}`,
  );
API.deleteHistorialByID = params =>
  axiosInstance.post('historial/delete', params);
API.createUpdateHistorial = params =>
  axiosInstance.post(`historial/update`, params);
API.generatePdf = params => axiosInstance.post(`pdf/generatePdf`, params);

// Medicamentos
API.getMedicineList = (
  sessionid,
  numofrecords,
  offset,
  order = '',
  filter = '',
) =>
  axiosInstance.get(
    `medicines/list?sessionid=${sessionid}&numofrecords=${numofrecords}&offset=${offset}&order=${order}&filter=${filter}`,
  );
API.deleteMedicineById = params =>
  axiosInstance.post(`medicines/delete`, params);
API.createUpdateMedicine = params =>
  axiosInstance.post(`medicines/update`, params);

// Seguros medicos
API.getSecureList = (
  sessionid,
  numofrecords,
  offset,
  order = '',
  filter = '',
  forRegister = '',
) =>
  axiosInstance.get(
    `secures/list?sessionid=${sessionid}&numofrecords=${numofrecords}&offset=${offset}&order=${order}&filter=${filter}&forRegister=${forRegister}`,
  );
API.deleteSecureById = params => axiosInstance.post(`secures/delete`, params);
API.createUpdateSeguro = params => axiosInstance.post(`secures/update`, params);

// PIN
API.updatePin = params => axiosInstance.post(`pin/update`, params);
API.pinLogout = params => axiosInstance.post(`pin/logout`, params);

// Config
API.updateEmailAuto = params => axiosInstance.post(`email/update`, params);
API.contact = params => axiosInstance.post(`contact/send`, params);
API.changeTheme = params => axiosInstance.post(`preferences/setTheme`, params);

export default API;
