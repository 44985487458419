import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    formBox: {
      height: '100%',
    },
    selectBox: {
      height: '100%',
    },
  }),
  { index: 1 },
);

export default useStyles;
