import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Loading } from 'components';

const BasicLayout = ({ children, isLoading, ...props }) => {
  return (
    <main
      style={{
        position: 'relative',
        maxWidth: '100%',
        overflow: 'hidden',
      }}
      {...props}>
      <CssBaseline />
      {children}
      {isLoading ? <Loading /> : null}
    </main>
  );
};

export default BasicLayout;
