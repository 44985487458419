/* eslint-disable camelcase */
const COUNTRIES = [
  {
    nombre: 'Afganistán',
    name: 'Afghanistan',
    nom: 'Afghanistan',
    iso2: 'AF',
    iso3: 'AFG',
    phone_code: '93',
    flag_emoji: '🇦🇫',
  },
  {
    nombre: 'Albania',
    name: 'Albania',
    nom: 'Albanie',
    iso2: 'AL',
    iso3: 'ALB',
    phone_code: '355',
    flag_emoji: '🇦🇱',
  },
  {
    nombre: 'Alemania',
    name: 'Germany',
    nom: 'Allemagne',
    iso2: 'DE',
    iso3: 'DEU',
    phone_code: '49',
    flag_emoji: '🇩🇪',
  },
  {
    nombre: 'Algeria',
    name: 'Algeria',
    nom: 'Algérie',
    iso2: 'DZ',
    iso3: 'DZA',
    phone_code: '213',
    flag_emoji: '🇩🇿',
  },
  {
    nombre: 'Andorra',
    name: 'Andorra',
    nom: 'Andorra',
    iso2: 'AD',
    iso3: 'AND',
    phone_code: '376',
    flag_emoji: '🇦🇩',
  },
  {
    nombre: 'Angola',
    name: 'Angola',
    nom: 'Angola',
    iso2: 'AO',
    iso3: 'AGO',
    phone_code: '244',
    flag_emoji: '🇦🇴',
  },
  {
    nombre: 'Anguila',
    name: 'Anguilla',
    nom: 'Anguilla',
    iso2: 'AI',
    iso3: 'AIA',
    phone_code: '1264',
    flag_emoji: '🇦🇮',
  },
  {
    nombre: 'Antártida',
    name: 'Antarctica',
    nom: "L'Antarctique",
    iso2: 'AQ',
    iso3: 'ATA',
    phone_code: '672',
    flag_emoji: '🇦🇶',
  },
  {
    nombre: 'Antigua y Barbuda',
    name: 'Antigua and Barbuda',
    nom: 'Antigua et Barbuda',
    iso2: 'AG',
    iso3: 'ATG',
    phone_code: '1268',
    flag_emoji: '🇦🇬',
  },
  {
    nombre: 'Antillas Neerlandesas',
    name: 'Netherlands Antilles',
    nom: 'Antilles Néerlandaises',
    iso2: 'AN',
    iso3: 'ANT',
    phone_code: '599',
    flag_emoji: '🇧🇶',
  },
  {
    nombre: 'Arabia Saudita',
    name: 'Saudi Arabia',
    nom: 'Arabie Saoudite',
    iso2: 'SA',
    iso3: 'SAU',
    phone_code: '966',
    flag_emoji: '🇸🇦',
  },
  {
    nombre: 'Argentina',
    name: 'Argentina',
    nom: 'Argentine',
    iso2: 'AR',
    iso3: 'ARG',
    phone_code: '54',
    flag_emoji: '🇦🇷',
  },
  {
    nombre: 'Armenia',
    name: 'Armenia',
    nom: "L'Arménie",
    iso2: 'AM',
    iso3: 'ARM',
    phone_code: '374',
    flag_emoji: '🇦🇲',
  },
  {
    nombre: 'Aruba',
    name: 'Aruba',
    nom: 'Aruba',
    iso2: 'AW',
    iso3: 'ABW',
    phone_code: '297',
    flag_emoji: '🇦🇼',
  },
  {
    nombre: 'Australia',
    name: 'Australia',
    nom: 'Australie',
    iso2: 'AU',
    iso3: 'AUS',
    phone_code: '61',
    flag_emoji: '🇦🇺',
  },
  {
    nombre: 'Austria',
    name: 'Austria',
    nom: 'Autriche',
    iso2: 'AT',
    iso3: 'AUT',
    phone_code: '43',
    flag_emoji: '🇦🇹',
  },
  {
    nombre: 'Azerbayán',
    name: 'Azerbaijan',
    nom: "L'Azerbaïdjan",
    iso2: 'AZ',
    iso3: 'AZE',
    phone_code: '994',
    flag_emoji: '🇦🇿',
  },
  {
    nombre: 'Bélgica',
    name: 'Belgium',
    nom: 'Belgique',
    iso2: 'BE',
    iso3: 'BEL',
    phone_code: '32',
    flag_emoji: '🇧🇪',
  },
  {
    nombre: 'Bahamas',
    name: 'Bahamas',
    nom: 'Bahamas',
    iso2: 'BS',
    iso3: 'BHS',
    phone_code: '1242',
    flag_emoji: '🇧🇸',
  },
  {
    nombre: 'Bahrein',
    name: 'Bahrain',
    nom: 'Bahreïn',
    iso2: 'BH',
    iso3: 'BHR',
    phone_code: '973',
    flag_emoji: '🇧🇭',
  },
  {
    nombre: 'Bangladesh',
    name: 'Bangladesh',
    nom: 'Bangladesh',
    iso2: 'BD',
    iso3: 'BGD',
    phone_code: '880',
    flag_emoji: '🇧🇩',
  },
  {
    nombre: 'Barbados',
    name: 'Barbados',
    nom: 'Barbade',
    iso2: 'BB',
    iso3: 'BRB',
    phone_code: '1246',
    flag_emoji: '🇧🇧',
  },
  {
    nombre: 'Belice',
    name: 'Belize',
    nom: 'Belize',
    iso2: 'BZ',
    iso3: 'BLZ',
    phone_code: '501',
    flag_emoji: '🇧🇿',
  },
  {
    nombre: 'Benín',
    name: 'Benin',
    nom: 'Bénin',
    iso2: 'BJ',
    iso3: 'BEN',
    phone_code: '229',
    flag_emoji: '🇧🇯',
  },
  {
    nombre: 'Bhután',
    name: 'Bhutan',
    nom: 'Le Bhoutan',
    iso2: 'BT',
    iso3: 'BTN',
    phone_code: '975',
    flag_emoji: '🇧🇹',
  },
  {
    nombre: 'Bielorrusia',
    name: 'Belarus',
    nom: 'Biélorussie',
    iso2: 'BY',
    iso3: 'BLR',
    phone_code: '375',
    flag_emoji: '🇧🇾',
  },
  {
    nombre: 'Birmania',
    name: 'Myanmar',
    nom: 'Myanmar',
    iso2: 'MM',
    iso3: 'MMR',
    phone_code: '95',
    flag_emoji: '🇲🇲',
  },
  {
    nombre: 'Bolivia',
    name: 'Bolivia',
    nom: 'Bolivie',
    iso2: 'BO',
    iso3: 'BOL',
    phone_code: '591',
    flag_emoji: '🇧🇴',
  },
  {
    nombre: 'Bosnia y Herzegovina',
    name: 'Bosnia and Herzegovina',
    nom: 'Bosnie-Herzégovine',
    iso2: 'BA',
    iso3: 'BIH',
    phone_code: '387',
    flag_emoji: '🇧🇦',
  },
  {
    nombre: 'Botsuana',
    name: 'Botswana',
    nom: 'Botswana',
    iso2: 'BW',
    iso3: 'BWA',
    phone_code: '267',
    flag_emoji: '🇧🇼',
  },
  {
    nombre: 'Brasil',
    name: 'Brazil',
    nom: 'Brésil',
    iso2: 'BR',
    iso3: 'BRA',
    phone_code: '55',
    flag_emoji: '🇧🇷',
  },
  {
    nombre: 'Brunéi',
    name: 'Brunei',
    nom: 'Brunei',
    iso2: 'BN',
    iso3: 'BRN',
    phone_code: '673',
    flag_emoji: '🇧🇳',
  },
  {
    nombre: 'Bulgaria',
    name: 'Bulgaria',
    nom: 'Bulgarie',
    iso2: 'BG',
    iso3: 'BGR',
    phone_code: '359',
    flag_emoji: '🇧🇬',
  },
  {
    nombre: 'Burkina Faso',
    name: 'Burkina Faso',
    nom: 'Burkina Faso',
    iso2: 'BF',
    iso3: 'BFA',
    phone_code: '226',
    flag_emoji: '🇧🇫',
  },
  {
    nombre: 'Burundi',
    name: 'Burundi',
    nom: 'Burundi',
    iso2: 'BI',
    iso3: 'BDI',
    phone_code: '257',
    flag_emoji: '🇧🇮',
  },
  {
    nombre: 'Cabo Verde',
    name: 'Cape Verde',
    nom: 'Cap-Vert',
    iso2: 'CV',
    iso3: 'CPV',
    phone_code: '238',
    flag_emoji: '🇨🇻',
  },
  {
    nombre: 'Camboya',
    name: 'Cambodia',
    nom: 'Cambodge',
    iso2: 'KH',
    iso3: 'KHM',
    phone_code: '855',
    flag_emoji: '🇰🇭',
  },
  {
    nombre: 'Camerún',
    name: 'Cameroon',
    nom: 'Cameroun',
    iso2: 'CM',
    iso3: 'CMR',
    phone_code: '237',
    flag_emoji: '🇨🇲',
  },
  {
    nombre: 'Canadá',
    name: 'Canada',
    nom: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
    phone_code: '1',
    flag_emoji: '🇨🇦',
  },
  {
    nombre: 'Chad',
    name: 'Chad',
    nom: 'Tchad',
    iso2: 'TD',
    iso3: 'TCD',
    phone_code: '235',
    flag_emoji: '🇹🇩',
  },
  {
    nombre: 'Chile',
    name: 'Chile',
    nom: 'Chili',
    iso2: 'CL',
    iso3: 'CHL',
    phone_code: '56',
    flag_emoji: '🇨🇱',
  },
  {
    nombre: 'China',
    name: 'China',
    nom: 'Chine',
    iso2: 'CN',
    iso3: 'CHN',
    phone_code: '86',
    flag_emoji: '🇨🇳',
  },
  {
    nombre: 'Chipre',
    name: 'Cyprus',
    nom: 'Chypre',
    iso2: 'CY',
    iso3: 'CYP',
    phone_code: '357',
    flag_emoji: '🇨🇾',
  },
  {
    nombre: 'Ciudad del Vaticano',
    name: 'Vatican City State',
    nom: 'Cité du Vatican',
    iso2: 'VA',
    iso3: 'VAT',
    phone_code: '39',
    flag_emoji: '🇻🇦',
  },
  {
    nombre: 'Colombia',
    name: 'Colombia',
    nom: 'Colombie',
    iso2: 'CO',
    iso3: 'COL',
    phone_code: '57',
    flag_emoji: '🇨🇴',
  },
  {
    nombre: 'Comoras',
    name: 'Comoros',
    nom: 'Comores',
    iso2: 'KM',
    iso3: 'COM',
    phone_code: '269',
    flag_emoji: '🇰🇲',
  },
  {
    nombre: 'Congo - Brazzaville',
    name: 'Congo - Brazzaville',
    nom: 'Congo - Brazzaville',
    iso2: 'CG',
    iso3: 'COG',
    phone_code: '242',
    flag_emoji: '🇨🇬',
  },
  {
    nombre: 'Congo - Kinshasa',
    name: 'Congo - Kinshasa',
    nom: 'Congo - Kinshasa',
    iso2: 'CD',
    iso3: 'COD',
    phone_code: '243',
    flag_emoji: '🇨🇩',
  },
  {
    nombre: 'Corea del Norte',
    name: 'North Korea',
    nom: 'Corée du Nord',
    iso2: 'KP',
    iso3: 'PRK',
    phone_code: '850',
    flag_emoji: '🇰🇵',
  },
  {
    nombre: 'Corea del Sur',
    name: 'South Korea',
    nom: 'Corée du Sud',
    iso2: 'KR',
    iso3: 'KOR',
    phone_code: '82',
    flag_emoji: '🇰🇷',
  },
  {
    nombre: 'Costa de Marfil',
    name: 'Ivory Coast',
    nom: "Côte-d'Ivoire",
    iso2: 'CI',
    iso3: 'CIV',
    phone_code: '225',
    flag_emoji: '🇨🇮',
  },
  {
    nombre: 'Costa Rica',
    name: 'Costa Rica',
    nom: 'Costa Rica',
    iso2: 'CR',
    iso3: 'CRI',
    phone_code: '506',
    flag_emoji: '🇨🇷',
  },
  {
    nombre: 'Croacia',
    name: 'Croatia',
    nom: 'Croatie',
    iso2: 'HR',
    iso3: 'HRV',
    phone_code: '385',
    flag_emoji: '🇭🇷',
  },
  {
    nombre: 'Cuba',
    name: 'Cuba',
    nom: 'Cuba',
    iso2: 'CU',
    iso3: 'CUB',
    phone_code: '53',
    flag_emoji: '🇨🇺',
  },
  {
    nombre: 'Dinamarca',
    name: 'Denmark',
    nom: 'Danemark',
    iso2: 'DK',
    iso3: 'DNK',
    phone_code: '45',
    flag_emoji: '🇩🇰',
  },
  {
    nombre: 'Dominica',
    name: 'Dominica',
    nom: 'Dominique',
    iso2: 'DM',
    iso3: 'DMA',
    phone_code: '1767',
    flag_emoji: '🇩🇲',
  },
  {
    nombre: 'Ecuador',
    name: 'Ecuador',
    nom: 'Equateur',
    iso2: 'EC',
    iso3: 'ECU',
    phone_code: '593',
    flag_emoji: '🇪🇨',
  },
  {
    nombre: 'Egipto',
    name: 'Egypt',
    nom: 'Egypte',
    iso2: 'EG',
    iso3: 'EGY',
    phone_code: '20',
    flag_emoji: '🇪🇬',
  },
  {
    nombre: 'El Salvador',
    name: 'El Salvador',
    nom: 'El Salvador',
    iso2: 'SV',
    iso3: 'SLV',
    phone_code: '503',
    flag_emoji: '🇸🇻',
  },
  {
    nombre: 'Emiratos Árabes Unidos',
    name: 'United Arab Emirates',
    nom: 'Emirats Arabes Unis',
    iso2: 'AE',
    iso3: 'ARE',
    phone_code: '971',
    flag_emoji: '🇦🇪',
  },
  {
    nombre: 'Eritrea',
    name: 'Eritrea',
    nom: 'Erythrée',
    iso2: 'ER',
    iso3: 'ERI',
    phone_code: '291',
    flag_emoji: '🇪🇷',
  },
  {
    nombre: 'Eslovaquia',
    name: 'Slovakia',
    nom: 'Slovaquie',
    iso2: 'SK',
    iso3: 'SVK',
    phone_code: '421',
    flag_emoji: '🇸🇰',
  },
  {
    nombre: 'Eslovenia',
    name: 'Slovenia',
    nom: 'Slovénie',
    iso2: 'SI',
    iso3: 'SVN',
    phone_code: '386',
    flag_emoji: '🇸🇮',
  },
  {
    nombre: 'España',
    name: 'Spain',
    nom: 'Espagne',
    iso2: 'ES',
    iso3: 'ESP',
    phone_code: '34',
    flag_emoji: '🇪🇸',
  },
  {
    nombre: 'Estados Unidos de América',
    name: 'United States of America',
    nom: "États-Unis d'Amérique",
    iso2: 'US',
    iso3: 'USA',
    phone_code: '1',
    flag_emoji: '🇺🇸',
  },
  {
    nombre: 'Estonia',
    name: 'Estonia',
    nom: "L'Estonie",
    iso2: 'EE',
    iso3: 'EST',
    phone_code: '372',
    flag_emoji: '🇪🇪',
  },
  {
    nombre: 'Etiopía',
    name: 'Ethiopia',
    nom: 'Ethiopie',
    iso2: 'ET',
    iso3: 'ETH',
    phone_code: '251',
    flag_emoji: '🇪🇹',
  },
  {
    nombre: 'Filipinas',
    name: 'Philippines',
    nom: 'Philippines',
    iso2: 'PH',
    iso3: 'PHL',
    phone_code: '63',
    flag_emoji: '🇵🇭',
  },
  {
    nombre: 'Finlandia',
    name: 'Finland',
    nom: 'Finlande',
    iso2: 'FI',
    iso3: 'FIN',
    phone_code: '358',
    flag_emoji: '🇫🇮',
  },
  {
    nombre: 'Fiyi',
    name: 'Fiji',
    nom: 'Fidji',
    iso2: 'FJ',
    iso3: 'FJI',
    phone_code: '679',
    flag_emoji: '🇫🇯',
  },
  {
    nombre: 'Francia',
    name: 'France',
    nom: 'France',
    iso2: 'FR',
    iso3: 'FRA',
    phone_code: '33',
    flag_emoji: '🇫🇷',
  },
  {
    nombre: 'Gabón',
    name: 'Gabon',
    nom: 'Gabon',
    iso2: 'GA',
    iso3: 'GAB',
    phone_code: '241',
    flag_emoji: '🇬🇦',
  },
  {
    nombre: 'Gambia',
    name: 'Gambia',
    nom: 'Gambie',
    iso2: 'GM',
    iso3: 'GMB',
    phone_code: '220',
    flag_emoji: '🇬🇲',
  },
  {
    nombre: 'Georgia',
    name: 'Georgia',
    nom: 'Géorgie',
    iso2: 'GE',
    iso3: 'GEO',
    phone_code: '995',
    flag_emoji: '🇬🇪',
  },
  {
    nombre: 'Ghana',
    name: 'Ghana',
    nom: 'Ghana',
    iso2: 'GH',
    iso3: 'GHA',
    phone_code: '233',
    flag_emoji: '🇬🇭',
  },
  {
    nombre: 'Gibraltar',
    name: 'Gibraltar',
    nom: 'Gibraltar',
    iso2: 'GI',
    iso3: 'GIB',
    phone_code: '350',
    flag_emoji: '🇬🇮',
  },
  {
    nombre: 'Granada',
    name: 'Grenada',
    nom: 'Grenade',
    iso2: 'GD',
    iso3: 'GRD',
    phone_code: '1473',
    flag_emoji: '🇬🇩',
  },
  {
    nombre: 'Grecia',
    name: 'Greece',
    nom: 'Grèce',
    iso2: 'GR',
    iso3: 'GRC',
    phone_code: '30',
    flag_emoji: '🇬🇷',
  },
  {
    nombre: 'Groenlandia',
    name: 'Greenland',
    nom: 'Groenland',
    iso2: 'GL',
    iso3: 'GRL',
    phone_code: '299',
    flag_emoji: '🇬🇱',
  },
  {
    nombre: 'Guam',
    name: 'Guam',
    nom: 'Guam',
    iso2: 'GU',
    iso3: 'GUM',
    phone_code: '1671',
    flag_emoji: '🇬🇺',
  },
  {
    nombre: 'Guatemala',
    name: 'Guatemala',
    nom: 'Guatemala',
    iso2: 'GT',
    iso3: 'GTM',
    phone_code: '502',
    flag_emoji: '🇬🇹',
  },
  {
    nombre: 'Guinea',
    name: 'Guinea',
    nom: 'Guinée',
    iso2: 'GN',
    iso3: 'GIN',
    phone_code: '224',
    flag_emoji: '🇬🇳',
  },
  {
    nombre: 'Guinea Ecuatorial',
    name: 'Equatorial Guinea',
    nom: 'Guinée Equatoriale',
    iso2: 'GQ',
    iso3: 'GNQ',
    phone_code: '240',
    flag_emoji: '🇬🇶',
  },
  {
    nombre: 'Guinea-Bissau',
    name: 'Guinea-Bissau',
    nom: 'Guinée-Bissau',
    iso2: 'GW',
    iso3: 'GNB',
    phone_code: '245',
    flag_emoji: '🇬🇼',
  },
  {
    nombre: 'Guyana',
    name: 'Guyana',
    nom: 'Guyane',
    iso2: 'GY',
    iso3: 'GUY',
    phone_code: '592',
    flag_emoji: '🇬🇾',
  },
  {
    nombre: 'Haití',
    name: 'Haiti',
    nom: 'Haïti',
    iso2: 'HT',
    iso3: 'HTI',
    phone_code: '509',
    flag_emoji: '🇭🇹',
  },
  {
    nombre: 'Honduras',
    name: 'Honduras',
    nom: 'Honduras',
    iso2: 'HN',
    iso3: 'HND',
    phone_code: '504',
    flag_emoji: '🇭🇳',
  },
  {
    nombre: 'Hong kong',
    name: 'Hong Kong',
    nom: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
    phone_code: '852',
    flag_emoji: '🇭🇰',
  },
  {
    nombre: 'Hungría',
    name: 'Hungary',
    nom: 'Hongrie',
    iso2: 'HU',
    iso3: 'HUN',
    phone_code: '36',
    flag_emoji: '🇭🇺',
  },
  {
    nombre: 'India',
    name: 'India',
    nom: 'Inde',
    iso2: 'IN',
    iso3: 'IND',
    phone_code: '91',
    flag_emoji: '🇮🇳',
  },
  {
    nombre: 'Indonesia',
    name: 'Indonesia',
    nom: 'Indonésie',
    iso2: 'ID',
    iso3: 'IDN',
    phone_code: '62',
    flag_emoji: '🇮🇩',
  },
  {
    nombre: 'Irán',
    name: 'Iran',
    nom: 'Iran',
    iso2: 'IR',
    iso3: 'IRN',
    phone_code: '98',
    flag_emoji: '🇮🇷',
  },
  {
    nombre: 'Irak',
    name: 'Iraq',
    nom: 'Irak',
    iso2: 'IQ',
    iso3: 'IRQ',
    phone_code: '964',
    flag_emoji: '🇮🇶',
  },
  {
    nombre: 'Irlanda',
    name: 'Ireland',
    nom: 'Irlande',
    iso2: 'IE',
    iso3: 'IRL',
    phone_code: '353',
    flag_emoji: '🇮🇪',
  },
  {
    nombre: 'Isla de Man',
    name: 'Isle of Man',
    nom: 'Ile de Man',
    iso2: 'IM',
    iso3: 'IMN',
    phone_code: '44',
    flag_emoji: '🇮🇲',
  },
  {
    nombre: 'Isla de Navidad',
    name: 'Christmas Island',
    nom: 'Christmas Island',
    iso2: 'CX',
    iso3: 'CXR',
    phone_code: '61',
    flag_emoji: '🇨🇽',
  },
  {
    nombre: 'Islandia',
    name: 'Iceland',
    nom: 'Islande',
    iso2: 'IS',
    iso3: 'ISL',
    phone_code: '354',
    flag_emoji: '🇮🇸',
  },
  {
    nombre: 'Islas Bermudas',
    name: 'Bermuda Islands',
    nom: 'Bermudes',
    iso2: 'BM',
    iso3: 'BMU',
    phone_code: '1441',
    flag_emoji: '🇧🇲',
  },
  {
    nombre: 'Islas Caimán',
    name: 'Cayman Islands',
    nom: 'Iles Caïmans',
    iso2: 'KY',
    iso3: 'CYM',
    phone_code: '1345',
    flag_emoji: '🇰🇾',
  },
  {
    nombre: 'Islas Cocos (Keeling)',
    name: 'Cocos (Keeling) Islands',
    nom: 'Cocos (Keeling',
    iso2: 'CC',
    iso3: 'CCK',
    phone_code: '61',
    flag_emoji: '🇨🇨',
  },
  {
    nombre: 'Islas Cook',
    name: 'Cook Islands',
    nom: 'Iles Cook',
    iso2: 'CK',
    iso3: 'COK',
    phone_code: '682',
    flag_emoji: '🇨🇰',
  },
  {
    nombre: 'Islas Feroe',
    name: 'Faroe Islands',
    nom: 'Iles Féro',
    iso2: 'FO',
    iso3: 'FRO',
    phone_code: '298',
    flag_emoji: '🇫🇴',
  },
  {
    nombre: 'Islas Maldivas',
    name: 'Maldives',
    nom: 'Maldives',
    iso2: 'MV',
    iso3: 'MDV',
    phone_code: '960',
    flag_emoji: '🇲🇻',
  },
  {
    nombre: 'Islas Malvinas',
    name: 'Falkland Islands (Malvinas)',
    nom: 'Iles Falkland (Malvinas',
    iso2: 'FK',
    iso3: 'FLK',
    phone_code: '500',
    flag_emoji: '🇫🇰',
  },
  {
    nombre: 'Islas Marianas del Norte',
    name: 'Northern Mariana Islands',
    nom: 'Iles Mariannes du Nord',
    iso2: 'MP',
    iso3: 'MNP',
    phone_code: '1670',
    flag_emoji: '🇲🇵',
  },
  {
    nombre: 'Islas Marshall',
    name: 'Marshall Islands',
    nom: 'Iles Marshall',
    iso2: 'MH',
    iso3: 'MHL',
    phone_code: '692',
    flag_emoji: '🇲🇭',
  },
  {
    nombre: 'Islas Pitcairn',
    name: 'Pitcairn Islands',
    nom: 'Iles Pitcairn',
    iso2: 'PN',
    iso3: 'PCN',
    phone_code: '870',
    flag_emoji: '🇵🇳',
  },
  {
    nombre: 'Islas Salomón',
    name: 'Solomon Islands',
    nom: 'Iles Salomon',
    iso2: 'SB',
    iso3: 'SLB',
    phone_code: '677',
    flag_emoji: '🇸🇧',
  },
  {
    nombre: 'Islas Turcas y Caicos',
    name: 'Turks and Caicos Islands',
    nom: 'Iles Turques et Caïques',
    iso2: 'TC',
    iso3: 'TCA',
    phone_code: '1649',
    flag_emoji: '🇹🇨',
  },
  {
    nombre: 'Islas Vírgenes Británicas',
    name: 'Virgin Islands',
    nom: 'Iles Vierges',
    iso2: 'VG',
    iso3: 'VG',
    phone_code: '1284',
    flag_emoji: '🇻🇬',
  },
  {
    nombre: 'Islas Vírgenes de los Estados Unidos',
    name: 'United States Virgin Islands',
    nom: 'Îles Vierges américaines',
    iso2: 'VI',
    iso3: 'VIR',
    phone_code: '1340',
    flag_emoji: '🇻🇮',
  },
  {
    nombre: 'Israel',
    name: 'Israel',
    nom: 'Israël',
    iso2: 'IL',
    iso3: 'ISR',
    phone_code: '972',
    flag_emoji: '🇮🇱',
  },
  {
    nombre: 'Italia',
    name: 'Italy',
    nom: 'Italie',
    iso2: 'IT',
    iso3: 'ITA',
    phone_code: '39',
    flag_emoji: '🇮🇹',
  },
  {
    nombre: 'Jamaica',
    name: 'Jamaica',
    nom: 'Jamaïque',
    iso2: 'JM',
    iso3: 'JAM',
    phone_code: '1876',
    flag_emoji: '🇯🇲',
  },
  {
    nombre: 'Japón',
    name: 'Japan',
    nom: 'Japon',
    iso2: 'JP',
    iso3: 'JPN',
    phone_code: '81',
    flag_emoji: '🇯🇵',
  },
  {
    nombre: 'Jordania',
    name: 'Jordan',
    nom: 'Jordan',
    iso2: 'JO',
    iso3: 'JOR',
    phone_code: '962',
    flag_emoji: '🇯🇴',
  },
  {
    nombre: 'Kazajistán',
    name: 'Kazakhstan',
    nom: 'Le Kazakhstan',
    iso2: 'KZ',
    iso3: 'KAZ',
    phone_code: '7',
    flag_emoji: '🇰🇿',
  },
  {
    nombre: 'Kenia',
    name: 'Kenya',
    nom: 'Kenya',
    iso2: 'KE',
    iso3: 'KEN',
    phone_code: '254',
    flag_emoji: '🇰🇪',
  },
  {
    nombre: 'Kirgizstán',
    name: 'Kyrgyzstan',
    nom: 'Kirghizstan',
    iso2: 'KG',
    iso3: 'KGZ',
    phone_code: '996',
    flag_emoji: '🇰🇬',
  },
  {
    nombre: 'Kiribati',
    name: 'Kiribati',
    nom: 'Kiribati',
    iso2: 'KI',
    iso3: 'KIR',
    phone_code: '686',
    flag_emoji: '🇰🇮',
  },
  {
    nombre: 'Kuwait',
    name: 'Kuwait',
    nom: 'Koweït',
    iso2: 'KW',
    iso3: 'KWT',
    phone_code: '965',
    flag_emoji: '🇰🇼',
  },
  {
    nombre: 'Líbano',
    name: 'Lebanon',
    nom: 'Liban',
    iso2: 'LB',
    iso3: 'LBN',
    phone_code: '961',
    flag_emoji: '🇱🇧',
  },
  {
    nombre: 'Laos',
    name: 'Laos',
    nom: 'Laos',
    iso2: 'LA',
    iso3: 'LAO',
    phone_code: '856',
    flag_emoji: '🇱🇦',
  },
  {
    nombre: 'Lesoto',
    name: 'Lesotho',
    nom: 'Lesotho',
    iso2: 'LS',
    iso3: 'LSO',
    phone_code: '266',
    flag_emoji: '🇱🇸',
  },
  {
    nombre: 'Letonia',
    name: 'Latvia',
    nom: 'La Lettonie',
    iso2: 'LV',
    iso3: 'LVA',
    phone_code: '371',
    flag_emoji: '🇱🇻',
  },
  {
    nombre: 'Liberia',
    name: 'Liberia',
    nom: 'Liberia',
    iso2: 'LR',
    iso3: 'LBR',
    phone_code: '231',
    flag_emoji: '🇱🇷',
  },
  {
    nombre: 'Libia',
    name: 'Libya',
    nom: 'Libye',
    iso2: 'LY',
    iso3: 'LBY',
    phone_code: '218',
    flag_emoji: '🇱🇾',
  },
  {
    nombre: 'Liechtenstein',
    name: 'Liechtenstein',
    nom: 'Liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
    phone_code: '423',
    flag_emoji: '🇱🇮',
  },
  {
    nombre: 'Lituania',
    name: 'Lithuania',
    nom: 'La Lituanie',
    iso2: 'LT',
    iso3: 'LTU',
    phone_code: '370',
    flag_emoji: '🇱🇹',
  },
  {
    nombre: 'Luxemburgo',
    name: 'Luxembourg',
    nom: 'Luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
    phone_code: '352',
    flag_emoji: '🇱🇺',
  },
  {
    nombre: 'México',
    name: 'Mexico',
    nom: 'Mexique',
    iso2: 'MX',
    iso3: 'MEX',
    phone_code: '52',
    flag_emoji: '🇲🇽',
  },
  {
    nombre: 'Mónaco',
    name: 'Monaco',
    nom: 'Monaco',
    iso2: 'MC',
    iso3: 'MCO',
    phone_code: '377',
    flag_emoji: '🇲🇨',
  },
  {
    nombre: 'Macao',
    name: 'Macao',
    nom: 'Macao',
    iso2: 'MO',
    iso3: 'MAC',
    phone_code: '853',
    flag_emoji: '🇲🇴',
  },
  {
    nombre: 'Macedônia',
    name: 'Macedonia',
    nom: 'Macédoine',
    iso2: 'MK',
    iso3: 'MKD',
    phone_code: '389',
    flag_emoji: '🇲🇰',
  },
  {
    nombre: 'Madagascar',
    name: 'Madagascar',
    nom: 'Madagascar',
    iso2: 'MG',
    iso3: 'MDG',
    phone_code: '261',
    flag_emoji: '🇲🇬',
  },
  {
    nombre: 'Malasia',
    name: 'Malaysia',
    nom: 'Malaisie',
    iso2: 'MY',
    iso3: 'MYS',
    phone_code: '60',
    flag_emoji: '🇲🇾',
  },
  {
    nombre: 'Malawi',
    name: 'Malawi',
    nom: 'Malawi',
    iso2: 'MW',
    iso3: 'MWI',
    phone_code: '265',
    flag_emoji: '🇲🇼',
  },
  {
    nombre: 'Mali',
    name: 'Mali',
    nom: 'Mali',
    iso2: 'ML',
    iso3: 'MLI',
    phone_code: '223',
    flag_emoji: '🇲🇱',
  },
  {
    nombre: 'Malta',
    name: 'Malta',
    nom: 'Malte',
    iso2: 'MT',
    iso3: 'MLT',
    phone_code: '356',
    flag_emoji: '🇲🇹',
  },
  {
    nombre: 'Marruecos',
    name: 'Morocco',
    nom: 'Maroc',
    iso2: 'MA',
    iso3: 'MAR',
    phone_code: '212',
    flag_emoji: '🇲🇦',
  },
  {
    nombre: 'Mauricio',
    name: 'Mauritius',
    nom: 'Iles Maurice',
    iso2: 'MU',
    iso3: 'MUS',
    phone_code: '230',
    flag_emoji: '🇲🇺',
  },
  {
    nombre: 'Mauritania',
    name: 'Mauritania',
    nom: 'Mauritanie',
    iso2: 'MR',
    iso3: 'MRT',
    phone_code: '222',
    flag_emoji: '🇲🇷',
  },
  {
    nombre: 'Mayotte',
    name: 'Mayotte',
    nom: 'Mayotte',
    iso2: 'YT',
    iso3: 'MYT',
    phone_code: '262',
    flag_emoji: '🇾🇹',
  },
  {
    nombre: 'Micronesia',
    name: 'Micronesia',
    nom: 'Micronesia',
    iso2: 'FM',
    iso3: 'FSM',
    phone_code: '691',
    flag_emoji: '🇫🇲',
  },
  {
    nombre: 'Moldavia',
    name: 'Moldova',
    nom: 'Moldavie',
    iso2: 'MD',
    iso3: 'MDA',
    phone_code: '373',
    flag_emoji: '🇲🇩',
  },
  {
    nombre: 'Mongolia',
    name: 'Mongolia',
    nom: 'Mongolie',
    iso2: 'MN',
    iso3: 'MNG',
    phone_code: '976',
    flag_emoji: '🇲🇳',
  },
  {
    nombre: 'Montenegro',
    name: 'Montenegro',
    nom: 'Monténégro',
    iso2: 'ME',
    iso3: 'MNE',
    phone_code: '382',
    flag_emoji: '🇲🇪',
  },
  {
    nombre: 'Montserrat',
    name: 'Montserrat',
    nom: 'Montserrat',
    iso2: 'MS',
    iso3: 'MSR',
    phone_code: '1664',
    flag_emoji: '🇲🇸',
  },
  {
    nombre: 'Mozambique',
    name: 'Mozambique',
    nom: 'Mozambique',
    iso2: 'MZ',
    iso3: 'MOZ',
    phone_code: '258',
    flag_emoji: '🇲🇿',
  },
  {
    nombre: 'Namibia',
    name: 'Namibia',
    nom: 'Namibie',
    iso2: 'NA',
    iso3: 'NAM',
    phone_code: '264',
    flag_emoji: '🇳🇦',
  },
  {
    nombre: 'Nauru',
    name: 'Nauru',
    nom: 'Nauru',
    iso2: 'NR',
    iso3: 'NRU',
    phone_code: '674',
    flag_emoji: '🇳🇷',
  },
  {
    nombre: 'Nepal',
    name: 'Nepal',
    nom: 'Népal',
    iso2: 'NP',
    iso3: 'NPL',
    phone_code: '977',
    flag_emoji: '🇳🇵',
  },
  {
    nombre: 'Nicaragua',
    name: 'Nicaragua',
    nom: 'Nicaragua',
    iso2: 'NI',
    iso3: 'NIC',
    phone_code: '505',
    flag_emoji: '🇳🇮',
  },
  {
    nombre: 'Niger',
    name: 'Niger',
    nom: 'Niger',
    iso2: 'NE',
    iso3: 'NER',
    phone_code: '227',
    flag_emoji: '🇳🇪',
  },
  {
    nombre: 'Nigeria',
    name: 'Nigeria',
    nom: 'Nigeria',
    iso2: 'NG',
    iso3: 'NGA',
    phone_code: '234',
    flag_emoji: '🇳🇬',
  },
  {
    nombre: 'Niue',
    name: 'Niue',
    nom: 'Niou',
    iso2: 'NU',
    iso3: 'NIU',
    phone_code: '683',
    flag_emoji: '🇳🇺',
  },
  {
    nombre: 'Noruega',
    name: 'Norway',
    nom: 'Norvège',
    iso2: 'NO',
    iso3: 'NOR',
    phone_code: '47',
    flag_emoji: '🇳🇴',
  },
  {
    nombre: 'Nueva Caledonia',
    name: 'New Caledonia',
    nom: 'Nouvelle-Calédonie',
    iso2: 'NC',
    iso3: 'NCL',
    phone_code: '687',
    flag_emoji: '🇳🇨',
  },
  {
    nombre: 'Nueva Zelanda',
    name: 'New Zealand',
    nom: 'Nouvelle-Zélande',
    iso2: 'NZ',
    iso3: 'NZL',
    phone_code: '64',
    flag_emoji: '🇳🇿',
  },
  {
    nombre: 'Omán',
    name: 'Oman',
    nom: 'Oman',
    iso2: 'OM',
    iso3: 'OMN',
    phone_code: '968',
    flag_emoji: '🇴🇲',
  },
  {
    nombre: 'Países Bajos',
    name: 'Netherlands',
    nom: 'Pays-Bas',
    iso2: 'NL',
    iso3: 'NLD',
    phone_code: '31',
    flag_emoji: '🇳🇱',
  },
  {
    nombre: 'Pakistán',
    name: 'Pakistan',
    nom: 'Pakistan',
    iso2: 'PK',
    iso3: 'PAK',
    phone_code: '92',
    flag_emoji: '🇵🇰',
  },
  {
    nombre: 'Palau',
    name: 'Palau',
    nom: 'Palau',
    iso2: 'PW',
    iso3: 'PLW',
    phone_code: '680',
    flag_emoji: '🇵🇼',
  },
  {
    nombre: 'Panamá',
    name: 'Panama',
    nom: 'Panama',
    iso2: 'PA',
    iso3: 'PAN',
    phone_code: '507',
    flag_emoji: '🇵🇦',
  },
  {
    nombre: 'Papúa Nueva Guinea',
    name: 'Papua New Guinea',
    nom: 'Papouasie-Nouvelle-Guinée',
    iso2: 'PG',
    iso3: 'PNG',
    phone_code: '675',
    flag_emoji: '🇵🇬',
  },
  {
    nombre: 'Paraguay',
    name: 'Paraguay',
    nom: 'Paraguay',
    iso2: 'PY',
    iso3: 'PRY',
    phone_code: '595',
    flag_emoji: '🇵🇾',
  },
  {
    nombre: 'Perú',
    name: 'Peru',
    nom: 'Pérou',
    iso2: 'PE',
    iso3: 'PER',
    phone_code: '51',
    flag_emoji: '🇵🇪',
  },
  {
    nombre: 'Polinesia Francesa',
    name: 'French Polynesia',
    nom: 'Polynésie française',
    iso2: 'PF',
    iso3: 'PYF',
    phone_code: '689',
    flag_emoji: '🇵🇫',
  },
  {
    nombre: 'Polonia',
    name: 'Poland',
    nom: 'Pologne',
    iso2: 'PL',
    iso3: 'POL',
    phone_code: '48',
    flag_emoji: '🇵🇱',
  },
  {
    nombre: 'Portugal',
    name: 'Portugal',
    nom: 'Portugal',
    iso2: 'PT',
    iso3: 'PRT',
    phone_code: '351',
    flag_emoji: '🇵🇹',
  },
  {
    nombre: 'Puerto Rico',
    name: 'Puerto Rico',
    nom: 'Porto Rico',
    iso2: 'PR',
    iso3: 'PRI',
    phone_code: '1939',
    flag_emoji: '🇵🇷',
  },
  {
    nombre: 'Puerto Rico',
    name: 'Puerto Rico',
    nom: 'Porto Rico',
    iso2: 'PR',
    iso3: 'PRI',
    phone_code: '1787',
    flag_emoji: '🇵🇷',
  },
  {
    nombre: 'Qatar',
    name: 'Qatar',
    nom: 'Qatar',
    iso2: 'QA',
    iso3: 'QAT',
    phone_code: '974',
    flag_emoji: '🇶🇦',
  },
  {
    nombre: 'Reino Unido',
    name: 'United Kingdom',
    nom: 'Royaume-Uni',
    iso2: 'GB',
    iso3: 'GBR',
    phone_code: '44',
    flag_emoji: '🇬🇧',
  },
  {
    nombre: 'República Centroafricana',
    name: 'Central African Republic',
    nom: 'République Centrafricaine',
    iso2: 'CF',
    iso3: 'CAF',
    phone_code: '236',
    flag_emoji: '🇨🇫',
  },
  {
    nombre: 'República Checa',
    name: 'Czech Republic',
    nom: 'République Tchèque',
    iso2: 'CZ',
    iso3: 'CZE',
    phone_code: '420',
    flag_emoji: '🇨🇿',
  },
  {
    nombre: 'República Dominicana',
    name: 'Dominican Republic',
    nom: 'République Dominicaine',
    iso2: 'DO',
    iso3: 'DOM',
    phone_code: '1809',
    flag_emoji: '🇩🇴',
  },
  {
    nombre: 'Ruanda',
    name: 'Rwanda',
    nom: 'Rwanda',
    iso2: 'RW',
    iso3: 'RWA',
    phone_code: '250',
    flag_emoji: '🇷🇼',
  },
  {
    nombre: 'Rumanía',
    name: 'Romania',
    nom: 'Roumanie',
    iso2: 'RO',
    iso3: 'ROU',
    phone_code: '40',
    flag_emoji: '🇷🇴',
  },
  {
    nombre: 'Rusia',
    name: 'Russia',
    nom: 'La Russie',
    iso2: 'RU',
    iso3: 'RUS',
    phone_code: '7',
    flag_emoji: '🇷🇺',
  },
  {
    nombre: 'Samoa',
    name: 'Samoa',
    nom: 'Samoa',
    iso2: 'WS',
    iso3: 'WSM',
    phone_code: '685',
    flag_emoji: '🇼🇸',
  },
  {
    nombre: 'Samoa Americana',
    name: 'American Samoa',
    nom: 'Les Samoa américaines',
    iso2: 'AS',
    iso3: 'ASM',
    phone_code: '1684',
    flag_emoji: '🇦🇸',
  },
  {
    nombre: 'San Bartolomé',
    name: 'Saint Barthélemy',
    nom: 'Saint-Barthélemy',
    iso2: 'BL',
    iso3: 'BLM',
    phone_code: '590',
    flag_emoji: '🇧🇱',
  },
  {
    nombre: 'San Cristóbal y Nieves',
    name: 'Saint Kitts and Nevis',
    nom: 'Saint Kitts et Nevis',
    iso2: 'KN',
    iso3: 'KNA',
    phone_code: '1869',
    flag_emoji: '🇰🇳',
  },
  {
    nombre: 'San Marino',
    name: 'San Marino',
    nom: 'San Marino',
    iso2: 'SM',
    iso3: 'SMR',
    phone_code: '378',
    flag_emoji: '🇸🇲',
  },
  {
    nombre: 'San Martín (Francia)',
    name: 'Saint Martin (French part)',
    nom: 'Saint-Martin (partie française)',
    iso2: 'MF',
    iso3: 'MAF',
    phone_code: '1599',
    flag_emoji: '🇲🇫',
  },
  {
    nombre: 'San Pedro y Miquelón',
    name: 'Saint Pierre and Miquelon',
    nom: 'Saint-Pierre-et-Miquelon',
    iso2: 'PM',
    iso3: 'SPM',
    phone_code: '508',
    flag_emoji: '🇵🇲',
  },
  {
    nombre: 'San Vicente y las Granadinas',
    name: 'Saint Vincent and the Grenadines',
    nom: 'Saint-Vincent et Grenadines',
    iso2: 'VC',
    iso3: 'VCT',
    phone_code: '1784',
    flag_emoji: '🇻🇨',
  },
  {
    nombre: 'Santa Elena',
    name: 'St. Helena',
    nom: 'St. Helena',
    iso2: 'SH',
    iso3: 'SHN',
    phone_code: '290',
    flag_emoji: '🇸🇭',
  },
  {
    nombre: 'Santa Lucía',
    name: 'Saint Lucia',
    nom: 'Sainte-Lucie',
    iso2: 'LC',
    iso3: 'LCA',
    phone_code: '1758',
    flag_emoji: '🇱🇨',
  },
  {
    nombre: 'Santo Tomé y Príncipe',
    name: 'Sao Tome and Principe',
    nom: 'Sao Tomé et Principe',
    iso2: 'ST',
    iso3: 'STP',
    phone_code: '239',
    flag_emoji: '🇸🇹',
  },
  {
    nombre: 'Senegal',
    name: 'Senegal',
    nom: 'Sénégal',
    iso2: 'SN',
    iso3: 'SEN',
    phone_code: '221',
    flag_emoji: '🇸🇳',
  },
  {
    nombre: 'Serbia',
    name: 'Serbia',
    nom: 'Serbie',
    iso2: 'RS',
    iso3: 'SRB',
    phone_code: '381',
    flag_emoji: '🇷🇸',
  },
  {
    nombre: 'Seychelles',
    name: 'Seychelles',
    nom: 'Les Seychelles',
    iso2: 'SC',
    iso3: 'SYC',
    phone_code: '248',
    flag_emoji: '🇸🇨',
  },
  {
    nombre: 'Sierra Leona',
    name: 'Sierra Leone',
    nom: 'Sierra Leone',
    iso2: 'SL',
    iso3: 'SLE',
    phone_code: '232',
    flag_emoji: '🇸🇱',
  },
  {
    nombre: 'Singapur',
    name: 'Singapore',
    nom: 'Singapour',
    iso2: 'SG',
    iso3: 'SGP',
    phone_code: '65',
    flag_emoji: '🇸🇬',
  },
  {
    nombre: 'Siria',
    name: 'Syria',
    nom: 'Syrie',
    iso2: 'SY',
    iso3: 'SYR',
    phone_code: '963',
    flag_emoji: '🇸🇾',
  },
  {
    nombre: 'Somalia',
    name: 'Somalia',
    nom: 'Somalie',
    iso2: 'SO',
    iso3: 'SOM',
    phone_code: '252',
    flag_emoji: '🇸🇴',
  },
  {
    nombre: 'Sri lanka',
    name: 'Sri Lanka',
    nom: 'Sri Lanka',
    iso2: 'LK',
    iso3: 'LKA',
    phone_code: '94',
    flag_emoji: '🇱🇰',
  },
  {
    nombre: 'Sudáfrica',
    name: 'South Africa',
    nom: 'Afrique du Sud',
    iso2: 'ZA',
    iso3: 'ZAF',
    phone_code: '27',
    flag_emoji: '🇿🇦',
  },
  {
    nombre: 'Sudán',
    name: 'Sudan',
    nom: 'Soudan',
    iso2: 'SD',
    iso3: 'SDN',
    phone_code: '249',
    flag_emoji: '🇸🇩',
  },
  {
    nombre: 'Suecia',
    name: 'Sweden',
    nom: 'Suède',
    iso2: 'SE',
    iso3: 'SWE',
    phone_code: '46',
    flag_emoji: '🇸🇪',
  },
  {
    nombre: 'Suiza',
    name: 'Switzerland',
    nom: 'Suisse',
    iso2: 'CH',
    iso3: 'CHE',
    phone_code: '41',
    flag_emoji: '🇨🇭',
  },
  {
    nombre: 'Surinám',
    name: 'Suriname',
    nom: 'Surinam',
    iso2: 'SR',
    iso3: 'SUR',
    phone_code: '597',
    flag_emoji: '🇸🇷',
  },
  {
    nombre: 'Swazilandia',
    name: 'Swaziland',
    nom: 'Swaziland',
    iso2: 'SZ',
    iso3: 'SWZ',
    phone_code: '268',
    flag_emoji: '🇸🇿',
  },
  {
    nombre: 'Tadjikistán',
    name: 'Tajikistan',
    nom: 'Le Tadjikistan',
    iso2: 'TJ',
    iso3: 'TJK',
    phone_code: '992',
    flag_emoji: '🇹🇯',
  },
  {
    nombre: 'Tailandia',
    name: 'Thailand',
    nom: 'Thaïlande',
    iso2: 'TH',
    iso3: 'THA',
    phone_code: '66',
    flag_emoji: '🇹🇭',
  },
  {
    nombre: 'Taiwán',
    name: 'Taiwan',
    nom: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    phone_code: '886',
    flag_emoji: '🇹🇼',
  },
  {
    nombre: 'Tanzania',
    name: 'Tanzania',
    nom: 'Tanzanie',
    iso2: 'TZ',
    iso3: 'TZA',
    phone_code: '255',
    flag_emoji: '🇹🇿',
  },
  {
    nombre: 'Timor Oriental',
    name: 'East Timor',
    nom: 'Timor-Oriental',
    iso2: 'TL',
    iso3: 'TLS',
    phone_code: '670',
    flag_emoji: '🇹🇱',
  },
  {
    nombre: 'Togo',
    name: 'Togo',
    nom: 'Togo',
    iso2: 'TG',
    iso3: 'TGO',
    phone_code: '228',
    flag_emoji: '🇹🇬',
  },
  {
    nombre: 'Tokelau',
    name: 'Tokelau',
    nom: 'Tokélaou',
    iso2: 'TK',
    iso3: 'TKL',
    phone_code: '690',
    flag_emoji: '🇹🇰',
  },
  {
    nombre: 'Tonga',
    name: 'Tonga',
    nom: 'Tonga',
    iso2: 'TO',
    iso3: 'TON',
    phone_code: '676',
    flag_emoji: '🇹🇴',
  },
  {
    nombre: 'Trinidad y Tobago',
    name: 'Trinidad and Tobago',
    nom: 'Trinidad et Tobago',
    iso2: 'TT',
    iso3: 'TTO',
    phone_code: '1868',
    flag_emoji: '🇹🇹',
  },
  {
    nombre: 'Tunez',
    name: 'Tunisia',
    nom: 'Tunisie',
    iso2: 'TN',
    iso3: 'TUN',
    phone_code: '216',
    flag_emoji: '🇹🇳',
  },
  {
    nombre: 'Turkmenistán',
    name: 'Turkmenistan',
    nom: 'Le Turkménistan',
    iso2: 'TM',
    iso3: 'TKM',
    phone_code: '993',
    flag_emoji: '🇹🇲',
  },
  {
    nombre: 'Turquía',
    name: 'Turkey',
    nom: 'Turquie',
    iso2: 'TR',
    iso3: 'TUR',
    phone_code: '90',
    flag_emoji: '🇹🇷',
  },
  {
    nombre: 'Tuvalu',
    name: 'Tuvalu',
    nom: 'Tuvalu',
    iso2: 'TV',
    iso3: 'TUV',
    phone_code: '688',
    flag_emoji: '🇹🇻',
  },
  {
    nombre: 'Ucrania',
    name: 'Ukraine',
    nom: "L'Ukraine",
    iso2: 'UA',
    iso3: 'UKR',
    phone_code: '380',
    flag_emoji: '🇺🇦',
  },
  {
    nombre: 'Uganda',
    name: 'Uganda',
    nom: 'Ouganda',
    iso2: 'UG',
    iso3: 'UGA',
    phone_code: '256',
    flag_emoji: '🇺🇬',
  },
  {
    nombre: 'Uruguay',
    name: 'Uruguay',
    nom: 'Uruguay',
    iso2: 'UY',
    iso3: 'URY',
    phone_code: '598',
    flag_emoji: '🇺🇾',
  },
  {
    nombre: 'Uzbekistán',
    name: 'Uzbekistan',
    nom: "L'Ouzbékistan",
    iso2: 'UZ',
    iso3: 'UZB',
    phone_code: '998',
    flag_emoji: '🇺🇿',
  },
  {
    nombre: 'Vanuatu',
    name: 'Vanuatu',
    nom: 'Vanuatu',
    iso2: 'VU',
    iso3: 'VUT',
    phone_code: '678',
    flag_emoji: '🇻🇺',
  },
  {
    nombre: 'Venezuela',
    name: 'Venezuela',
    nom: 'Venezuela',
    iso2: 'VE',
    iso3: 'VEN',
    phone_code: '58',
    flag_emoji: '🇻🇪',
  },
  {
    nombre: 'Vietnam',
    name: 'Vietnam',
    nom: 'Vietnam',
    iso2: 'VN',
    iso3: 'VNM',
    phone_code: '84',
    flag_emoji: '🇻🇳',
  },
  {
    nombre: 'Wallis y Futuna',
    name: 'Wallis and Futuna',
    nom: 'Wallis et Futuna',
    iso2: 'WF',
    iso3: 'WLF',
    phone_code: '681',
    flag_emoji: '🇼🇫',
  },
  {
    nombre: 'Yemen',
    name: 'Yemen',
    nom: 'Yémen',
    iso2: 'YE',
    iso3: 'YEM',
    phone_code: '967',
    flag_emoji: '🇾🇪',
    codepoint: '&U+1F1FB',
  },
  {
    nombre: 'Yibuti',
    name: 'Djibouti',
    nom: 'Djibouti',
    iso2: 'DJ',
    iso3: 'DJI',
    phone_code: '253',
    flag_emoji: '🇩🇯',
  },
  {
    nombre: 'Zambia',
    name: 'Zambia',
    nom: 'Zambie',
    iso2: 'ZM',
    iso3: 'ZMB',
    phone_code: '260',
    flag_emoji: '🇿🇲',
  },
  {
    nombre: 'Zimbabue',
    name: 'Zimbabwe',
    nom: 'Zimbabwe',
    iso2: 'ZW',
    iso3: 'ZWE',
    phone_code: '263',
    flag_emoji: '🇿🇼',
  },
];

export default COUNTRIES;
