import React from 'react';
import { Typography } from '@mui/material';

const SuccessMessage = ({ successMsg, ...props }) => (
  <>
    {successMsg !== '' && (
      <Typography component="p" variant="success" {...props}>
        {successMsg}
      </Typography>
    )}
  </>
);

export default SuccessMessage;
