const styles = {
  inputStyle: {
    backgroundColor: '#D7D7D7',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: '#333',
    borderRadius: 10,
    marginTop: 10,
  },
  btnLoginForm: {
    backgroundColor: '#013E68',
    borderRadius: 10,
    color: '#FFF',
    padding: 10,
    borderStyle: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: "'Open-sans', sans-serif",
    fontSize: 16,
    fontWeight: '500',
    textTransform: 'none',
    transition: '1s',
    marginBottom: 15,
    width: 150,
    '&:hover': {
      backgroundColor: '#013E68',
      opacity: 0.9,
      borderWidth: 0,
    },
  },
  loginboxdiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleLogin: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: '600',
    fontSize: '36px',
    color: '#FFF',
    marginBottom: '20px',
    textAlign: 'center',
    '@media only screen and (max-width : 800px)': {
      fontSize: '32px',
    },
    '@media only screen and (max-width : 500px)': {
      fontSize: '28px',
      marginBottom: '5px',
    },
    '@media only screen and (max-width : 400px)': {
      fontSize: '28px',
    },
  },
  sectionLogin: {
    width: '50%',
    '@media only screen and (max-width : 800px)': {
      width: '70%',
    },
    '@media only screen and (max-width : 600px)': {
      width: '80%',
    },
    '@media only screen and (max-width : 500px)': {
      width: '85%',
    },
  },
  loginBox: {
    fontFamily: "'Open-sans', sans-serif",
    background: 'linear-gradient(180deg, #6EC1F9 0%, #0273BF 100%)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '27px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '450px',
    maxWidth: '480px',
    '@media only screen and (max-width : 960px)': {
      marginBottom: '25px',
      minWidth: '380px',
    },
    '@media only screen and (max-width : 800px)': {
      marginBottom: '25px',
      minWidth: '180px',
      padding: '40px 15px',
    },
  },
};

export { styles };
