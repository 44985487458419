import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AuthRoute = () => {
  const id = localStorage.getItem('sessionid');
  if (!id) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default AuthRoute;
