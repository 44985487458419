import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/* Others */
import Router from './Router';
import ThemeContext, { config } from './config/Context';
import { lightTheme, darkTheme } from './config';

const env = process.env.REACT_APP_ENV;

// eslint-disable-next-line no-console
console.log(env);
const App = () => {
  const snackbar = useRef();
  const [mode, setMode] = useState('light');
  const [conf, setConf] = useState(config);

  const handleChangeConfig = () => {
    localStorage.setItem('registerMode', conf.registerMode === 0 ? 1 : 0);
    setConf(prevState => {
      return {
        ...prevState,
        registerMode: prevState.registerMode === 0 ? 1 : 0,
      };
    });
  };

  const toggleColorMode = () => {
    localStorage.setItem('theme', mode === 'light' ? 'dark' : 'light');
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    setMode(() =>
      localStorage.getItem('theme') !== undefined &&
      localStorage.getItem('theme') === 'dark'
        ? 'dark'
        : 'light',
    );

    localStorage.setItem(
      'theme',
      localStorage.getItem('theme') !== undefined &&
        localStorage.getItem('theme') === 'dark'
        ? 'dark'
        : 'light',
    );

    setConf(prevState => {
      return {
        ...prevState,
        registerMode:
          localStorage.getItem('registerMode') !== undefined &&
          parseInt(localStorage.getItem('registerMode')) === 1
            ? 1
            : 0,
      };
    });
    const waitScreen = document.getElementById('waitScreen');
    if (waitScreen) {
      waitScreen.style.opacity = 0;
      setTimeout(() => {
        document.body.setAttribute('style', '');
        document.body.removeChild(waitScreen);
      }, 1000);
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{ conf, handleChangeConfig, mode, toggleColorMode }}>
      <SnackbarProvider
        ref={snackbar}
        action={key => (
          <IconButton
            aria-label="close"
            onClick={() => snackbar.current.closeSnackbar(key)}
            sx={{
              color: '#FFF',
            }}>
            <CloseIcon />
          </IconButton>
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <BrowserRouter>
          <ThemeProvider theme={mode === 'dark' ? darkTheme : lightTheme}>
            <div data-theme={mode}>
              <Router />
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeContext.Provider>
  );
};

export default App;
