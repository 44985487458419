import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    userName: {
      fontWeight: 600,
      marginTop: 5,
      marginBottom: 20,
    },
    subjectInput: {
      backgroundColor: theme.palette.config.recipeInputBg,
      '& div': {
        background: 'transparent !important',
      },
    },
    inputBox: {
      backgroundColor: theme.palette.config.recipeInputBg,
    },
    dscText: {
      color: theme.palette.config.contentText,
    },
    textAreaSep: {
      marginTop: 20,
    },
  }),
  { index: 1 },
);

export default useStyles;
