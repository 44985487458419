import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    welcomeBackground: {
      marginTop: '-80px !important',
      paddingTop: 80,
      backgroundColor: '#002854',
      paddingBottom: 40,
      '@media only screen and (max-width : 1000px)': {
        marginTop: '0px !important',
      },
    },
    featureSection: {
      backgroundColor: '#2C023E',
    },
    welcomeTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: 50,
      color: '#FFF',
      textAlign: 'center',
      '@media only screen and (max-width : 1040px)': {
        fontSize: 45,
      },
      '@media only screen and (max-width : 950px)': {
        fontSize: 40,
      },
      '@media only screen and (max-width : 900px)': {
        fontSize: 40,
      },
      '@media only screen and (max-width : 850px)': {
        fontSize: 35,
      },
      '@media only screen and (max-width : 800px)': {
        fontSize: 30,
      },
      '@media only screen and (max-width : 750px)': {
        fontSize: 25,
      },
    },
    welcomeBox: {
      margin: '50px 15%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '@media only screen and (max-width : 650px)': {
        margin: '20px 10%',
      },
      '@media only screen and (max-width : 550px)': {
        margin: '15px 5%',
      },
    },
    welcomeImage: {
      marginTop: '25px',
      width: '60%',
      maxWidth: '500px',
    },
    backgroundWelcome: {
      width: '85%',
      position: 'absolute',
      top: '0',
      zIndex: '1',
      maxHeight: '750px',
      right: '10%',
    },
    featureRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      transition: '1s',
    },
    featureRowBigger: {
      opacity: '0',
      transform: 'scale(0)',
    },
    featuresMini: {
      justifyContent: 'space-around',
    },
    featureColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sectionBox: {
      padding: '50px 80px',
      '@media only screen and (max-width : 900px)': {
        padding: '50px 60px',
      },
      '@media only screen and (max-width : 800px)': {
        padding: '50px 40px',
      },
      '@media only screen and (max-width : 750px)': {
        padding: '45px 20px',
      },
    },
    globalImg: {
      width: '50%',
      '@media only screen and (max-width : 850px)': {
        width: '40%',
      },
      '@media only screen and (max-width : 600px)': {
        width: '50%',
        marginTop: '20px',
      },
    },
    globalImgMini: {
      width: '25%',
    },
    imgLeft: {
      marginRight: '30px',
      '@media only screen and (max-width : 600px)': {
        marginRight: '0px',
      },
    },
    imgRight: {
      marginLeft: '30px',
      '@media only screen and (max-width : 600px)': {
        marginLeft: '0px',
      },
    },
    titleFeature: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '24px',
      letterSpacing: '0.05em',
      color: '#FFF',
      '@media only screen and (max-width : 950px)': {
        fontSize: '22px',
      },
      '@media only screen and (max-width : 850px)': {
        fontSize: '20px',
      },
      '@media only screen and (max-width : 650px)': {
        fontSize: '17px',
        marginTop: '30px',
      },
      '@media only screen and (max-width : 600px)': {
        marginTop: '15px',
      },
      '@media only screen and (max-width : 550px)': {
        fontSize: '16px',
      },
    },
    dscTitleFeatureBoxLeft: {
      paddingLeft: '5%',
      '@media only screen and (max-width: 1200px)': {
        paddingLeft: '2%',
      },
      '@media only screen and (max-width: 600px)': {
        paddingRight: '0%',
      },
    },
    dscTitleFeatureBoxRight: {
      paddingRight: '5%',
      '@media only screen and (max-width: 1200px)': {
        paddingRight: '2%',
      },
      '@media only screen and (max-width: 600px)': {
        paddingRight: '0%',
      },
    },
    dscFeature: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 'normal',
      fontSize: 18,
      lineHeight: '25px',
      textAlign: 'left',
      marginTop: 10,
      color: '#FFF',
      '@media only screen and (max-width : 950px)': {
        fontSize: 16,
      },
      '@media only screen and (max-width : 850px)': {
        fontSize: 14,
      },
      '@media only screen and (max-width : 750px)': {
        fontSize: 18,
      },
    },
    dscFeatureMini: {
      fontSize: '16px',
      color: '#FFF',
      '@media only screen and (max-width : 900px)': {
        fontSize: '15px',
      },
      '@media only screen and (max-width : 850px)': {
        fontSize: '14px',
      },
      '@media only screen and (max-width : 750px)': {
        fontSize: '13px',
      },
    },
    featureRowMini: {
      width: '45%',
      '@media only screen and (max-width : 600px)': {
        width: '85%',
        margin: '15px 0px',
      },
      '@media only screen and (max-width : 500px)': {
        width: '95%',
      },
      '@media only screen and (max-width : 400px)': {
        width: '98%',
      },
    },
    separateFeature: {
      width: '50%',
      margin: '50px auto',
      '@media only screen and (max-width : 600px)': {
        width: '65%',
        margin: '25px auto',
      },
      '@media only screen and (max-width : 550px)': {
        width: '70%',
      },
      '@media only screen and (max-width : 450px)': {
        width: '75%',
      },
      '@media only screen and (max-width : 400px)': {
        width: '80%',
      },
    },
    pricesSection: {
      backgroundColor: '#2A4D75',
      marginLeft: '0px',
      marginRight: '0px',
      padding: '40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    titlePrices: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '32px',
      textAlign: 'center',
      letterSpacing: '0.05em',
      color: '#FFFFFF',
      '@media only screen and (max-width : 500px)': {
        fontSize: '28px',
      },
      '@media only screen and (max-width : 450px)': {
        fontSize: '27px',
      },
      '@media only screen and (max-width : 400px)': {
        fontSize: '26px',
      },
    },
    switchLetters: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '20px',
      textAlign: 'center',
      padding: '15px 15px',
      width: '55%',
      cursor: 'pointer',
      color: '#FFFFFF',
      textTransform: 'none',
      userSelect: 'none',
      marginRight: 0,
      '@media only screen and (max-width : 750px)': {
        fontSize: '18px',
      },
      '@media only screen and (max-width : 650px)': {
        fontSize: '17px',
        padding: '12px 12px',
      },
      '@media only screen and (max-width : 600px)': {
        fontSize: '16px',
      },
      '@media only screen and (max-width : 550px)': {
        fontSize: '15px',
        padding: '10px 10px',
      },
    },
    kindMembership: {
      backgroundColor: '#49ABED',
      borderRadius: '30px',
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'center',
      width: '35%',
      flexDirection: 'row',
      '@media only screen and (max-width : 750px)': {
        width: '40%',
      },
      '@media only screen and (max-width : 650px)': {
        width: '42%',
      },
      '@media only screen and (max-width : 600px)': {
        width: '50%',
      },
      '@media only screen and (max-width : 550px)': {
        width: '52%',
      },
      '@media only screen and (max-width : 500px)': {
        width: '60%',
      },
      '@media only screen and (max-width : 450px)': {
        width: '65%',
      },
      '@media only screen and (max-width : 400px)': {
        width: '70%',
      },
    },
    discount: {
      marginTop: '15px',
      fontFamily: "'Open Sans', sans-serif",
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#F24976',
      textAlign: 'center',
    },
    separatorKind: {
      display: 'block',
      width: '1.5px',
      background: '#000',
    },
    boxPrices: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '95%',
      marginTop: '30px',
      '@media only screen and (max-width : 850px)': {
        width: '100%',
      },
      '@media only screen and (max-width : 650px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    cardPrices: {
      backgroundColor: '#61B0E4',
      width: '27%',
      borderRadius: '28px',
      color: '#FFF',
      padding: '15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '@media only screen and (max-width : 900px)': {
        width: '30%',
      },
      '@media only screen and (max-width : 650px)': {
        width: '60%',
        marginBottom: '30px',
      },
      '@media only screen and (max-width : 550px)': {
        width: '65%',
      },
      '@media only screen and (max-width : 450px)': {
        width: '70%',
      },
      '@media only screen and (max-width : 400px)': {
        width: '80%',
      },
      '@media only screen and (max-width : 350px)': {
        width: '90%',
      },
    },
    titlePricesCard: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '28px',
      textAlign: 'center',
      letterSpacing: '0.03em',
      marginBottom: '10px',
      '@media only screen and (max-width : 850px)': {
        fontSize: '24px',
      },
    },
    imageCard: {
      height: '20%',
      marginBottom: '13px',
      '@media only screen and (max-width : 950px)': {
        height: '17%',
      },
      '@media only screen and (max-width : 850px)': {
        height: '15%',
      },
      '@media only screen and (max-width : 550px)': {
        width: '80%',
      },
      '@media only screen and (max-width : 450px)': {
        width: '65%',
      },
    },
    buttonPrices: {
      borderStyle: 'none',
      padding: '10px 20px',
      background: '#2A4D75',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '30px',
      textDecoration: 'none',
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '18px',
      color: '#FFFFFF',
      margin: '15px 0px',
      cursor: 'pointer',
      outline: 'none',
      textTransform: 'none',
      userSelect: 'none',
      '@media only screen and (max-width : 850px)': {
        fontSize: '16px',
      },
      '&:hover': {
        backgroundColor: '#2A4D75',
      },
    },
    featuresList: {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      color: '#FFFFFF',
      margin: '7px 0px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '@media only screen and (max-width : 850px)': {
        fontSize: '13px',
      },
    },
    userHelpSection: {
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media only screen and (max-width : 600px)': {
        flexDirection: 'column',
      },
    },
    userGuideIcon: {
      width: '100%',
    },
    userGuideDsc: {
      width: '45%',
      '@media only screen and (max-width : 800px)': {
        width: '50%',
      },
      '@media only screen and (max-width : 600px)': {
        width: '90%',
      },
    },
    titleUser: {
      textAlign: 'center',
      fontFamily: "'Montserrat',sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '34px',
      letterSpacing: '0.06em',
      margin: '30px 0px',

      color: '#3D3D3D',
      '@media only screen and (max-width : 900px)': {
        fontSize: '32px',
      },
      '@media only screen and (max-width : 850px)': {
        fontSize: '30px',
      },
      '@media only screen and (max-width : 800px)': {
        fontSize: '28px',
      },
      '@media only screen and (max-width : 400px)': {
        fontSize: '24px',
      },
    },
    dscUser: {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '22px',
      color: '#3D3D3D',
      '@media only screen and (max-width : 800px)': {
        fontSize: '20px',
      },
      '@media only screen and (max-width : 750px)': {
        fontSize: '18px',
      },
      '@media only screen and (max-width : 600px)': {
        textAlign: 'center',
      },
      '@media only screen and (max-width : 400px)': {
        fontSize: '17px',
      },
    },
    marginDsc: {
      margin: '45px 0px',
      '@media only screen and (max-width : 600px)': {
        margin: '30px 0px',
      },
    },
    linkDirect: {
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 'bold',
      color: '#3D3D3D',
    },
    contact: {
      background: 'linear-gradient(#003E82, #8825B2)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: '#FFF',
      '@media only screen and (max-width: 700px)': {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
    logoTraumapp: {
      width: '80px',
      height: '80px',
      '@media only screen and (max-width : 800px)': {
        width: '70px',
        height: '70px',
      },
      '@media only screen and (max-width: 700px)': {
        display: 'none !important',
      },
    },
    nameLogo: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: 20,
      letterSpacing: '0.03em',
      '@media only screen and (max-width: 700px)': {
        display: 'none',
      },
    },
    itemsFaq: {
      display: 'flex',
      flexDirection: 'row',
      width: '30%',
      '@media only screen and (max-width: 700px)': {
        width: '50%',
        justifyContent: 'center',
      },
    },
    itFaq: {
      margin: '10px 25px',
      display: 'flex',
      flexDirection: 'column',
      '@media only screen and (max-width : 950px)': {
        margin: '10px 20px',
      },
      '@media only screen and (max-width: 700px)': {
        margin: '25px 20px',
      },
    },
    itemFaq: {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      color: '#FFF',
      textAlign: 'center',
      letterSpacing: '0.03em',
      margin: '15px 0px',
      transition: '1s',
      '@media only screen and (max-width : 600px)': {
        width: '60%',
      },
      '@media only screen and (max-width : 550px)': {
        width: '66%',
      },
      '@media only screen and (max-width : 500px)': {
        width: '75%',
      },
      '@media only screen and (max-width : 450px)': {
        width: '80%',
      },
      '@media only screen and (max-width : 350px)': {
        width: '95%',
      },
      '&:hover': {
        color: '#ccc',
      },
    },
    itemPointer: {
      cursor: 'pointer',
    },
    emailSend: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '30%',
      '@media only screen and (max-width : 950px)': {
        width: '35%',
      },
      '@media only screen and (max-width: 700px)': {
        width: '50%',
      },
      '@media only screen and (max-width : 600px)': {
        width: '60%',
      },
      '@media only screen and (max-width : 550px)': {
        width: '66%',
      },
      '@media only screen and (max-width : 500px)': {
        width: '75%',
      },
      '@media only screen and (max-width : 450px)': {
        width: '80%',
      },
      '@media only screen and (max-width : 350px)': {
        width: '95%',
      },
    },
    linkIcon: {
      width: '20%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '@media only screen and (max-width : 850px)': {
        width: '15%',
      },
      '@media only screen and (max-width: 700px)': {
        width: '50%',
      },
      '@media only screen and (max-width : 600px)': {
        width: '60%',
      },
      '@media only screen and (max-width : 550px)': {
        width: '66%',
      },
      '@media only screen and (max-width : 500px)': {
        width: '75%',
      },
      '@media only screen and (max-width : 450px)': {
        width: '80%',
      },
      '@media only screen and (max-width : 350px)': {
        width: '95%',
      },
    },
    formBox: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
    },
    formInput: {
      borderStyle: 'none',
      backgroundColor: '#EBE8E8',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      margin: '10px 0px 0px 0px !important',
      outline: 'none',
      fontFamily: "'Open Sans', sans-serif",
      color: '#444',
    },
    formTextarea: {
      color: '#444',
      fontFamily: "'Open Sans', sans-serif",
      resize: 'none',
      margin: '10px 0px !important',
      background: '#EBE8E8',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      outline: 'none',
    },
    titleEmail: {
      fontWeight: '600',
      fontSize: 24,
      textAlign: 'center',
      letterSpacing: '0.03em',
    },
    subtitleEmail: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 'normal',
      fontSize: '18px',
      textAlign: 'center',
      letterSpacing: '0.03em',
      marginBottom: '5px',
    },
    btnForm: {
      borderStyle: 'none',
      background: '#49ABED',
      borderRadius: 27,
      fontFamily: "'Open Sans', sans-serif",
      fontSize: 18,
      padding: 10,
      color: '#FFFFFF',
      width: '70%',
      alignSelf: 'center',
      outline: 'none',
      textTransform: 'none',
      marginTop: 10,
      '&:hover': {
        backgroundColor: '#49ABED',
      },
    },
    tickPrice: {
      width: '20px',
      marginRight: '10px',
    },
    commonFeatures: {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    commomFeaturesTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '26px',
      textAlign: 'center',
      marginBottom: '15px',
      color: '#FFFFFF',
      '@media only screen and (max-width : 550px)': {
        fontSize: '30px',
      },
      '@media only screen and (max-width : 500px)': {
        fontSize: '28px',
      },
      '@media only screen and (max-width : 450px)': {
        fontSize: '24px',
      },
      '@media only screen and (max-width : 400px)': {
        fontSize: '22px',
      },
      '@media only screen and (max-width : 350px)': {
        fontSize: '20px',
      },
    },
    switchLeft: {
      borderRadius: '30px 0px 0px 30px',
    },
    switchRight: {
      borderRadius: '0px 30px 30px 0px',
    },
    switchActiveLeft: {
      backgroundColor: '#002854',
      '&:hover': {
        backgroundColor: '#002854',
      },
    },
    switchActiveRight: {
      backgroundColor: '#002854',
      '&:hover': {
        backgroundColor: '#002854',
      },
    },
    successEmailContact: {
      textAlign: 'center',
      color: '#66E26F',
    },
    featureRowRight: {
      '@media only screen and (max-width : 600px)': {
        flexDirection: 'column-reverse',
      },
    },
    featureRowLeft: {
      '@media only screen and (max-width : 600px)': {
        flexDirection: 'column',
      },
    },
    dscMiniBox: {
      '@media only screen and (max-width : 600px)': {
        marginLeft: '15px',
      },
    },
    scrolledItem: {
      scrollMarginTop: '90px',
    },
    badgeGoogle: {
      width: '200px',
      height: 'auto',
      marginTop: '15px',
    },
    loaderBar: {
      backgroundColor: '#FFF',
      width: '50%',
    },
    titleUtil: {
      color: '#FFF',
      fontSize: 20,
      textAlign: 'center',
      letterSpacing: '0.03em',
    },
    traumAppDesktopSection: {
      background: 'linear-gradient(180deg, #49ABED 0%, #51136C 100%)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 100,
      paddingBottom: 100,
      '@media only screen and (max-width : 600px)': {
        flexDirection: 'column',
        paddingTop: 50,
        paddingBottom: 50,
      },
    },
    traumAppDesktopImgBox: {
      width: '40%',
      '@media only screen and (max-width : 600px)': {
        width: '60%',
        marginBottom: 30,
      },
    },
    traumAppDesktopImg: {
      width: '100%',
    },
    desktopDsc: {
      width: '45%',
      display: 'flex',
      flexDirection: 'column',
      '@media only screen and (max-width : 600px)': {
        width: '100%',
      },
    },
    titleDesktop: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 36,
      textAlign: 'center',
      color: '#FFF',
      letterSpacing: '0.2em',
      '@media only screen and (max-width : 900px)': {
        fontSize: 32,
      },
      '@media only screen and (max-width : 850px)': {
        fontSize: 30,
      },
      '@media only screen and (max-width : 800px)': {
        fontSize: 28,
      },
      '@media only screen and (max-width : 400px)': {
        fontSize: 24,
      },
    },
    dscDesktopBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dscDesktop: {
      fontFamily: 'Open Sans',
      fontWeight: '500',
      fontSize: 18,
      margin: '35px 0px',
      letterSpacing: '0.03em',
      color: '#FFF',
      '@media only screen and (max-width : 900px)': {
        fontSize: 16,
      },
    },
    buttonDownloadDesktop: {
      background: '#B459DB',
      borderRadius: 41,
      width: 200,
      padding: '10px 20px',
      color: '#FFF',
      fontWeight: '500',
      marginTop: 10,
      transition: '0.5s',
      '&:hover': {
        background: '#B459DB90',
      },
    },
    userGuideBox: {
      width: '40%',
      '@media only screen and (max-width : 600px)': {
        width: '60%',
        marginBottom: 30,
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
