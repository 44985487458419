import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    installApp: {
      width: '95%',
      backgroundColor: '#51136C',
      borderRadius: 27,
      boxShadow: theme.shadows[2],
      padding: 20,
      color: '#FFF',
      margin: 'auto',
      marginBottom: 30,
      marginTop: 20,
      '@media only screen and (max-width : 800px)': {
        width: '80%',
      },
      '@media only screen and (max-width : 450px)': {
        width: '85%',
      },
    },
    installAppTitle: {
      fontWeight: 600,
    },
    installAppMsg: {
      marginTop: 10,
      fontWeight: 400,
    },
    linkApp: {
      color: '#DDD',
    },
  }),
  { index: 1 },
);

export default useStyles;
