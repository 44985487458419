import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    headerBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      '@media only screen and (max-width: 1000px)': {
        backgroundColor: '#64068D',
        padding: 0,
      },
      '@media only screen and (max-width: 950px)': {
        padding: 0,
      },
    },
    headerBoxFloat: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      '@media only screen and (max-width: 950px)': {
        padding: 0,
      },
    },
    logoTraumappHeader: {
      margin: '10px 0px 10px 15px',
      width: '35px',
      height: '35px',
    },
    logoName: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '18px',
      letterSpacing: '0.17em',
      margin: '10px 0px 10px 10px',
      color: '#FFF',
      cursor: 'pointer',
      '@media only screen and (max-width : 300px)': {
        display: 'none',
      },
    },
    logoBox: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
    btnLogin: {
      backgroundColor: '#D168FF',
      borderStyle: 'none',
      padding: '10px 35px',
      borderRadius: '27px',
      marginRight: '25px',
      fontFamily: '"Montserrat", sans-serif',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      color: '#FFF',
      transition: '1s',
      '&:hover': {
        backgroundColor: '#D168FF',
        opacity: 0.8,
      },
    },
    btnLoginTxt: {
      marginRight: '25px',
      textDecoration: 'none',
      fontFamily: '"Montserrat", sans-serif',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '18px',
      color: '#FFF',
      cursor: 'pointer',
      transition: '0.8s',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        color: '#AAA',
      },
    },
    userLogo: {
      width: '20px',
      height: '20px',
      marginRight: '6px',
    },
    buttonNav: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: 18,
      padding: '0px 15px',
      color: '#FFF',
      textDecoration: 'none',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      background: 'linear-gradient(360deg, rgba(0,0,0,0) 50%,#DF95FF 0)',
      backgroundSize: '100% 200%',
      backgroundPosition: '100% 100%',
      transition: '0.3s',
      '@media only screen and (max-width : 1100px)': {
        padding: '0px 12px',
        fontSize: 17,
      },
      '@media only screen and (max-width : 950px)': {
        padding: '0px 15px',
        fontSize: 18,
      },
      '&:hover': {
        backgroundPosition: '100% 0',
        color: '#222',
      },
    },
    navBox: {
      display: 'flex',
      flexDirection: 'row',
      '@media only screen and (max-width : 950px)': {
        flexDirection: 'column',
        backgroundColor: '#F6F6F6',
        margin: 'auto',
        width: '95%',
      },
    },
    btnLoginBox: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 0px',
    },
    floatHeader: {
      position: 'fixed',
      top: '0',
      width: '100%',
      opacity: '0',
      zIndex: '9999',
      transition: '1s',
      backgroundColor: '#64068D',
    },
    burguer: {
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      cursor: 'pointer',
    },
    line: {
      width: '35px',
      height: '3px',
      display: 'block',
      backgroundColor: '#FFF',
      margin: '4px 20px',
    },
    firstItemMobile: {
      marginTop: '5px',
    },
    separationItem: {
      display: 'block',
      width: '95%',
      height: '1px',
      backgroundColor: '#777',
      margin: '3px auto',
    },
    showFloatHeader: {
      opacity: 1,
    },
    showMobile: {
      display: 'none',
      '@media only screen and (max-width : 950px)': {
        display: 'flex',
      },
    },
    buttonNavMobile: {
      color: '#333',
    },
    showDesktop: {
      '@media only screen and (max-width : 950px)': {
        display: 'none',
      },
    },
    list: {
      backgroundColor: '#002854',
      height: '0px',
      transform: 'scaleY(0)',
      transition: '1s',
    },
    listFloat: {
      height: '0px',
      transform: 'scaleY(0)',
      transition: '1s',
    },
    listActive: {
      transform: 'scaleY(1)',
    },
  }),
  { index: 1 },
);

export default useStyles;
