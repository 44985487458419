import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    mainBox: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '30px !important',
      minHeight: '100vh',
      position: 'relative',
    },
    titleHeader: {
      color: theme.palette.headerStyled.titleColor,
      letterSpacing: '0.1em',
    },
    subtitleHeader: {
      letterSpacing: '0.1em',
      color: theme.palette.headerStyled.subtitleColor,
    },
  }),
  { index: 1 },
);

export default useStyles;
