import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

import {
  LOGO_TRAUMAPP,
  FLOAT_TRAUMAPP_MENU,
  PURPLE_BALLS1,
  PURPLE_BALLS2,
  GOOGLE_PLAY_BADGE,
} from 'images';
import { Footer, Loading, ImgLoader } from 'components';
import { useLayoutLoginStyles } from 'styles';

const LayoutLogin = ({ children, isLoading }) => {
  const location = useLocation();

  const [withoutPhone, setwithoutPhone] = useState(false);
  const classes = useLayoutLoginStyles();

  useEffect(() => {
    if (location.pathname === '/user-register') {
      setwithoutPhone(true);
    } else {
      setwithoutPhone(false);
    }
  }, [location]);

  return (
    <main className={classes.mainLogin}>
      <CssBaseline />
      <header className={classes.bgHeaderMainBox}>
        <Link to="/" className={classes.logoLoginBox}>
          <img
            src={LOGO_TRAUMAPP}
            className={classes.logoTraumappLogin}
            title="TraumApp"
            alt="TraumApp"
          />
        </Link>
        <Typography
          component="h4"
          variant="h4"
          className={classes.titleLoginLogo}>
          Traumapp
        </Typography>
      </header>
      <div className={classes.mainBox}>
        <div
          className={`${
            withoutPhone
              ? `${classes.sectionLoginWithoutPhone} ${classes.sectionPresentationImageWithoutPhone}`
              : `${classes.sectionLogin} ${classes.sectionPresentationImage}`
          }`}>
          <img
            src={PURPLE_BALLS1}
            alt="Purple Balls Background"
            className={`${classes.backgroundBalls1} ${
              withoutPhone
                ? `${classes.purpleBallsGlobalWithoutPhone}`
                : `${classes.purpleBallsGlobal}`
            }`}
          />
          {!withoutPhone && (
            <>
              <ImgLoader
                src={FLOAT_TRAUMAPP_MENU}
                height="350px"
                heighttag="350"
                widthtag="350"
                classes={classes.mobile3d}
                title="TraumApp menu de formularios"
                alt="TraumApp menu de formularios"
                id="traumappForms"
              />
              <h1 className={classes.titleLoginImage}>
                Empieza a agilizar tus consultas <b>médicas</b>
              </h1>
              <a
                href="https://play.google.com/store/apps/details?id=com.traumapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
                className={classes.badgeBox}
                rel="noreferrer">
                <img
                  alt="Disponible en Google Play"
                  src={GOOGLE_PLAY_BADGE}
                  className={classes.badgeGoogle}
                />
              </a>
            </>
          )}
          <img
            src={PURPLE_BALLS2}
            alt="Purple Balls Background"
            className={`${classes.backgroundBalls2} ${classes.purpleBallsGlobal}`}
          />
          {withoutPhone && children}
        </div>
        {!withoutPhone && children}
      </div>
      <Footer />
      {isLoading ? <Loading /> : null}
    </main>
  );
};

export default LayoutLogin;
