import React from 'react';
import { Controller } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import Select, { components } from 'react-select';

import { useReactSelectFormStyles } from '../styles';

const ReactSelectForm = ({
  name, // Nombre del campo
  className, // estilos para la caja de el input
  control, // control proveniente de react-hook-form
  placeholder = '...', // Placeholder
  validations = {}, // Objeto con las validaciones
  defaultValue = '', // Valor por defecto
  items = [], // Items para el listado
  styles = {}, // Estilos especificos
}) => {
  const styled = useReactSelectFormStyles();

  const BoxOption = props => (
    <components.Option {...props}>{props.label}</components.Option>
  );

  const BoxCointainer = ({ children, ...props }) => (
    <div style={{ display: 'flex', flexGrow: 1 }}>
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </div>
  );

  const Placeholder = props => {
    return <components.Placeholder {...props} />;
  };

  const customStyles = {
    menu: provided => ({
      ...provided,
      zIndex: 99999,
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '2px 4px',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      '& > div': {
        padding: 4,
      },
    }),
    menuPortal: provided => ({
      ...provided,
      zIndex: 99999,
    }),
    control: provided => ({
      ...provided,
      ...styles['control'],
    }),
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validations}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <>
          <Select
            id={name}
            style={{}}
            styles={customStyles}
            className={`${className} ${
              fieldState.invalid ? styled.errorBox : ''
            }`}
            options={items}
            placeholder={placeholder}
            components={{
              Placeholder,
              Option: BoxOption,
              ValueContainer: BoxCointainer,
            }}
            {...field}
            value={items.find(c => c.value === field.value)}
            onChange={val => field.onChange(val.value)}
          />
          {fieldState.invalid && (
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default ReactSelectForm;
