import { makeStyles } from '@mui/styles';
import { styles } from './Styles';

const useStyles = makeStyles(
  () => ({
    marginButton: {
      marginTop: 12,
    },
    ...styles,
  }),
  { index: 1 },
);

export default useStyles;
