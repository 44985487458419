import React from 'react';
import { useLazyLoadingDocStyles } from 'styles';

const LazyLoadingDoc = () => {
  const styles = useLazyLoadingDocStyles();

  return (
    <div className={styles.loadingBox}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default LazyLoadingDoc;
