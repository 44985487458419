import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText, Slider, Typography } from '@mui/material';

const SliderForm = ({
  name, // Nombre del input
  label, // Label del input
  className = '', // Clase para la caja del input
  validations = {}, // Objeto con las validaciones
  control, // Control del formulario
  defaultValue = 0, // No explico
  disabled = false, // Deshabilitar caja de texto
  step = 1, // Cantidad que debe recorrer el slider
  max, // Cantidad maxima que puede llegar
  min = 0, // Cantidad minima del slider
  showValue = true, // Mostrar valor
  fullWidth = true, // Ocupar el maximo
  classes = {}, // Clases del componente como tal
  valStyle = '', // mEstilo para la label del valor
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    rules={validations}
    defaultValue={defaultValue}
    render={({ field, fieldState }) => (
      <>
        <FormControl
          fullWidth={fullWidth}
          className={className}
          onChange={({ target: { value } }) => {
            field.onChange(value);
          }}
          error={fieldState.invalid}
          {...props}>
          <Slider
            defaultValue={defaultValue}
            valueLabelDisplay="auto"
            step={step}
            min={min}
            max={max}
            disabled={disabled}
            classes={classes}
            {...props}
            {...field}
          />
          {showValue && (
            <Typography variant="h6" component="p" className={valStyle}>
              {field.value}
            </Typography>
          )}
        </FormControl>

        {fieldState.invalid && (
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        )}
      </>
    )}
  />
);

export default SliderForm;
