import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

const fontsFamily = ['Montserrat', 'Open-sans', 'sans-serif'].join(',');

const theme = createTheme({
  palette: {
    primary: {
      main: '#49ABED',
    },
    secondary: {
      main: '#2E8FD0',
    },
    text: {
      primary: '#010101',
    },
    background: {
      default: '#2D537D',
    },
  },
  typography: {
    fontFamily: fontsFamily,
    error: {
      fontSize: 16,
      color: '#d32f2f',
      fontWeight: 400,
    },
    success: {
      fontSize: 16,
      color: 'rgb(56, 142, 60)',
      fontWeight: 400,
    },
    h1: {
      fontSize: 24,
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: 32,
      },
    },
    h2: {
      fontSize: 22,
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: 28,
      },
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: 24,
      },
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: 22,
      },
      '@media (max-width:400px)': {
        fontSize: 16,
      },
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: 20,
      },
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: 18,
      },
      '@media (max-width:400px)': {
        fontSize: 12,
      },
    },
    p: {
      fontSize: 14,
      fontWeight: 400,
      '@media (min-width:600px)': {
        fontSize: 18,
      },
      '@media (max-width:400px)': {
        fontSize: 12,
      },
    },
    p2: {
      fontSize: 14,
      fontWeight: 400,
      '@media (min-width:600px)': {
        fontSize: 16,
      },
      '@media (max-width:400px)': {
        fontSize: 12,
      },
    },
    sidebar: {
      fontSize: 14,
      fontWeight: 400,
    },
    copyright: {
      fontSize: 14,
      fontWeight: 300,
      '@media (max-width:400px)': {
        fontSize: 12,
      },
    },
    button: {
      fontSize: 12,
      '@media (min-width:600px)': {
        fontSize: 14,
      },
    },
  },
  esES,
});

const rootButton = {
  padding: theme.spacing(1, 3),
  borderRadius: theme.spacing(1),
  margin: 0,
  transition: '0.5s',
  fontWeight: 500,
  fontFamily: `'Open-sans', sans-serif`,
  textTransform: 'none !important',
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.42)',
    color: '#FFFFFF',
  },
  '&:hover': {
    opacity: 0.8,
  },
};

const formHelperTextRoot = {
  width: '100%',
  fontWeight: '600',
  color: '#f44336',
  textAlign: 'left',
  marginTop: 5,
  marginBottom: 6,
  marginLeft: 0,
};

const secondaryButton = {
  color: theme.palette.primary.main,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const primaryButton = {
  color: '#FFFFFF',
  border: '1px solid #FFFFFF',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
};

const transparentWhiteButton = {
  color: '#FFFFFF',
  border: '1px solid #FFFFFF',
};

const formCalendarFilledButton = {
  backgroundColor: '#51136C',
  color: '#FFF',
  boxShadow: theme.shadows[2],
  transition: '0.5s',
  width: 110,
  marginTop: 10,
  '&:hover': {
    opacity: 0.9,
    backgroundColor: '#51136C',
  },
};

const configButton = {
  backgroundColor: '#B459DB',
  color: '#FFF',
  borderRadius: 4,
  '&:hover': {
    backgroundColor: '#B459DB',
    opacity: 0.8,
  },
};

const configOutlinedButton = {
  backgroundColor: 'transparent',
  border: '2px solid #B459DB',
  color: '#000',
  borderRadius: 4,
  '&:hover': {
    border: '2px solid #B459DB',
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
};

theme.components = {
  MuiTypography: {
    styleOverrides: {
      root: {
        wordBreak: 'break-word',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        ...formHelperTextRoot,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        ...rootButton,
      },
      secondary: {
        ...secondaryButton,
      },
      primary: {
        ...primaryButton,
      },
      transparentWhite: {
        ...transparentWhiteButton,
      },
      buttonFormCalendarFilled: {
        ...formCalendarFilledButton,
      },
      config: {
        ...configButton,
      },
      configOutlined: {
        ...configOutlinedButton,
      },
    },
  },
};

export const lightTheme = createTheme({
  ...theme,
  palette: {
    background: {
      default: '#f6f6f6',
    },
    login: {
      mainBoxBackground: '#FFF',
      textColor: '#000',
      recoverText: '#333',
      backgroundGradient1: '#49ABED',
      backgroundGradient2: '#0267AB',
      inputBackground: '#E8E8E8',
      recoverMsg: '#454545',
    },
    home: {
      titleText: '#000',
      dateInfoBox: '#D168FF',
    },
    sidebar: {
      backgroundGradient1: '#BF4FF0',
      backgroundGradient2: '#1A97EC',
      hamburguerColor: '#000',
    },
    headerStyled: {
      arrowColor: '#000',
      dividerColor: '#51136C',
      titleColor: '#51136C',
      subtitleColor: '#000',
      separator: '#000',
    },
    calendar: {
      buttonColor: '#ED80F9',
      borderDateBoxColor: '#51136C',
      dateBox: '#F4DBFF',
      titleColor: '#000',
      dateTextColor: '#000',
      buttonStatusBorder: '#51136C',
      buttonStatusText: '#000',
      detailBoxDate: '#D168FF',
      modalBoxBackground: '#FFFFFF',
      modalTextColor: '#000000',
      buttonDeleteModalText: '#000',
      floatInputBg: '#D168FF',
      borderBoxTable: '#51136C',
      headerBoxTable: '#EDEDED',
      headerTextTable: '#000',
      checkboxDisabled: 'rgba(0, 0, 0, 0.6)',
    },
    patients: {
      tableBoxBg: '#F4DBFF',
      borderBoxTable: '#51136C',
      headerBoxTable: '#EDEDED',
      headerTextTable: '#000',
      footerBgTable: '#EDEDED',
      viewIconColor: '#000',
      inputBackground: 'rgba(0, 0, 0, 0.06)',
      patientTitleText: '#000',
      buttonTabPatientDetail: '#F4DBFF',
      buttonTabSelectedPatientDetail: '#EABBFF',
      patientDetailBoxBg: '#F4DBFF',
      searchInputDetailBg: '#DBDBDB',
    },
    forms: {
      buttonMenuBg: '#F4DBFF',
      titleRecipe: '#000',
      titleRecipeSection: '#51136C',
      borderSectionRecipe: '#51136C',
      recipeInputBg: '#E5E5E5',
      recipeMedicineText: '#000',
      iconMedicineColor: '#51136C',
      quantityMedicineBox: '#F4DBFF',
      finalFormBg: '#FFF',
      tabText: 'rgba(180, 89, 219, 0.8)',
      tabUnselectedText: '#000',
      trackSliderBg: '#51136C',
    },
    config: {
      menuButtonBg: '#F4DBFF',
      contentText: '#000',
      switchTrack: '#000',
      modalBoxBg: '#FFF',
      recipeInputBg: '#E5E5E5',
      backgroundBoxDetail: '#F4DBFF',
      borderBox: '#000',
      headerBoxTable: '#EDEDED',
      headerTextTable: '#000',
      footerBgTable: '#EDEDED',
    },
    registerMode: {
      titleText: '#000',
      buttonMenuBg: '#EDC7FE',
      modalBoxBg: '#FFF',
      inputBg: '#E5E5E5',
      borderBoxTable: '#51136C',
      headerBoxTable: '#EDEDED',
      headerTextTable: '#000',
      footerBgTable: '#EDEDED',
      dateDetailBg: '#F4DBFF',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...formHelperTextRoot,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...rootButton,
        },
        secondary: {
          ...secondaryButton,
        },
        primary: {
          ...primaryButton,
        },
        transparentWhite: {
          ...transparentWhiteButton,
        },
        buttonFormCalendarFilled: {
          ...formCalendarFilledButton,
        },
        config: {
          ...configButton,
          backgroundColor: '#782B9A',
          '&:hover': {
            backgroundColor: '#782B9A',
            opacity: 0.8,
          },
        },
        configOutlined: {
          ...configOutlinedButton,
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  ...theme,
  palette: {
    background: {
      default: '#3D3D3D',
    },
    login: {
      mainBoxBackground: '#3D3D3D',
      textColor: '#FFF',
      recoverText: '#EEE',
      backgroundGradient1: '#4298D2',
      backgroundGradient2: '#02406A',
      inputBackground: '#E8E8E8',
      recoverMsg: '#FFF',
    },
    home: {
      titleText: '#FFF',
      dateInfoBox: '#713889',
    },
    sidebar: {
      backgroundGradient1: '#8C13C1',
      backgroundGradient2: '#0D6CAC',
      hamburguerColor: '#FFF',
    },
    headerStyled: {
      arrowColor: '#FFF',
      dividerColor: '#8A29B5',
      titleColor: '#D993F6',
      subtitleColor: '#FFF',
      separator: '#FFF',
    },
    calendar: {
      buttonColor: '#782B9A',
      borderDateBoxColor: '#713889',
      dateBox: '#713889',
      titleColor: '#FFF',
      dateTextColor: '#FFF',
      buttonStatusBorder: '#9F32CE',
      buttonStatusText: '#FFF',
      detailBoxDate: '#713889',
      modalBoxBackground: '#3D3D3D',
      modalTextColor: '#FFF',
      buttonDeleteModalText: '#FFF',
      floatInputBg: '#713889',
      borderBoxTable: '#713889',
      headerBoxTable: '#5B5B5B',
      headerTextTable: '#FFF',
      checkboxDisabled: 'rgba(255, 255, 255, 0.6)',
    },
    patients: {
      tableBoxBg: '#713889',
      borderBoxTable: '#713889',
      headerBoxTable: '#5B5B5B',
      headerTextTable: '#FFF',
      footerBgTable: '#3D3D3D',
      viewIconColor: '#FFF',
      inputBackground: '#E8E8E8',
      patientTitleText: '#FFF',
      buttonTabPatientDetail: '#DAACEE',
      buttonTabSelectedPatientDetail: '#B28BC2',
      patientDetailBoxBg: '#713889',
      searchInputDetailBg: '#BBBABA',
    },
    forms: {
      buttonMenuBg: '#E3A4FF',
      titleRecipe: '#FFF',
      titleRecipeSection: '#B459DB',
      borderSectionRecipe: '#713889',
      recipeInputBg: '#BBBABA',
      recipeMedicineText: '#FFF',
      iconMedicineColor: '#B459DB',
      quantityMedicineBox: '#E3A4FF',
      finalFormBg: '#3D3D3D',
      tabText: '#FFF',
      tabUnselectedText: '#FFF',
      trackSliderBg: '#B459DB',
    },
    config: {
      menuButtonBg: '#E3A4FF',
      contentText: '#FFF',
      switchTrack: '#E3A4FF',
      modalBoxBg: '#3D3D3D',
      recipeInputBg: '#BBBABA',
      backgroundBoxDetail: '#DAACEE',
      borderBox: '#FFF',
      headerBoxTable: '#5B5B5B',
      headerTextTable: '#FFF',
      footerBgTable: '#3D3D3D',
    },
    registerMode: {
      titleText: '#FFF',
      buttonMenuBg: '#E3A4FF',
      modalBoxBg: '#3D3D3D',
      inputBg: '#BBBABA',
      borderBoxTable: '#713889',
      headerBoxTable: '#5B5B5B',
      headerTextTable: '#FFF',
      footerBgTable: '#3D3D3D',
      dateDetailBg: '#E3A4FF',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...formHelperTextRoot,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...rootButton,
        },
        secondary: {
          ...secondaryButton,
        },
        primary: {
          ...primaryButton,
          backgroundColor: '#0A4E7B',
          border: '1px solid #0A4E7B',
          '&:hover': {
            backgroundColor: '#0A4E7B',
          },
        },
        transparentWhite: {
          ...transparentWhiteButton,
        },
        buttonFormCalendarFilled: {
          ...formCalendarFilledButton,
          backgroundColor: '#782B9A',
          '&:hover': {
            opacity: 0.9,
            backgroundColor: '#782B9A',
          },
        },
        config: {
          ...configButton,
          backgroundColor: '#782B9A',
          color: '#FFF',
          '&:hover': {
            backgroundColor: '#782B9A',
            opacity: 0.8,
          },
        },
        configOutlined: {
          ...configOutlinedButton,
          border: '2px solid #782B9A',
          color: '#FFF',
          borderRadius: 4,
          '&:hover': {
            border: '2px solid #782B9A',
            backgroundColor: 'transparent',
            opacity: 0.8,
          },
        },
      },
    },
  },
});

export default theme;
