import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Box, FormHelperText } from '@mui/material';
import { DateTimePicker } from '@mui/lab';

const DateTimePickerForm = ({
  name, // Nombre del campo
  label = '', // label del campo
  className = '', // estilos para la caja de el input
  control, // control proveniente de react-hook-form
  validations = {}, // Objeto con las validaciones
  fullWidth = false, // Si ocupa o no toda la caja donde se encuentra
  required = false, // Marcar campo como requerido
  max = null, // fecha maxima
  min = null, // Fecha minima
  showPicker, // Booleano para mostrar el picker
  setShowPicker, // Funcion para cambiar el booleano del picker
  placeholder = '', // Placeholder para la caja de texto
  boxProps = {}, // Propiedades de la caja del campo de texto
  inputProps = {}, // Propiedades para el input
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={null}
    rules={validations}
    render={({ field, fieldState }) => (
      <DateTimePicker
        label={label}
        value={field.value}
        error={fieldState.invalid}
        onClose={() => setShowPicker(false)}
        onChange={date => {
          setShowPicker(false);
          field.onChange(date);
        }}
        showTodayButton
        open={showPicker}
        fullWidth={fullWidth}
        maxDate={max}
        minDate={min}
        {...field}
        renderInput={params => (
          <Box {...boxProps}>
            <TextField
              {...params}
              fullWidth={fullWidth}
              required={required}
              className={className}
              error={fieldState.invalid}
              onClick={() => setShowPicker(true)}
              inputProps={{
                readOnly: true,
                placeholder,
                value: params.inputProps.value,
                ...inputProps,
              }}
              inputRef={params.inputRef}
              ref={params.ref}
            />
            {fieldState.invalid && (
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            )}
          </Box>
        )}
      />
    )}
  />
);

export default DateTimePickerForm;
