import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
//MaterialUI
import { Button } from '@mui/material';

import { LOGO_TRAUMAPP, USER_ICON } from 'images';

import { ImgLoader } from 'components';

import { logout, API } from 'config';

import { useHeaderHomeStyles } from 'styles';

const treshold = 0.5;
const HeaderHome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const styles = useHeaderHomeStyles();

  const [showFloat, setShowFloat] = useState(false);
  const [activateAnimation, setActivateAnimation] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [username, setUsername] = useState('');

  const sections = [
    {
      link: '/#features',
      dsc: 'Características',
    },
    {
      link: '/#download',
      dsc: 'Descarga',
    },
    {
      link: '/#prices',
      dsc: 'Precios',
    },
    {
      link: '/#guide',
      dsc: 'Guía de usuario',
    },
    {
      link: '/#contact',
      dsc: 'Contacto',
    },
  ];

  useEffect(() => {
    const options = {
      threshold: treshold,
    };
    const div = document.getElementById('welcomeImageVis');

    if (div) {
      const observer = new IntersectionObserver(handleIntersection, options);

      observer.observe(div);

      return () => {
        observer.unobserve(div);
      };
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('name') !== undefined &&
      localStorage.getItem('name') !== '' &&
      localStorage.getItem('name') !== null
    ) {
      setUsername(localStorage.getItem('name'));
    } else {
      setUsername('');
    }
  }, []);

  const handleIntersection = entries => {
    const entry = entries[0];
    const isVisible = entry.intersectionRatio >= treshold;

    if (isVisible) {
      setShowFloat(false);
    } else {
      setShowFloat(true);
    }
  };

  const activeMenu = () => {
    setTimeout(() => {
      setActivateAnimation(!activateAnimation);
    }, 100);
  };

  const goToLogin = () => {
    navigate('/dashboard');
  };

  const validSessionNav = async () => {
    const sessionid = localStorage.getItem('sessionid');

    if (sessionid === null || sessionid === undefined || sessionid === '') {
      navigate('/login');
    } else {
      setIsCalled(true);
      try {
        await API.validateSessionById(sessionid);
        navigate('/dashboard');
        setIsCalled(false);
      } catch (error) {
        const { status, data: res } = error;
        setIsCalled(false);
        switch (status) {
          case 401:
            logout(navigate);
            break;
          default:
            // eslint-disable-next-line no-console
            console.log(res.msg);
            break;
        }
      }
    }
  };

  return (
    <header>
      <div id="headerHome" className={styles.headerBox}>
        {location.pathname !== '/' ? (
          <Link to="/#home" className={styles.logoBox}>
            <ImgLoader
              src={LOGO_TRAUMAPP}
              height="30px"
              heighttag="30"
              widthtag="30"
              classes={styles.logoTraumappHeader}
              title="TraumApp"
              alt="TraumApp"
              id="logoTraumApp1"
            />
            <h1 className={styles.logoName}>TraumApp</h1>
          </Link>
        ) : (
          <a href="/#home" className={styles.logoBox}>
            <ImgLoader
              src={LOGO_TRAUMAPP}
              height="30px"
              heighttag="30"
              widthtag="30"
              classes={styles.logoTraumappHeader}
              title="TraumApp"
              alt="TraumApp"
              id="logoTraumApp1"
            />
            <h1 className={styles.logoName}>TraumApp</h1>
          </a>
        )}

        <nav className={`${styles.navBox} ${styles.showDesktop}`}>
          {sections.map((el, index) => {
            if (location.pathname !== '/') {
              return (
                <Link
                  to={el.link}
                  key={`${el.dsc}-${index}`}
                  className={styles.buttonNav}>
                  {el.dsc}
                </Link>
              );
            } else {
              return (
                <a
                  href={el.link}
                  key={`${el.dsc}-${index}`}
                  className={styles.buttonNav}>
                  {el.dsc}
                </a>
              );
            }
          })}
        </nav>
        <div className={`${styles.btnLoginBox} ${styles.showDesktop}`}>
          {username === '' ? (
            <Button
              onClick={() => (isCalled ? null : validSessionNav())}
              className={styles.btnLogin}
              variant="outlined">
              Login
            </Button>
          ) : (
            <div onClick={() => goToLogin()} className={styles.btnLoginTxt}>
              <ImgLoader
                src={USER_ICON}
                height="20px"
                heighttag="20"
                widthtag="20"
                classes={styles.userLogo}
                title="Usuario"
                alt="User"
                id="userIc1"
              />
              {username}
            </div>
          )}
        </div>
        <div className={styles.showMobile}>
          <div
            className={styles.burguer}
            onClick={() => {
              activeMenu();
            }}>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
          </div>
        </div>
      </div>
      {showFloat ? (
        <div
          id="headerHomeFloat"
          className={`${styles.floatHeader} ${
            showFloat ? styles.showFloatHeader : ''
          }`}>
          <div className={styles.headerBoxFloat}>
            {location.pathname !== '/' ? (
              <Link to="/#home" className={styles.logoBox}>
                <ImgLoader
                  src={LOGO_TRAUMAPP}
                  height="30px"
                  heighttag="30"
                  widthtag="30"
                  classes={styles.logoTraumappHeader}
                  title="TraumApp"
                  alt="TraumApp"
                  id="logoTraumApp2"
                />
                <h1 className={styles.logoName}>TraumApp</h1>
              </Link>
            ) : (
              <a href="/#home" className={styles.logoBox}>
                <ImgLoader
                  src={LOGO_TRAUMAPP}
                  height="30px"
                  heighttag="30"
                  widthtag="30"
                  classes={styles.logoTraumappHeader}
                  title="TraumApp"
                  alt="TraumApp"
                  id="logoTraumApp2"
                />
                <h1 className={styles.logoName}>TraumApp</h1>
              </a>
            )}
            <nav className={`${styles.navBox} ${styles.showDesktop}`}>
              {sections.map((el, index) => {
                if (location.pathname !== '/') {
                  return (
                    <Link
                      to={el.link}
                      key={`${el.dsc}-${index}`}
                      className={styles.buttonNav}>
                      {el.dsc}
                    </Link>
                  );
                } else {
                  return (
                    <a
                      href={el.link}
                      key={`${el.dsc}-${index}`}
                      className={styles.buttonNav}>
                      {el.dsc}
                    </a>
                  );
                }
              })}
            </nav>
            <div className={`${styles.btnLoginBox} ${styles.showDesktop}`}>
              {username === '' ? (
                <Button
                  onClick={() => (isCalled ? null : validSessionNav())}
                  className={styles.btnLogin}
                  variant="outlined">
                  Login
                </Button>
              ) : (
                <div onClick={() => goToLogin()} className={styles.btnLoginTxt}>
                  <ImgLoader
                    src={USER_ICON}
                    height="20px"
                    heighttag="20"
                    widthtag="20"
                    classes={styles.userLogo}
                    title="Usuario"
                    alt="User"
                    id="userIc2"
                  />
                  {username}
                </div>
              )}
            </div>
            <div className={styles.showMobile}>
              <div
                className={styles.burguer}
                onClick={() => {
                  activeMenu();
                }}>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
              </div>
            </div>
          </div>
          <div
            id="list"
            className={`${styles.showMobile} ${styles.listFloat} ${
              activateAnimation ? styles.listActive : ''
            }`}>
            <nav className={styles.navBox}>
              {sections.map((el, index) => (
                <div key={`${el.dsc}-${index}`}>
                  {location.pathname !== '/' ? (
                    <Link
                      to={el.link}
                      className={`${styles.buttonNav} ${styles.buttonNavMobile} ${styles.firstItemMobile}`}>
                      {el.dsc}
                    </Link>
                  ) : (
                    <a
                      href={el.link}
                      className={`${styles.buttonNav} ${styles.buttonNavMobile} ${styles.firstItemMobile}`}>
                      {el.dsc}
                    </a>
                  )}

                  <span className={styles.separationItem}></span>
                </div>
              ))}

              {username === '' ? (
                <div
                  onClick={() => (isCalled ? null : validSessionNav())}
                  className={`${styles.buttonNav} ${styles.buttonNavMobile}`}>
                  Login
                </div>
              ) : (
                <div
                  onClick={() => goToLogin()}
                  className={`${styles.buttonNav} ${styles.buttonNavMobile}`}>
                  <ImgLoader
                    src={USER_ICON}
                    height="20px"
                    heighttag="20"
                    widthtag="20"
                    classes={styles.userLogo}
                    title="Usuario"
                    alt="User"
                    id="userIc3"
                  />
                  {username}
                </div>
              )}
              <span className={styles.separationItem}></span>
            </nav>
          </div>
        </div>
      ) : null}
      <div
        className={`${styles.showMobile} ${styles.listFloat} ${
          activateAnimation ? styles.listActive : ''
        }`}>
        <nav className={styles.navBox}>
          {sections.map((el, index) => (
            <div key={`${el.dsc}-${index}`}>
              {location.pathname !== '/' ? (
                <Link
                  to={el.link}
                  className={`${styles.buttonNav} ${styles.buttonNavMobile} ${styles.firstItemMobile}`}>
                  {el.dsc}
                </Link>
              ) : (
                <a
                  href={el.link}
                  className={`${styles.buttonNav} ${styles.buttonNavMobile} ${styles.firstItemMobile}`}>
                  {el.dsc}
                </a>
              )}
              <span className={styles.separationItem}></span>
            </div>
          ))}
          {username === '' ? (
            <div
              onClick={() => (isCalled ? null : validSessionNav())}
              className={`${styles.buttonNav} ${styles.buttonNavMobile}`}>
              Login
            </div>
          ) : (
            <div
              onClick={() => goToLogin()}
              className={`${styles.buttonNav} ${styles.buttonNavMobile}`}>
              <ImgLoader
                src={USER_ICON}
                height="20px"
                heighttag="20"
                widthtag="20"
                classes={styles.userLogo}
                title="Usuario"
                alt="User"
                id="userIc4"
              />
              {username}
            </div>
          )}
          <span className={styles.separationItem}></span>
        </nav>
      </div>
    </header>
  );
};

export default HeaderHome;
