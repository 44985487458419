import React from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const useStyles = makeStyles(
  theme => ({
    buttonArrow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'translate(0, -50%)',
      width: 20,
      height: 20,
      position: 'absolute',
      top: '50%',
      [theme.breakpoints.down('sm')]: {
        width: 45,
        height: 45,
      },
    },
  }),
  { index: 1 },
);

const ArrowNext = props => {
  const styles = useStyles();
  const { onClick } = props;

  return (
    <IconButton
      className={`${styles.buttonArrow}`}
      style={{ right: -25 }}
      onClick={onClick}>
      <NavigateNextIcon />
    </IconButton>
  );
};

const ArrowPrev = props => {
  const styles = useStyles();
  const { onClick } = props;

  return (
    <IconButton
      className={`${styles.buttonArrow}`}
      style={{ left: -25 }}
      onClick={onClick}>
      <NavigateBeforeIcon />
    </IconButton>
  );
};

export const settingsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <ArrowNext />,
  prevArrow: <ArrowPrev />,
  // eslint-disable-next-line react/display-name
  customPaging: i => (
    <IconButton
      className="cleanBeforeReactSlick"
      style={{
        width: 30,
        height: 30,
        color: '#999',
      }}
    />
  ),
};
