import React from 'react';

import { useBaseDocStyles } from 'styles';

const NavigationBarDoc = ({ sections = [], currentSection = '', ...props }) => {
  const baseStyles = useBaseDocStyles();
  return (
    <nav {...props}>
      <ul className={baseStyles.navigationList}>
        {sections.map((section, index) => {
          return (
            <li key={index.toString()}>
              <a
                href={`#${section.id}`}
                className={`${
                  currentSection === section.id
                    ? baseStyles.selectedSection
                    : ''
                }`}>
                {section.title}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavigationBarDoc;
