import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  titleDetail: {
    color: theme.palette.calendar.titleColor,
  },
  contentBox: {
    maxWidth: 1000,
    width: '85%',
    padding: '0px 30px',
    paddingBottom: 15,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '0px 20px',
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
      width: '95%',
    },
  },
  buttonSep: {
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  buttonStatus: {
    border: `2px solid ${theme.palette.calendar.buttonStatusBorder}`,
    color: theme.palette.calendar.buttonStatusText,
    boxShadow: theme.shadows[2],
    width: 110,
    marginTop: 10,
    '&:hover': {
      opacity: 0.9,
    },
  },
  buttonModal: {
    border: '2px solid #D168FF',
    color: theme.palette.calendar.buttonDeleteModalText,
    boxShadow: theme.shadows[2],
    width: 110,
    marginTop: 10,
    '&:hover': {
      opacity: 0.9,
    },
  },
  detailsBox: {
    backgroundColor: theme.palette.calendar.detailBoxDate,
    borderRadius: 21,
    margin: '25px 0px',
    marginTop: 32,
    color: '#FFF',
    padding: 25,
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0px',
    },
  },
  labelSep: {
    margin: '15px 0px',
  },
  statusDot: {
    marginRight: 8,
  },
  dateDataBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonsBox: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  buttonModalSep: {
    margin: '10px 20px',
  },
  dscModalSep: {
    margin: '20px',
    textAlign: 'center',
  },
  modalBox: {
    backgroundColor: theme.palette.calendar.modalBoxBackground,
    color: theme.palette.calendar.modalTextColor,
  },
}));

export default useStyles;
