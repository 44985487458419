import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  calendarButton: {
    background: theme.palette.calendar.buttonColor,
    color: '#FFF',
    fontWeight: 600,
    '&:hover': {
      opacity: 0.9,
      background: theme.palette.calendar.buttonColor,
    },
  },
  contentBox: {
    padding: '0px 30px',
    paddingBottom: 15,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
    },
  },
  datesBox: {
    display: 'flex',
    flexDirection: 'column',
    border: `2px solid ${theme.palette.calendar.borderDateBoxColor}`,
    boxShadow: theme.shadows[4],
    borderRadius: 40,
    padding: 30,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 8px',
    },
  },
  dateBox: {
    padding: 10,
    borderRadius: 15,
    backgroundColor: theme.palette.calendar.dateBox,
    boxShadow: theme.shadows[4],
    margin: '20px 10px',
    cursor: 'pointer',
    color: theme.palette.calendar.dateTextColor,
    [theme.breakpoints.down('sm')]: {
      margin: '20px 8px',
    },
  },
  datesBoxScrolled: {
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    maxHeight: '72vh',
    minHeight: '72vh',
    '&::-webkit-scrollbar': {
      width: 3,
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
  },
  statusDot: {
    marginLeft: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
    },
  },
  textDate: {
    wordBreak: 'break-word',
  },
  anyRegisterButton: {
    marginTop: 15,
  },
  boxBtnToday: {
    width: '90%',
    margin: 'auto',
    marginBottom: 20,
  },
  titleDates: {
    color: theme.palette.calendar.titleColor,
  },
  sepBoxTextDate: {
    margin: '8px 0px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      marginBottom: 8,
    },
  },
  emptyMsg: {
    color: theme.palette.calendar.titleColor,
  },
}));

export default useStyles;
