import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Hidden, Drawer, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { LOGO_TRAUMAPP } from 'images';

import { useHeaderDocStyles } from 'styles';
import { DrawerDoc } from 'components';

const HeaderDoc = ({ window }) => {
  const styles = useHeaderDocStyles();
  const theme = useTheme();
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <header className={styles.headerBox}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={styles.menuButton}>
        <MenuIcon />
      </IconButton>
      <Link to="/" className={styles.headerLink}>
        <img
          className={styles.logoTraumapp}
          src={LOGO_TRAUMAPP}
          title="TraumApp"
          alt="TraumApp"
          id="logoTraumApp1"
        />
        <Typography
          variant="h4"
          component="h1"
          className={styles.titleTraumapp}>
          TraumApp
        </Typography>
        <Typography
          component="span"
          variant="body1"
          className={styles.versionText}>
          2.1.13
        </Typography>
      </Link>

      <Hidden xsDown implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            root: styles.backgroundDrawer,
            paper: styles.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}>
          <DrawerDoc />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          className={styles.backgroundDrawer}
          classes={{
            root: styles.backgroundDrawer,
            paper: styles.drawerPaper,
          }}
          variant="permanent"
          open>
          <DrawerDoc />
        </Drawer>
      </Hidden>
    </header>
  );
};

export default HeaderDoc;
