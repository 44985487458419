import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    listContentDetails: {
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    registerStepper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '96vw',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
        marginTop: 10,
        width: '80%',
      },
    },
    imgSizeRegister: {
      width: '96%',
      height: 'auto',
      borderRadius: 15,
      maxWidth: 300,
      border: '0.2px solid #7A7A7A',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        marginBottom: 25,
        width: '80%',
        marginTop: 10,
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
