import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  buttonNavigate: {
    background: theme.palette.config.menuButtonBg,
    borderRadius: 12,
    padding: 16,
    color: '#000000',
    height: '100%',
    maxWidth: 300,
    '&:hover': {
      background: theme.palette.config.menuButtonBg,
      opacity: 0.8,
    },
  },
  imgButton: {
    marginBottom: 16,
    width: '100%',
    height: 'auto',
    maxWidth: 100,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  switchText: {
    fontSize: 18,
    color: `${theme.palette.config.contentText} !important`,
  },
  switchFalseTrack: {
    backgroundColor: theme.palette.config.switchTrack,
  },
  switchThumb: {
    color: '#51136C',
  },
  switchThumbFalse: {
    color: '#FF00F5',
  },
  disabledButton: {
    opacity: 0.7,
    backgroundColor: `${theme.palette.config.menuButtonBg} !important`,
    color: '#000000 !important',
  },
  modalBox: {
    backgroundColor: theme.palette.config.modalBoxBg,
    color: theme.palette.config.contentText,
  },
  buttonExportData: {
    minWidth: 150,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
    },
  },
  msgExportModal: {
    fontWeight: 400,
  },
}));

export default useStyles;
