import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    buttonSep: {
      margin: '0px 10px',
      width: 100,
      padding: 8,
      [theme.breakpoints.down('sm')]: {
        margin: '0px 5px',
      },
    },
    mainBox: {
      padding: 10,
      margin: '10px 5px',
    },
    modalBox: {
      backgroundColor: theme.palette.forms.finalFormBg,
    },
  }),
  { index: 1 },
);

export default useStyles;
