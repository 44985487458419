import React from 'react';

export const config = {
  registerMode: 0,
  mode: 'light',
  toggleColorMode: () => {},
};

const ThemeContext = React.createContext(config);

export default ThemeContext;
