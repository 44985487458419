import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    mainBox: {
      marginLeft: 250,
      marginTop: 64,
      backgroundColor: '#FEFEFE',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      minHeight: '90vh',
      '@media only screen and (max-width : 800px)': {
        justifyContent: 'center',
      },
    },
    navigationSideBar: {
      display: 'flex',
      padding: 30,
      width: 200,
      margin: '20px 0px',
      borderLeft: '2px solid #000',
      position: 'fixed',
      height: '40vh',
      minWidth: 100,
      minHeight: 200,
      right: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
      '@media only screen and (max-width : 800px)': {
        display: 'none',
      },
    },
    contentBox: {
      display: 'flex',
      width: '75%',
      padding: '30px 30px 50px 30px',
      flexDirection: 'column',
      '@media only screen and (max-width : 1200px)': {
        width: '70%',
      },
      '@media only screen and (max-width : 800px)': {
        width: '90%',
        padding: '30px 0px 50px 0px',
      },
    },
    navigationList: {
      listStyle: 'none',
      padding: 0,
      '& li': {
        margin: '20px 0px',
      },
      '& li a': {
        color: '#3A3A3A',
        fontWeight: 400,
        fontSize: 16,
        cursor: 'pointer',
        transition: '0.8s',
        '&:hover': {
          color: '#AAA',
        },
      },
    },
    titleHome: {
      fontWeight: 600,
      color: '#3A3A3A',
    },
    paragraphs: {
      marginTop: 20,
      fontWeight: 400,
      color: '#3A3A3A',
    },
    titleSep: {
      marginTop: 30,
    },
    selectedSection: {
      fontWeight: '600 !important',
    },
    scrolledItem: {
      scrollMarginTop: '95px',
    },
    dscContentDetails: {
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    imgSize: {
      width: '100%',
      height: 'auto',
      maxWidth: 300,
      boxShadow: theme.shadows[2],
      borderRadius: 15,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
        marginTop: 10,
      },
    },
    carousel: {
      marginBottom: 40,
      width: '100%',
    },
  }),
  { index: 1 },
);

export default useStyles;
