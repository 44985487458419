import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    sepList: {
      marginBottom: 30,
    },
    sepParagraph: {
      marginBottom: 30,
    },
    imgSize: {
      marginBottom: 25,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
        marginTop: 20,
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
