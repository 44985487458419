import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { API } from 'config';

const PayPalButton =
  window.paypal === undefined
    ? null
    : window.paypal.Buttons.driver('react', { React, ReactDOM });

const Paypal = ({ amount, savePayment }) => {
  const [canRenovate, setCanRenovate] = useState(0);

  const createOrder = (data, actions) => {
    return actions.order.create({
      // eslint-disable-next-line camelcase
      purchase_units: [
        {
          amount: {
            value: amount,
          },
        },
      ],
    });
  };

  const getRenovate = async () => {
    const idTmp = localStorage.getItem('membership').split('|');

    try {
      const res = await API.validatePayment(
        localStorage.getItem('sessionid'),
        idTmp[0],
      );
      setCanRenovate(res.isValid ? 1 : 0);
    } catch (error) {
      const { status } = error;
      switch (status) {
        case 402:
          // eslint-disable-next-line no-console
          console.log('Pago no pudo ser procesado');
          break;
        default:
          // eslint-disable-next-line no-console
          console.log('Error interno');
          break;
      }
    }
  };

  useEffect(() => {
    getRenovate();
  }, []);

  const onSubmit = (data, actions) => {
    if (canRenovate === 0) {
      return actions.reject();
    }
  };

  return (
    <>
      {window.paypal ? (
        <PayPalButton
          onClick={(data, actions) => onSubmit(data, actions)}
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => savePayment(data, actions)}
        />
      ) : (
        <p>Pagos por paypal no disponibles</p>
      )}
    </>
  );
};

export default Paypal;
