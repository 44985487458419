import React from 'react';
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Breadcrumbs,
  Hidden,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useHeaderStyledStyles } from '../styles';

const HeaderStyled = ({
  arrowBackEvent = () => {}, // Evento al pulsar flecha
  title, // Titulo de la ruta
  titleStyle = '', // Estilo del titulo
  subtitle = '', // Subtitulo de la ruta
  subtitleStyle = '', // Estilo del subtitlo
  ButtonComponent = () => {}, // Componente del boton para la cabecera
  showArrowBack = true, // Booleano para mostrar o no la flechita weon
  ...props
}) => {
  const styles = useHeaderStyledStyles();

  return (
    <Box className={`${styles.headerBox}`} {...props}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {showArrowBack ? (
            <IconButton
              aria-label="back"
              onClick={() => arrowBackEvent()}
              className={`${styles.buttonBack}`}>
              <ArrowBackIosNewIcon />
            </IconButton>
          ) : null}

          <Hidden mdUp implementation="css">
            <Breadcrumbs
              separator="-"
              aria-label="breadcrumb"
              classes={{
                separator: styles.separator,
              }}>
              {subtitle !== '' ? (
                <Typography
                  variant="h3"
                  component="h1"
                  className={`${subtitleStyle}`}>
                  {subtitle}
                </Typography>
              ) : (
                <Typography
                  variant="h3"
                  component="h1"
                  className={`${titleStyle}`}>
                  {title}
                </Typography>
              )}
            </Breadcrumbs>
          </Hidden>
          <Hidden mdDown implementation="css">
            <Breadcrumbs
              separator="-"
              aria-label="breadcrumb"
              classes={{
                separator: styles.separator,
              }}>
              <Typography
                variant="h3"
                component="h1"
                className={`${titleStyle}`}>
                {title}
              </Typography>
              {subtitle !== '' && (
                <Typography
                  variant="h3"
                  component="h1"
                  className={`${subtitleStyle}`}>
                  {subtitle}
                </Typography>
              )}
            </Breadcrumbs>
          </Hidden>
        </Box>
        {ButtonComponent !== null && ButtonComponent()}
      </Box>
      <Divider className={`${styles.dividerStyle}`} />
    </Box>
  );
};

export default HeaderStyled;
