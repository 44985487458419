import { makeStyles } from '@mui/styles';
import { styles } from './Styles';

const useStyles = makeStyles(
  () => ({
    msgLogin: {
      fontSize: 18,
      marginBottom: 30,
      textAlign: 'center',
      color: '#454545',
      '@media only screen and (max-width : 500px)': {
        margin: '8px 0px',
      },
      '@media only screen and (max-width : 400px)': {
        fontSize: '16px',
      },
    },
    marginButton: {
      marginTop: 16,
    },
    linkForgot: {
      textDecoration: 'underline',
      color: '#000',
      '&:hover': {
        color: '-webkit-link',
      },
    },
    ...styles,
  }),
  { index: 1 },
);

export default useStyles;
