import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    sectionBox: {
      border: `2px solid ${theme.palette.forms.borderSectionRecipe}`,
      borderRadius: 5,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '20px 0px',
      marginTop: 40,
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
    },
    titleSection: {
      marginTop: -40,
      background: `${theme.palette.background.default} !important`,
      padding: '0px 20px',
      color: theme.palette.forms.titleRecipeSection,
      marginBottom: 20,
    },
    otherMedicineBox: {
      margin: '15px 0px',
    },
    quantityInput: {
      textAlign: 'center',
    },
    backgroundOtherInput: {
      background: theme.palette.forms.recipeInputBg,
    },
    separateItems: {
      padding: '0px 10px',
    },
    medicineName: {
      color: theme.palette.forms.recipeMedicineText,
    },
    medicineBox: {
      margin: '15px 0px',
    },
    iconMedicine: {
      color: theme.palette.forms.iconMedicineColor,
      marginRight: 10,
    },
    iconActions: {
      border: `1px solid ${theme.palette.forms.titleRecipe}`,
      margin: '0px 5px',
      [theme.breakpoints.down('sm')]: {
        width: 30,
        height: 30,
      },
    },
    backgroundQuantity: {
      backgroundColor: theme.palette.forms.quantityMedicineBox,
      width: '100%',
      padding: '5px',
      textAlign: 'center',
      boxShadow: theme.shadows[2],
    },
    buttonActions: {
      margin: '0px 5px',
    },
    medicineMainBox: {
      maxHeight: 400,
      overflowY: 'auto',
    },
    titleName: {
      color: theme.palette.forms.titleRecipe,
    },
    iconButton: {
      color: theme.palette.forms.titleRecipe,
    },
  }),
  { index: 1 },
);

export default useStyles;
