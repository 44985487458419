import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    mainBox: {
      padding: 25,
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
    },
    mainBoxNonRegister: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 150,
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    boxTable: {
      borderRadius: 12,
      border: `2px solid ${theme.palette.registerMode.borderBoxTable}`,
      '& > .MuiTableContainer-root': {
        borderRadius: '10px 10px 0px 0px',
      },
      '& > .MuiTablePagination-root': {
        borderRadius: '0px 0px 10px 10px',
      },
    },
    rowBox: {
      maxWidth: '20vw',
      wordBreak: 'break-word',
      color: theme.palette.registerMode.headerTextTable,
      borderWidth: 0,
      [theme.breakpoints.down('md')]: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    headerTextTable: {
      fontWeight: 600,
      color: theme.palette.registerMode.headerTextTable,
      fontSize: 16,
      borderWidth: 0,
    },
    headerBox: {
      backgroundColor: theme.palette.registerMode.headerBoxTable,
    },
    footerBox: {
      backgroundColor: theme.palette.registerMode.footerBgTable,
      color: theme.palette.registerMode.headerTextTable,
    },
    detailBox: {
      background: theme.palette.registerMode.dateDetailBg,
      padding: 15,
    },
    iconStyle: {
      color: '#51136C',
      fontSize: 20,
      marginRight: 5,
    },
    dateStyle: {
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        marginTop: 12,
        textAlign: 'left',
      },
    },
    labelSeparator: {
      marginTop: 12,
    },
    dscText: {
      marginLeft: 28,
    },
    buttonBoxDetail: {
      display: 'flex',
      marginTop: 15,
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
    },
    buttonBox: {
      minWidth: 200,
      maxWidth: 200,
      [theme.breakpoints.down('md')]: {
        marginTop: 15,
        minWidth: 100,
        maxWidth: 100,
      },
    },
    buttonModalBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    buttonModal: {
      margin: '0px 10px',
    },
    modalBox: {
      backgroundColor: theme.palette.registerMode.modalBoxBg,
      color: theme.palette.registerMode.titleText,
    },
    detailBoxS: {
      borderWidth: 0,
    },
    cancelBtn: {
      color: '#000',
    },
    activeOrder: {
      color: `${theme.palette.config.headerTextTable} !important`,
      opacity: 0.7,
    },
  }),
  { index: 1 },
);

export default useStyles;
