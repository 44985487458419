import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Drawer,
  Typography,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { sidebarItems } from 'utils';
import { logout, getUserData } from 'config';
import { useSidebarStyles } from 'styles';
import { LOGO_TRAUMAPP } from 'images';
import { ImgLoader } from 'components';

const Sidebar = ({ window }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useSidebarStyles();
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [userData, setUserdata] = useState({
    email: '',
    username: '',
    membership: '',
  });
  const { username, email, membership } = userData;
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getLocalUserData = async () => {
    const user = await getUserData();

    setUserdata(prevState => ({
      ...prevState,
      username: `${user.person.firstname} ${user.person.firstlastname}`,
      email: user.usr,
      membership: user.membership.dsc,
    }));
  };

  useEffect(() => {
    getLocalUserData();
  }, []);

  useEffect(() => {
    getLocalUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('user')]);

  const ContentItem = ({
    icon,
    name,
    path,
    divider = false,
    clickFunction = null,
    styles = '',
  }) => (
    <ListItem
      button
      selected={location.pathname === path}
      onClick={clickFunction}
      className={`${classes.navButton} ${styles}`}>
      <ImgLoader
        src={icon}
        height="50px"
        heighttag="50"
        widthtag="50"
        classes={classes.itemImage}
        alt={name}
        title={name}
        id={name}
      />
      <ListItemText
        primary={name}
        primaryTypographyProps={{ variant: 'sidebar' }}
      />
      {divider && <div className={classes.separatorItem} />}
    </ListItem>
  );

  const Items = ({ float = false }) => (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={1}
        color="#FFF">
        <Link to="/dashboard">
          <Box display="flex" justifyContent="center">
            <ImgLoader
              src={LOGO_TRAUMAPP}
              height="100px"
              heighttag="100"
              widthtag="100"
              classes={classes.traumAppLogo}
              alt="TraumApp Logo"
              title="TraumApp Logo"
              id="traumappFooterLogo"
            />
          </Box>
        </Link>

        <Typography component="h1" variant="h6" className={classes.titleLogo}>
          TraumApp
        </Typography>
        <Typography
          component="p"
          variant="p"
          textAlign="center"
          className={classes.userName}>
          {username}
        </Typography>
        {float && (
          <Typography component="p" variant="p" textAlign="center">
            {email}
          </Typography>
        )}
      </Box>

      <List component="nav" className={classes.navBox}>
        {sidebarItems.map((data, index) => {
          if (data.name === 'Planes') {
            return (
              <div key={`${index.toString()}+${data.path}`}>
                <Link to={data.path}>
                  {float ? (
                    <ListItem
                      button
                      id="a"
                      selected={location.pathname === data.path}
                      className={`${classes.navButton} ${
                        classes.plansDirection
                      } ${membership === 'Free' ? classes.plansItem : ''}`}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center">
                        <ImgLoader
                          src={data.icon}
                          height="50px"
                          heighttag="50"
                          widthtag="50"
                          classes={classes.itemImage}
                          alt={data.name}
                          title={data.name}
                          id={data.name}
                        />
                        <ListItemText
                          className={classes.itemMobile}
                          primary={data.name}
                        />
                      </Box>
                      <Typography
                        component="p"
                        variant="p"
                        fontSize="14px !important">
                        Contrata un plan pago y accede a todas las
                        funcionalidades de TraumApp
                      </Typography>
                    </ListItem>
                  ) : (
                    <ContentItem
                      icon={data.icon}
                      name={data.name}
                      path={data.path}
                      divider={membership !== 'Free'}
                      styles={membership === 'Free' ? classes.plansItem : ''}
                    />
                  )}
                </Link>
              </div>
            );
          }

          if (data.name === 'Cerrar sesión') {
            return (
              <div key={`${index.toString()}+${data.path}`}>
                <Box onClick={() => logout(navigate)}>
                  <ContentItem
                    icon={data.icon}
                    name={data.name}
                    path={data.path}
                    divider={!float}
                  />
                </Box>
              </div>
            );
          }

          return (
            <div key={`${index.toString()}+${data.path}`}>
              <Link to={data.path}>
                <ContentItem
                  icon={data.icon}
                  name={data.name}
                  path={data.path}
                  divider={!float}
                  clickFunction={() => {
                    if (data.name === 'Cerrar sesión') {
                      logout(navigate);
                    }
                  }}
                />
              </Link>
            </div>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box id="sidebar">
      <Hidden mdUp implementation="css">
        <IconButton
          id="aa"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            root: classes.backgroundDrawerMobile,
          }}
          ModalProps={{
            keepMounted: true,
          }}>
          <Items float />
        </Drawer>
      </Hidden>
      <Hidden
        className={classes.backgroundDrawer}
        classes={{
          root: classes.backgroundDrawer,
        }}
        mdDown
        implementation="css">
        <Drawer variant="permanent" open>
          <Items />
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default Sidebar;
