const documentationItems = [
  {
    name: 'Introducción',
    path: '/documentation',
    items: [],
  },
  {
    name: 'Primeros Pasos',
    path: '/first-step',
    items: [],
  },
  {
    name: 'Pacientes',
    path: '/patients',
    open: 'patients',
    items: [
      {
        name: 'Busqueda de datos',
        path: '/patients/search',
      },
      {
        name: 'Ingresar nuevo paciente',
        path: '/patients/register',
      },
      {
        name: 'Eliminar pacientes',
        path: '/patients/delete',
      },
    ],
  },
  {
    name: 'Informes',
    path: '/forms',
    open: 'forms',
    items: [
      {
        name: 'Crear informes',
        path: '/forms/create',
      },
      {
        name: 'Exportación',
        path: '/forms/export',
      },
      {
        name: 'Eliminar informes',
        path: '/forms/delete',
      },
    ],
  },
  {
    name: 'Agenda',
    path: '/calendarDoc',
    open: 'calendar',
    items: [
      {
        name: 'Agendar citas',
        path: '/calendarDoc/new',
      },
      {
        name: 'Lista de espera',
        path: '/calendarDoc/list',
      },
    ],
  },
  {
    name: 'Modo Registro',
    path: '/register-mode',
    items: [],
  },
  {
    name: 'Exportación de datos',
    path: '/export-data',
    items: [],
  },
  {
    name: 'Notificaciones',
    path: '/notifications',
    items: [],
  },
  {
    name: 'Medicamentos y seguros médicos',
    path: '/medicines-secures',
    items: [],
  },
];

export default documentationItems;
