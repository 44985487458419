import {
  LOGOUT_IC,
  CALENDAR_SIDEBAR_IC,
  HOME_IC,
  CONFIG_IC,
  WAITLIST_IC,
  PLANS_IC,
  PATIENT_SIDEBAR_IC,
} from '../images';

export const sidebarItems = [
  {
    path: '/#prices',
    name: 'Planes',
    icon: PLANS_IC,
  },
  {
    path: '/home',
    name: 'Inicio',
    icon: HOME_IC,
  },
  {
    path: '/patient',
    name: 'Paciente',
    icon: PATIENT_SIDEBAR_IC,
  },
  {
    path: '/calendar',
    name: 'Agenda',
    icon: CALENDAR_SIDEBAR_IC,
  },
  {
    path: '/waitlist',
    name: 'En espera',
    icon: WAITLIST_IC,
  },
  {
    path: '/config',
    name: 'Configuración',
    icon: CONFIG_IC,
  },
  {
    path: '/dashboard',
    name: 'Cerrar sesión',
    icon: LOGOUT_IC,
  },
];
