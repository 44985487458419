import React, { useState } from 'react';

// Material-ui
import LinearProgress from '@mui/material/LinearProgress';
import { useImgLoaderStyles } from 'styles';

const ImgLoader = ({
  src,
  height,
  heighttag,
  widthtag,
  classes,
  alt,
  title,
  id,
}) => {
  const styles = useImgLoaderStyles();
  const [imgLoading, setImgLoading] = useState(true);

  return (
    <>
      <img
        id={id}
        className={classes}
        style={{ display: imgLoading ? 'none' : 'block' }}
        src={src}
        height={heighttag}
        width={widthtag}
        alt={alt}
        onLoad={() => setImgLoading(false)}
        title={title}
      />
      {imgLoading ? (
        <div style={{ height }} className={styles.loaderBox}>
          <LinearProgress className={styles.loaderBar} />
        </div>
      ) : null}
    </>
  );
};

export default ImgLoader;
