import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  contentBox: {
    maxWidth: 1000,
    width: '90%',
    padding: '0px 20px',
    paddingBottom: 15,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
    },
  },
  inputTextColor: {
    color: theme.palette.calendar.modalTextColor,
    wordBreak: 'initial',
  },
  inputTextColorForm: {
    color: '#FFF',
    wordBreak: 'initial',
  },
  inputBoxColor: {
    backgroundColor: theme.palette.calendar.floatInputBg,
    marginTop: 30,
    borderRadius: 8,
    boxShadow: theme.shadows[2],
    color: '#FFF',
    '& label': {
      color: '#FFF',
    },
    '& div input::placeholder, & div textarea::placeholder': {
      color: '#FFF',
    },
  },
  dateBox: {
    padding: '15px 25px',
    display: 'flex',
    flexDirection: 'column',
  },
  labelDate: {
    color: '#FFF',
    marginBottom: 15,
  },
  inputDate: {
    width: 250,
    alignSelf: 'flex-end',
    borderColor: '#FFF',
    color: '#FFF',
    '& div input::placeholder': {
      color: '#FFF',
    },
    '& svg': {
      color: '#FFF',
    },
  },
  buttonSave: {
    marginLeft: 30,
  },
  searchIcon: {
    color: '#FFF',
  },
  filtersBox: {
    marginRight: 20,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
      margin: '10px 0px',
    },
  },
  boxTable: {
    borderRadius: 12,
    border: `2px solid ${theme.palette.calendar.borderBoxTable}`,
    '& > .MuiTableContainer-root': {
      borderRadius: '10px 10px 0px 0px',
    },
    '& > .MuiTablePagination-root': {
      borderRadius: '0px 0px 10px 10px',
    },
  },
  rowBox: {
    maxWidth: '20vw',
    wordBreak: 'break-word',
    color: theme.palette.calendar.headerTextTable,
    borderWidth: 0,
  },
  headerTextTable: {
    fontWeight: 600,
    color: theme.palette.calendar.headerTextTable,
    fontSize: 20,
    borderWidth: 0,
  },
  headerBox: {
    backgroundColor: theme.palette.calendar.headerBoxTable,
  },
  dotDates: {
    width: 20,
    height: 20,
    borderRadius: 9999,
    margin: 'auto',
  },
  haveDates: {
    backgroundColor: '#17DD43',
  },
  anyDates: {
    backgroundColor: '#FD0909',
  },
  filterBox: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  modalBox: {
    backgroundColor: theme.palette.calendar.modalBoxBackground,
    color: theme.palette.calendar.modalTextColor,
  },
  selectBorder: {
    border: `1px solid ${theme.palette.calendar.headerTextTable}`,
  },
  iconSelect: {
    color: theme.palette.calendar.modalTextColor,
  },
  paginatorDetail: {
    color: theme.palette.calendar.modalTextColor,
  },
  checkboxColor: {
    color: theme.palette.calendar.modalTextColor,
  },
  checkboxDisabled: {
    color: `${theme.palette.calendar.checkboxDisabled} !important`,
  },
  activeOrder: {
    color: `${theme.palette.calendar.headerTextTable} !important`,
    opacity: 0.7,
  },
}));

export default useStyles;
