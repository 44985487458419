import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';

import Loading from '../Loading';
import Sidebar from '../Sidebar';
import Copyright from '../Copyright';

const LayoutAuth = ({
  children,
  isLoading,
  className,
  showSidebar = true,
  ...props
}) => (
  <main
    style={{
      position: 'relative',
      maxWidth: '100%',
      overflow: 'hidden',
      minHeight: '100vh',
    }}>
    <CssBaseline />
    {showSidebar ? <Sidebar /> : null}
    <section className={className} {...props}>
      {children}
    </section>
    <Box
      sx={{
        paddingLeft: { md: showSidebar ? '150px' : 0, xs: 0 },
        position: 'absolute',
        bottom: 5,
        width: '100%',
      }}>
      <Copyright />
    </Box>

    {isLoading && <Loading />}
  </main>
);

export default LayoutAuth;
