import { makeStyles } from '@mui/styles';
import { styles } from './Styles';

const useStyles = makeStyles(
  () => ({
    msgLogin: {
      color: '#454545',
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 15,
      marginTop: 15,
    },
    buttonSep: {
      marginTop: 20,
    },
    ...styles,
  }),
  { index: 1 },
);

export default useStyles;
