import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  homeButtonRegister: {
    minWidth: 350,
    maxWidth: 350,
    background: theme.palette.registerMode.buttonMenuBg,
    borderRadius: 4,
    color: '#000',
    paddingTop: 20,
    paddingBottom: 20,
    margin: '15px 0px',
    width: '100%',
    '&:hover': {
      background: theme.palette.registerMode.buttonMenuBg,
      opacity: 0.9,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 200,
      maxWidth: 300,
      margin: '10px 0px',
    },
  },
  traumapplogo: {
    width: 200,
    height: 'auto',
    margin: '15px 0px',
    [theme.breakpoints.down('sm')]: {
      width: 100,
      margin: '10px 0px',
    },
  },
  titleSep: {
    margin: '20px 0px',
    letterSpacing: '0.15em',
    textAlign: 'center',
    color: theme.palette.registerMode.titleText,
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
    },
  },
  messageBox: {
    padding: 20,
  },
  messageText: {
    textAlign: 'center',
  },
  inputBox: {
    background: '#51136C',
    marginTop: 20,
  },
  inputPin: {
    color: '#FFF',
    textAlign: 'center',
    letterSpacing: 10,
  },
  buttonSep: {
    margin: '0px 10px',
    marginTop: 20,
  },
  modalBox: {
    backgroundColor: theme.palette.registerMode.modalBoxBg,
    color: theme.palette.registerMode.titleText,
  },
}));

export default useStyles;
