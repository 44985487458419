import React from 'react';
import { useLazyLoadingStyles } from 'styles';

const LazyLoading = () => {
  const styles = useLazyLoadingStyles();

  return (
    <div className={styles.loadingBox}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default LazyLoading;
