import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Footer, HeaderDoc } from 'components';
import { useBaseDocStyles } from 'styles';

const LayoutDoc = ({ children }) => {
  const baseStyles = useBaseDocStyles();

  return (
    <main>
      <CssBaseline />
      <HeaderDoc />
      <section className={baseStyles.mainBox}>
        {children}
        <Footer />
      </section>
    </main>
  );
};

export default LayoutDoc;
