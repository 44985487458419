import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  saveButton: {
    backgroundColor: '#51136C',
    color: '#FFF',
    borderRadius: 4,
    marginLeft: 20,
    '&:hover': {
      backgroundColor: '#51136C',
      opacity: 0.8,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
      marginLeft: 0,
    },
  },
  formBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: `2px solid ${theme.palette.config.borderBox}`,
    borderRadius: 8,
    padding: '10px 20px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  boxTable: {
    borderRadius: 12,
    border: `2px solid ${theme.palette.config.borderBox}`,
    '& > .MuiTableContainer-root': {
      borderRadius: '10px 10px 0px 0px',
    },
    '& > .MuiTablePagination-root': {
      borderRadius: '0px 0px 10px 10px',
    },
  },
  rowBox: {
    maxWidth: '20vw',
    wordBreak: 'break-word',
    color: theme.palette.config.headerTextTable,
    borderWidth: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: 200,
      maxWidth: 200,
    },
  },
  headerTextTable: {
    fontWeight: 600,
    color: theme.palette.config.headerTextTable,
    fontSize: 16,
    borderWidth: 0,
  },
  headerBox: {
    backgroundColor: theme.palette.config.headerBoxTable,
  },
  footerBox: {
    backgroundColor: theme.palette.config.footerBgTable,
    color: theme.palette.config.headerTextTable,
  },
  buttonTable: {
    marginLeft: 10,
  },
  iconBtn: {
    color: theme.palette.config.headerTextTable,
  },
  editBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogBox: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: '#FFF',
  },
  buttonModalSep: {
    margin: '0px 10px',
    marginTop: '20px',
  },
  inputForm: {
    backgroundColor: theme.palette.config.recipeInputBg,
  },
  activeOrder: {
    color: `${theme.palette.config.headerTextTable} !important`,
    opacity: 0.7,
  },
}));

export default useStyles;
