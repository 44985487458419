import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    loaderBar: {
      backgroundColor: '#FFF',
      width: '50%',
    },
    loaderBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { index: 1 },
);

export default useStyles;
