import { makeStyles } from '@mui/styles';
import { styles } from './Styles';

const useStyles = makeStyles(
  theme => ({
    btnLoginOutlineForm: {
      borderRadius: 10,
      border: '1px solid #013E68',
      padding: 10,
      boxShadow: theme.shadows[2],
      fontFamily: "'Open-sans', sans-serif",
      fontSize: '18px',
      fontWeight: '500',
      textTransform: 'none',
      marginBottom: '2em',
      alignSelf: 'flex-start',
      background: 'transparent',
      '&:hover': {
        border: '1px solid #013E68',
        background: 'transparent',
      },
    },
    code: {
      textAlign: 'center',
      letterSpacing: 15,
    },
    msgValidate: {
      color: '#454545',
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 15,
      marginTop: 15,
    },
    resendEmailBtn: {
      marginTop: 15,
    },
    ...styles,
  }),
  { index: 1 },
);

export default useStyles;
