import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { useCheckboxFormStyles } from '../styles';

const CheckboxForm = ({
  name, // Nombre del campo
  label, // label del campo
  className, // estilos para la caja de el input
  control, // control proveniente de react-hook-form
  validations = {}, // Objeto con las validaciones
  disabled = false,
  defaultValue = false, // No explico
  classes = {}, // Clases de MUI para el input
  ...props
}) => {
  const styles = useCheckboxFormStyles();

  return (
    <Controller
      name={name}
      control={control}
      rules={validations}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <>
          <FormControl
            className={className}
            onChange={({ target: { value } }) =>
              field.onChange(value !== 'true')
            }
            error={fieldState.invalid}
            {...props}>
            <FormControlLabel
              className={styles.label}
              disabled={disabled}
              control={
                <Checkbox checked={field.value} {...field} classes={classes} />
              }
              label={label}
            />
          </FormControl>
          {fieldState.invalid && (
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default CheckboxForm;
