import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    formBox: {
      backgroundColor: theme.palette.config.backgroundBoxDetail,
      borderRadius: 8,
      boxShadow: theme.shadows[2],
      padding: '15px 20px',
      width: '90%',
      maxWidth: 1200,
      margin: 'auto',
      justifyContent: 'space-between',
    },
    itemBox: {
      width: '50%',
      flexBasis: '50%',
      padding: 10,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexBasis: '100%',
      },
    },
    itemBoxFull: {
      width: '100%',
      flexBasis: '100%',
      padding: 10,
    },
    buttonSep: {
      margin: '10px',
    },
    inputBox: {
      backgroundColor: theme.palette.config.recipeInputBg,
    },
    buttonCancel: {
      color: '#000',
    },
  }),
  { index: 1 },
);

export default useStyles;
