import { makeStyles } from '@mui/styles';

const styles = makeStyles(
  theme => ({
    headerBox: {
      borderRadius: 10,
      border: `2px solid ${theme.palette.forms.borderSectionRecipe}`,
      padding: 20,
    },
    headerSubBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 15,
      color: theme.palette.forms.titleRecipe,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    switchLabel: {
      userSelect: 'none',
    },
    switchThumb: {
      color: '#51136C',
    },
    switchThumbFalse: {
      color: '#FF00F5',
    },
    switchTrack: {
      backgroundColor: '#F4DBFF !important',
    },
    formBox: {
      padding: 20,
      marginTop: 15,
      border: `2px solid ${theme.palette.forms.borderSectionRecipe}`,
      borderRadius: 10,
      justifyContent: 'space-between',
    },
    indicatorDetail: {
      backgroundColor: theme.palette.forms.borderSectionRecipe,
    },
    tabBox: {
      margin: '20px 0px',
    },
    btnTab: {
      color: `${theme.palette.forms.tabText} !important`,
    },
    btnTabUnselected: {
      color: theme.palette.forms.tabUnselectedText,
    },
    itemBox: {
      padding: '5px 15px',
    },
    globalLabel: {
      marginBottom: 10,
      color: theme.palette.forms.titleRecipe,
    },
    globalSliderVal: {
      color: theme.palette.forms.titleRecipe,
    },
    globalSelect: {
      marginHorizontal: 12,
      marginBottom: 20,
      backgroundColor: theme.palette.forms.recipeInputBg,
    },
    globalInput: {
      marginHorizontal: 12,
      marginBottom: 20,
      backgroundColor: theme.palette.forms.recipeInputBg,
    },
    titleGlobal: {
      fontSize: 18,
      fontWeight: '600',
      textAlign: 'center',
      marginBottom: 5,
      color: theme.palette.forms.titleRecipe,
    },
    subInputsBox: {
      marginLeft: 15,
      marginBottom: 15,
    },
    multiSliderBox: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    multiSliderLabelBox: {
      alignItems: 'center',
      textAlign: 'center',
    },
    sliderBox: {
      marginBottom: 10,
    },
    label: {
      color: '#333',
    },
    thumbStyle: {
      height: 20,
      width: 20,
    },
    sectionDot: {
      height: 5,
      width: 5,
      backgroundColor: '#FFF',
      borderRadius: 1000,
    },
    sectionTxt: {
      color: '#FFF',
      marginHorizontal: 15,
      fontSize: 17,
    },
    sectionButtonBox: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    trackSlider: {
      color: theme.palette.forms.trackSliderBg,
    },
    thumbSlider: {
      color: '#B459DB',
    },
    titleForm: {
      color: theme.palette.forms.titleRecipe,
    },
    arrowButton: {
      color: theme.palette.forms.titleRecipe,
    },
    checkboxLabel: {
      color: theme.palette.forms.titleRecipe,
    },
    checkboxRoot: {
      color: theme.palette.forms.titleRecipe,
    },
  }),
  { index: 1 },
);

export default styles;
