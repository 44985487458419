import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    titleApp: {
      position: 'absolute',
      color: '#FFF',
      margin: '30px 0px 0px 80px',
      fontSize: '18px',
    },
    iconMembership: {
      margin: '20px',
    },
    paymentBoxScreen: {
      minHeight: '100vh',
      background: 'linear-gradient(50deg, #FFF -15%, #49ABED, #0267AB)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 50,
      '@media only screen and (max-height : 500px)': {
        height: 'auto',
      },
    },
    headerPayment: {
      backgroundColor: '#2D537D',
    },
    floatItem: {
      position: 'absolute',
    },
    dscMemberpshipPayment: {
      fontFamily: "'Montserrat',sans-serif",
      fontSize: '18px',
      color: '#222',
      fontWeight: '500',
      textAlign: 'center',
      margin: '0px 40px',
      marginBottom: '10px',
    },
    membershipTotal: {
      fontFamily: "'Montserrat',sans-serif ",
      fontSize: '20px',
      color: '#222',
      fontWeight: '500',
      margin: '15px 0px',
    },
    cancelProcess: {
      margin: '25px 0px',
      textDecoration: 'none',
      color: '#FFF',
      transition: '1s',
      '&:hover': {
        color: '#111',
      },
    },
    cannotBuy: {
      fontSize: '15px',
      margin: '0px 40px',
      color: '#F00',
      letterSpacing: '1px',
      fontWeight: '600',
      fontFamily: "'Open-sans', sans-serif",
      textAlign: 'center',
      marginBottom: '10px',
    },
    logoTraumappLogin: {
      margin: '10px 0px 10px 15px',
      width: '60px',
      height: '60px',
    },
    selectPayments: {
      width: '200px',
      marginBottom: '30px !important',
      backgroundColor: '#EBE8E8',
    },
    formBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    subtitleForm: {
      fontFamily: "'Open-sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '20px',
      textDecorationLine: 'underline',
      color: '#000000',
      textAlign: 'center',
      '@media only screen and (max-width : 500px)': {
        fontSize: '18px',
      },
    },
    stepText: {
      fontFamily: "'Open-sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      margin: '25px 0px',
      width: '100%',
      '@media only screen and (max-width : 500px)': {
        fontSize: '16px',
        margin: '18px 0px',
      },
    },
    bitcoinBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '50%',
      '@media only screen and (max-width : 950px)': {
        width: '60%',
      },
      '@media only screen and (max-width : 750px)': {
        width: '70%',
      },
      '@media only screen and (max-width : 600px)': {
        width: '85%',
      },
      '@media only screen and (max-width : 400px)': {
        width: '95%',
      },
    },
    walletBox: {
      width: '350px',
      textAlign: 'center',
      '@media only screen and (max-width : 500px)': {
        width: '315px',
      },
    },
    bitcoinButton: {
      backgroundColor: '#51136C',
      borderRadius: '27px',
      width: 'fit-content',
      color: '#FFF',
      textTransform: 'none',
      padding: '10px 40px',
      margin: 'auto',
      fontFamily: "'Open-sans', sans-serif",
      marginTop: '20px',
      '&:hover': {
        backgroundColor: '#51136C',
      },
    },
    bitcoinAdvise: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      textAlign: 'center',
      marginTop: '25px',
    },
    formInputs: {
      marginBottom: '30px',
      backgroundColor: '#EBE8E8',
      borderRadius: '5px',
    },
    labelStyle: {
      fontFamily: "'Montserrat', sans-serif",
    },
    ownDropZone: {
      backgroundColor: 'rgba(219, 219, 219, 0.6)',
      border: '1px dashed #000',
      transition: '0.5s',
      boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '10px',
      padding: '50px',
      cursor: 'pointer',
      textAlign: 'center',
      marginTop: '8px',
      marginBottom: '10px',
      '&:hover': {
        border: '1px dashed #888',
      },
      '@media only screen and (max-width : 500px)': {
        padding: '30px',
      },
    },
    walletCopy: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    copyIcon: {
      '@media only screen and (max-width : 500px)': {
        width: '20px',
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
