import { makeStyles } from '@mui/styles';
import { styles } from './Styles';

const useStyles = makeStyles(
  () => ({
    registerBox: {
      fontFamily: "'Open-sans', sans-serif",
      background: 'linear-gradient(180deg, #6EC1F9 0%, #0273BF 100%)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      marginBottom: 40,
      borderRadius: 27,
      padding: 40,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 800,
      maxWidth: 800,
      zIndex: '1',
      minHeight: 450,
      width: '90%',
      '@media only screen and (max-width : 800px)': {
        marginBottom: 25,
        minWidth: 200,
      },
      '@media only screen and (max-width : 400px)': {
        padding: '40px 15px',
      },
    },
    buttonSep: {
      marginTop: 20,
    },
    mainBoxRegister: {
      width: '100%',
    },
    ...styles,
  }),
  { index: 1 },
);

export default useStyles;
