import React, { useRef, useContext } from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router';
import { Editor } from '@tinymce/tinymce-react';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { useFinalFormStyles } from '../styles';
import {
  API,
  logout,
  globalurl,
  ThemeContext,
  datePDF,
  createFileName,
} from '../config';
import DialogComp from './DialogComp';

const FinalForm = ({
  open,
  setClose,
  setContent,
  content = '',
  onSave = () => {},
  setLoading = () => {},
  afterSave = () => {},
  historialForm = '',
  patientName = '',
  kindForm = '',
}) => {
  // otros
  const navigate = useNavigate();
  const styles = useFinalFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const printContent = useRef(null);
  const { mode } = useContext(ThemeContext);

  // Funciones
  const printPdf = (kind, id) => {
    let finalurl = '';
    if (kind === 'recipe') {
      finalurl = `${globalurl}/~api/recipes/preview?sessionid=${localStorage.getItem(
        'sessionid',
      )}&id=${id}`;
    } else if (kind === 'historia') {
      finalurl = `${globalurl}/~api/historial/preview?sessionid=${localStorage.getItem(
        'sessionid',
      )}&id=${id}`;
    }

    window.open(finalurl, '_blank');
  };

  const downloadPdf = async (kind, id) => {
    try {
      setLoading(true);

      let finalurl = '';
      let filename = '';

      if (kind === 'recipe') {
        finalurl = `${globalurl}/~api/recipes/generatePdf?sessionid=${localStorage.getItem(
          'sessionid',
        )}&id=${id}`;
        filename = `recipe_${id}_${datePDF()}`;
      } else if (kind === 'historia') {
        finalurl = `${globalurl}/~api/historial/generatePdf?sessionid=${localStorage.getItem(
          'sessionid',
        )}&id=${id}`;
        filename = createFileName(id, patientName, historialForm, datePDF());
      }

      await axios({
        url: finalurl,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        if (response.status !== 200) {
          switch (response.status) {
            case 401:
              enqueueSnackbar('Sesión inválida o expirada', {
                variant: 'error',
              });
              logout(navigate);
              break;
            case 204:
              enqueueSnackbar('Registro no encontrado', {
                variant: 'error',
              });
              break;
            case 500:
              enqueueSnackbar('Error interno', {
                variant: 'error',
              });
              break;
            default:
              enqueueSnackbar('Error interno', {
                variant: 'error',
              });
              break;
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}.pdf`);
          document.body.appendChild(link);
          link.click();
          setLoading(false);
          document.body.removeChild(link);

          enqueueSnackbar('PDF descargado', {
            variant: 'success',
          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      const { status, data: res } = error;
      switch (status) {
        case 401:
          enqueueSnackbar('Sesión inválida o expirada', {
            variant: 'error',
          });
          logout(navigate);
          break;
        case 204:
          enqueueSnackbar('Registro no encontrado', {
            variant: 'error',
          });
          break;
        case 500:
          enqueueSnackbar('Error interno', {
            variant: 'error',
          });
          break;
        default:
          enqueueSnackbar(res.msg, {
            variant: 'error',
          });
          break;
      }
      setLoading(false);
    }
  };

  const validateGeneratePdf = async (action, id) => {
    try {
      setLoading(true);
      const params = {
        sessionid: localStorage.getItem('sessionid'),
      };

      await API.generatePdf(params);

      setLoading(false);

      if (action === 'd') {
        downloadPdf(kindForm, id);
      } else if (action === 'p') {
        printPdf(kindForm, id);
      }
    } catch (error) {
      const { status, data: res } = error;
      switch (status) {
        case 410:
          enqueueSnackbar('Limite de documentos excedido', {
            variant: 'error',
          });
          break;
        case 401:
          enqueueSnackbar('Sesión inválida o expirada', {
            variant: 'error',
          });
          logout(navigate);
          break;
        case 500:
          enqueueSnackbar('Error interno', {
            variant: 'error',
          });
          break;
        default:
          enqueueSnackbar(res.msg, {
            variant: 'error',
          });
          break;
      }

      navigate('/patient');
      setLoading(false);
    }
  };

  return (
    <DialogComp
      open={open}
      handleClose={() => setClose()}
      showCloseButton
      classNameBox={`${styles.modalBox}`}
      closeButtonColor={mode === 'dark' ? '#FFF' : '#000'}
      maxWidth="lg">
      <Box className={styles.mainBox}>
        <Editor
          apiKey="ddtyx5xwc2nnmmy6aungl402rqamezb7kp22htm9kfofns3i"
          initialValue={content}
          onEditorChange={val => setContent(val)}
          init={{
            menubar: false,
            toolbar: 'bold italic underline',
            branding: false,
            resize: false,
            statusbar: false,
            height: '60vh',
            // eslint-disable-next-line camelcase
            max_height: 600,
            placeholder: 'Ingrese...',
          }}
        />

        <div style={{ display: 'none' }}>
          <div
            ref={printContent} // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>

        <Box
          display="flex"
          alignItems="center"
          mt={2}
          justifyContent="flex-end">
          <Button
            variant="config"
            className={styles.buttonSep}
            onClick={async () => {
              await onSave();
              await afterSave();
            }}>
            Guardar
          </Button>

          <Button
            variant="config"
            onClick={async () => {
              const id = await onSave();
              await validateGeneratePdf('d', id);
              await afterSave();
            }}
            className={styles.buttonSep}>
            Generar PDF
          </Button>

          <Button
            variant="config"
            className={styles.buttonSep}
            onClick={async () => {
              const id = await onSave();
              await validateGeneratePdf('p', id);
              await afterSave();
            }}>
            Imprimir
          </Button>
        </Box>
      </Box>
    </DialogComp>
  );
};

export default FinalForm;
