import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Footer, Loading } from 'components';

const Layout = ({ children, isLoading, ...props }) => {
  return (
    <main {...props}>
      <CssBaseline />
      {children}
      <Footer />
      {isLoading ? <Loading /> : null}
    </main>
  );
};

export default Layout;
