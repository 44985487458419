import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    rootList: {
      maxWidth: 300,
      width: 250,
      '@media only screen and (max-width: 500px)': {
        width: 200,
      },
    },
    headerBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: 12,
      alignItems: 'center',
      backgroundColor: '#2C2C2C',
      color: '#FFF',
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100%',
      zIndex: '99',
    },
    logoTraumapp: {
      width: 40,
      height: 'auto',
    },
    titleTraumapp: {
      marginLeft: 15,
      fontWeight: 500,
    },
    versionText: {
      fontWeight: 400,
      marginLeft: 10,
    },
    drawer: {
      backgroundColor: '#49ABED',
      [theme.breakpoints.up('sm')]: {
        width: 240,
        flexShrink: 0,
      },
    },
    backgroundDrawer: {
      '& .MuiPaper-root': {
        backgroundColor: '#49ABED',
        top: 0,
        [theme.breakpoints.up('sm')]: {
          top: '64px',
        },
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      padding: '0px 12px',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    listItem: {
      '& span': {
        color: '#000',
        transition: '1s',
        fontWeight: 500,
      },
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
    arrowColor: {
      color: '#2A4D75',
    },
    selectedItem: {
      '& span': {
        color: '#3C3C3C',
        textDecoration: 'underline',
      },
    },
    headerLink: {
      display: 'flex',
      alignItems: 'center',
      color: '#FFF',
      transition: '0.8s',
      '&:hover': {
        color: '#BBB',
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
