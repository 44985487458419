import React from 'react';
import { Typography } from '@mui/material';

import { useCopyrightStyles } from '../styles';

const Copyright = ({ className, ...props }) => {
  const styles = useCopyrightStyles();

  return (
    <Typography
      component="p"
      variant="copyright"
      className={`${className} ${styles.text}`}
      {...props}>
      Todos los derechos reservados © 2022 TraumApp
    </Typography>
  );
};

export default Copyright;
