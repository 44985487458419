import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    buttonTab: {
      backgroundColor: theme.palette.patients.buttonTabPatientDetail,
      borderRadius: 20,
      color: '#000',
      minWidth: 90,
      maxWidth: 250,
      width: '15vw',
      margin: '15px 20px',
      fontSize: 16,
      boxShadow: theme.shadows[3],
      '&:hover': {
        backgroundColor: theme.palette.patients.buttonTabPatientDetail,
        opacity: 0.9,
      },
      [theme.breakpoints.down('sm')]: {
        margin: '10px 8px',
        fontSize: 14,
        padding: '8px 12px',
      },
    },
    selectedButton: {
      backgroundColor: `${theme.palette.patients.buttonTabSelectedPatientDetail} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.patients.buttonTabSelectedPatientDetail} !important`,
        opacity: 0.9,
      },
    },
    boxTable: {
      borderRadius: 12,
      border: `2px solid ${theme.palette.patients.borderBoxTable}`,
      '& > .MuiTableContainer-root': {
        borderRadius: '10px 10px 0px 0px',
      },
      '& > .MuiTablePagination-root': {
        borderRadius: '0px 0px 10px 10px',
      },
    },
    rowBox: {
      maxWidth: '20vw',
      wordBreak: 'break-word',
      color: theme.palette.patients.headerTextTable,
      borderWidth: 0,
      [theme.breakpoints.down('md')]: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    headerTextTable: {
      fontWeight: 600,
      color: theme.palette.patients.headerTextTable,
      fontSize: 16,
      borderWidth: 0,
    },
    headerBox: {
      backgroundColor: theme.palette.patients.headerBoxTable,
    },
    footerBox: {
      backgroundColor: theme.palette.patients.footerBgTable,
      color: theme.palette.patients.headerTextTable,
    },
    inputSearchBox: {
      background: theme.palette.patients.searchInputDetailBg,
    },
    mainBoxDetails: {
      padding: 15,
      backgroundColor: theme.palette.patients.patientDetailBoxBg,
      borderRadius: 11,
    },
    formBox: {
      width: '95%',
      maxWidth: 1200,
      margin: 'auto',
      justifyContent: 'space-between',
    },
    itemBox: {
      width: '50%',
      flexBasis: '50%',
      padding: 10,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexBasis: '100%',
      },
    },
    itemBoxFull: {
      width: '100%',
      flexBasis: '100%',
      padding: 10,
    },
    inputPatientData: {
      backgroundColor: theme.palette.patients.inputBackground,
    },
    titleNamePatient: {
      fontWeight: 400,
      textAlign: 'center',
      color: theme.palette.patients.patientTitleText,
    },
    buttonTable: {
      marginLeft: 10,
    },
    modalBox: {
      color: '#FFF',
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    modalButton: {
      margin: '0px 10px',
    },
    iconTable: {
      color: theme.palette.patients.viewIconColor,
    },
  }),
  { index: 1 },
);

export default useStyles;
