import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    headerDashboard: {
      backgroundColor: '#2E8FD0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '5px 20px',
      '@media only screen and (max-width : 900px)': {
        padding: '5px 10px',
      },
    },
    dashboardHeaderIc: {
      height: '50px',
      width: '50px',
      cursor: 'pointer',
      '@media only screen and (max-width : 900px)': {
        height: '35px',
        width: '35px',
      },
    },
    mainBoxDashboard: {
      backgroundColor: '#2D537D',
      padding: '30px 50px',
      minHeight: '100vh',
      '@media only screen and (max-width : 500px)': {
        padding: '15px 25px',
      },
    },
    userNameTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '26px',
      textAlign: 'center',
      color: '#FFF',
      marginBottom: '20px',
      '@media only screen and (max-width : 900px)': {
        marginBottom: '10px',
      },
    },
    backgroundSectionUser: {
      backgroundColor: '#F6F6F6',
      padding: '20px 15px',
      borderRadius: '30px',
      margin: '15px 0px',
      fontFamily: "'Open-sans', sans-serif",
      '@media only screen and (max-width : 950px)': {
        padding: '15px 10px',
      },
      '@media only screen and (max-width : 900px)': {
        padding: '25px 20px',
        margin: '10px 0px',
      },
      '@media only screen and (max-width : 500px)': {
        padding: '15px 10px',
        margin: '8px 0px',
      },
    },
    infoCol: {
      display: 'flex',
      width: '48%',
      flexDirection: 'column',
      '@media only screen and (max-width : 950px)': {
        width: '49%',
      },
      '@media only screen and (max-width : 900px)': {
        width: '95%',
      },
    },
    userFormDetails: {
      height: '100%',
    },
    paymentBox: {
      backgroundColor: '#F6F6F6',
      padding: '25px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '60px',
      '@media only screen and (max-width : 450px)': {
        padding: '15px',
        borderRadius: '45px',
      },
    },
    paymentDetailBox: {
      backgroundColor: '#51136C',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '30px',
      padding: '15px 25px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '20px 0px',
      color: '#FFF',
      '@media only screen and (max-width : 900px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    titleCardEmail: {
      fontSize: '20px',
      marginBottom: '10px',
      '@media only screen and (max-width : 400px)': {
        marginBottom: '5px',
      },
    },
    emailUser: {
      fontSize: '16px',
      marginLeft: '15px',
      '@media only screen and (max-width : 400px)': {
        marginLeft: '0px',
      },
    },
    membershipData: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '@media only screen and (max-width : 400px)': {
        flexDirection: 'column',
      },
    },
    changeMembershipBtn: {
      border: '1px solid #51136C !important',
      borderRadius: '12px !important',
      padding: '15px 5px !important',
      outline: 'none !important',
      textAlign: 'center !important',
      textTransform: 'none !important',
      textDecoration: 'none !important',
      fontFamily: "'Open-sans', sans-serif !important",
      '&:active': {
        color: '#000',
      },
      '@media only screen and (max-width : 500px)': {
        padding: '5px',
      },
      '@media only screen and (max-width : 400px)': {
        padding: '8px',
        marginTop: '15px',
      },
    },
    inputLabelRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '12px',
      alignItems: 'flex-end',
      '@media only screen and (max-width : 450px)': {
        flexDirection: 'column',
        marginBottom: '0px',
      },
    },
    inputLabelColUser: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '0px 15px',
      '& label': {
        marginBottom: '3px',
      },
      '@media only screen and (max-width : 450px)': {
        margin: '0px',
        marginBottom: '12px',
      },
    },
    inputUserData: {
      background: '#D7D7D7',
      borderStyle: 'none',
      border: '0.5px solid #888',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
      width: '100%',
      height: '40px',
      outline: 'none',
      textIndent: '5px',
      '@media only screen and (max-width : 400px)': {
        width: 'auto',
      },
    },
    paymentDataCol: {
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        margin: '4px 0px',
      },
    },
    downloadIc: {
      width: 50,
      cursor: 'pointer',
      '& img': {
        width: '100%',
      },
      '@media only screen and (max-width : 900px)': {
        marginTop: '-30px',
        alignSelf: 'flex-end',
      },
      '@media only screen and (max-width : 400px)': {
        marginTop: '10px',
        alignSelf: 'center',
      },
    },
    paymentMembershipTitle: {
      fontSize: '20px',
    },
    subtitleInfoUser: {
      color: '#3e3e3e',
    },
    resumeFloatBox: {
      height: '100%',
      maxHeight: '300px',
    },
    tableBox: {
      width: '100%',
      overflowY: 'auto',
      height: '100%',
      maxHeight: '90%',
      '@media only screen and (max-width : 900px)': {
        maxHeight: '200px',
      },
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '&::-webkit-scrollbar-track': {
        border: '1px solid #999',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#999',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#777',
      },
    },
    tableRowResume: {
      display: 'flex',
      flexDirection: 'row',
    },
    oddRow: {
      backgroundColor: '#DDD',
    },
    anyPayments: {
      textAlign: 'center',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: '0px',
    },
    tableHeaderResume: {
      minWidth: '350px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: '#6f6f6f',
      fontSize: '18px',
    },
    tableBodyResume: {
      minWidth: '350px',
      height: '85%',
      fontSize: '14px',
    },
    firstCol: {
      width: '50%',
      '@media only screen and (max-width : 950px)': {
        width: '45%',
      },
    },
    otherCol: {
      width: '25%',
      textAlign: 'center',
      '@media only screen and (max-width : 950px)': {
        width: '30%',
      },
    },
    globalCol: {
      padding: '10px 5px',
      borderBottom: '1px solid #aaa',
      borderLeft: '1px solid #AAA',
      '@media only screen and (max-width : 500px)': {
        padding: '5px',
      },
    },
    infoUserBox: {
      background: 'linear-gradient(180deg, #A24EC7 0%, #378AC1 74.79%)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '60px',
      padding: '25px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '25px',
      '@media only screen and (max-width : 950px)': {
        padding: '15px',
      },
      '@media only screen and (max-width : 900px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
      '@media only screen and (max-width : 350px)': {
        padding: '8px',
        marginBottom: '15px',
      },
    },
    msgExpirationDate: {
      '@media only screen and (max-width : 400px)': {
        marginTop: '10px',
      },
    },
    linkText: {
      textDecoration: 'none !important',
      fontFamily: "'Open-sans', sans-serif !important",
    },
    buttonConsulting: {
      position: 'fixed',
      bottom: 25,
      right: 25,
      width: 70,
      height: 70,
      background: '#49ABED',
      '&:hover': {
        background: '#49ABED',
      },
      '& img': {
        width: '100%',
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
