import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    mainBox: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 150,
      paddingBottom: '30px !important',
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    titleHeader: {
      color: theme.palette.headerStyled.titleColor,
      letterSpacing: '0.1em',
    },
    subtitleHeader: {
      letterSpacing: '0.1em',
      color: theme.palette.headerStyled.subtitleColor,
    },
    statusDot: {
      minHeight: 20,
      minWidth: 20,
      borderRadius: 9999,
    },
  }),
  { index: 1 },
);

export default useStyles;
