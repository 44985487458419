import React, { useEffect, Suspense, useContext } from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  useNavigate,
} from 'react-router-dom';

import {
  AuthRoute,
  LazyLoading,
  LayoutDoc,
  LayoutLogin,
  LazyLoadingDoc,
  LazyLoadingLogin,
  LayoutAuth,
} from 'components';

import ThemeContext from './config/Context';

// general
const Home = React.lazy(() => import('./containers/home/Home'));
const Payment = React.lazy(() => import('./containers/home/Payment'));

// Login
const Login = React.lazy(() => import('./containers/login/Login'));
const Dashboard = React.lazy(() => import('./containers/login/Dashboard'));
const DownloadDesktop = React.lazy(() =>
  import('./containers/home/DownloadDesktop'),
);

const ForgotPassword = React.lazy(() =>
  import('./containers/login/ForgotPassword'),
);

const ValidateEmail = React.lazy(() =>
  import('./containers/login/ValidateEmail'),
);

const ChangePassword = React.lazy(() =>
  import('./containers/login/ChangePassword'),
);

const InitialRegister = React.lazy(() =>
  import('./containers/login/InitialRegister'),
);

const UserDataRegister = React.lazy(() =>
  import('./containers/login/UserDataRegister'),
);

const HomeAuth = React.lazy(() => import('./containers/login/Home'));

// documentacion
const HomeDoc = React.lazy(() => import('./containers/documentation/HomeDoc'));
const FirstStepDoc = React.lazy(() =>
  import('./containers/documentation/FirstStep'),
);
const PatientsDoc = React.lazy(() =>
  import('./containers/documentation/PatientsDoc'),
);
const SearchPatientsDoc = React.lazy(() =>
  import('./containers/documentation/SearchPatientsDoc'),
);
const RegisterPatientsDoc = React.lazy(() =>
  import('./containers/documentation/RegisterPatientsDoc'),
);
const DeletePatientsDoc = React.lazy(() =>
  import('./containers/documentation/DeletePatientsDoc'),
);
const FormsMainDoc = React.lazy(() =>
  import('./containers/documentation/FormsMainDoc'),
);
const CreateFormsDoc = React.lazy(() =>
  import('./containers/documentation/CreateFormsDoc'),
);
const DeleteFormsDoc = React.lazy(() =>
  import('./containers/documentation/DeleteFormsDoc'),
);
const ExportFormsDoc = React.lazy(() =>
  import('./containers/documentation/ExportFormsDoc'),
);
const CalendarDoc = React.lazy(() =>
  import('./containers/documentation/CalendarDoc'),
);
const CreateCalendarDoc = React.lazy(() =>
  import('./containers/documentation/CreateCalendarDoc'),
);
const CalendarWaitListDoc = React.lazy(() =>
  import('./containers/documentation/CalendarWaitListDoc'),
);
const RegisterModeDoc = React.lazy(() =>
  import('./containers/documentation/RegisterModeDoc'),
);
const NotificationsDoc = React.lazy(() =>
  import('./containers/documentation/NotificationsDoc'),
);
const MedicinesSecuresDoc = React.lazy(() =>
  import('./containers/documentation/MedicinesSecuresDoc'),
);
const ExportDataDoc = React.lazy(() =>
  import('./containers/documentation/ExportDataDoc'),
);

// Calendarios
const Calendar = React.lazy(() => import('./containers/calendar/Calendar'));
const CreateDate = React.lazy(() => import('./containers/calendar/CreateDate'));
const DateDetails = React.lazy(() =>
  import('./containers/calendar/DateDetails'),
);

// Configuracion
const MenuConfig = React.lazy(() => import('./containers/config/MenuConfig'));
const Contact = React.lazy(() => import('./containers/config/Contact'));
const UserDetail = React.lazy(() => import('./containers/config/UserDetail'));
const UserEdit = React.lazy(() => import('./containers/config/UserEdit'));
const ConfigPin = React.lazy(() => import('./containers/config/ConfigPin'));
const ConfigMedicineSecures = React.lazy(() =>
  import('./containers/config/ConfigMedicineSecures'),
);

// Modo Registro
const HomeRegisterMode = React.lazy(() =>
  import('./containers/registerMode/HomeRegisterMode'),
);
const RegisterModePatient = React.lazy(() =>
  import('./containers/registerMode/RegisterModePatient'),
);
const RegisterWaitList = React.lazy(() =>
  import('./containers/registerMode/RegisterWaitList'),
);

// Pacientes
const Patient = React.lazy(() => import('./containers/patients/Patients'));
const RegisterPatient = React.lazy(() =>
  import('./containers/patients/RegisterPatient'),
);
const PatientDetails = React.lazy(() =>
  import('./containers/patients/PatientDetails'),
);

// Formularios
const FormBuilder = React.lazy(() => import('./containers/forms/FormBuilder'));
const FormMenu = React.lazy(() => import('./containers/forms/FormMenu'));
const Recipe = React.lazy(() => import('./containers/forms/Recipe'));

const Router = () => {
  const { pathname } = useLocation();
  const { conf } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.location.href.includes('#')) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      conf.registerMode === 1 &&
      (pathname.includes('/home') ||
        pathname.includes('/calendar') ||
        pathname.includes('/createDate') ||
        pathname.includes('/editDate') ||
        pathname.includes('/calendar') ||
        pathname.includes('/config') ||
        pathname.includes('/config/contact') ||
        pathname.includes('/config/user') ||
        pathname.includes('/config/editUser') ||
        pathname.includes('/config/pin') ||
        pathname.includes('/config/medicines') ||
        pathname.includes('/config/secures') ||
        pathname.includes('/patient') ||
        pathname.includes('/patient/register') ||
        pathname.includes('/patient/edit') ||
        pathname.includes('/patient/detail'))
    ) {
      navigate('/registerMode');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, conf]);

  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/download" element={<DownloadDesktop />} />

        <Route
          element={
            <LayoutLogin>
              <Suspense fallback={<LazyLoadingLogin />}>
                <Outlet />
              </Suspense>
            </LayoutLogin>
          }>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<InitialRegister />} />
          <Route path="/user-register" element={<UserDataRegister />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/validate-email" element={<ValidateEmail />} />
          <Route path="/validate-email-register" element={<ValidateEmail />} />
          <Route path="/forgot" element={<ForgotPassword />} />
        </Route>

        <Route element={<AuthRoute />}>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route
            element={
              <LayoutAuth>
                <Suspense fallback={<LazyLoading />}>
                  <Outlet />
                </Suspense>
              </LayoutAuth>
            }>
            <Route path="/home" element={<HomeAuth />}></Route>
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/createDate" element={<CreateDate />} />
            <Route path="/editDate/:id" element={<CreateDate />} />
            <Route path="/calendar/:id" element={<DateDetails />} />

            <Route path="/config" element={<MenuConfig />} />
            <Route path="/config/contact" element={<Contact />} />
            <Route path="/config/user" element={<UserDetail />} />
            <Route path="/config/editUser" element={<UserEdit />} />
            <Route path="config/pin" element={<ConfigPin />} />
            <Route
              path="/config/medicines"
              element={<ConfigMedicineSecures />}
            />
            <Route path="/config/secures" element={<ConfigMedicineSecures />} />

            <Route path="/patient" element={<Patient />} />
            <Route path="/patient/register" element={<RegisterPatient />} />
            <Route path="/patient/edit/:id" element={<RegisterPatient />} />
            <Route path="/patient/detail/:id" element={<PatientDetails />} />

            <Route path="/forms/:id" element={<FormMenu />} />
            <Route path="/recipe/:id" element={<Recipe />} />
            <Route path="/cadera/:id" element={<FormBuilder />} />
            <Route path="/codo/:id" element={<FormBuilder />} />
            <Route path="/cervical/:id" element={<FormBuilder />} />
            <Route path="/dorsal/:id" element={<FormBuilder />} />
            <Route path="/lumbar/:id" element={<FormBuilder />} />
            <Route path="/hombro/:id" element={<FormBuilder />} />
            <Route path="/mano/:id" element={<FormBuilder />} />
            <Route path="/rodilla/:id" element={<FormBuilder />} />
            <Route path="/pie/:id" element={<FormBuilder />} />
          </Route>

          <Route path="/registerMode" element={<HomeRegisterMode />} />
          <Route
            path="/registerMode/registerPatient"
            element={<RegisterModePatient />}
          />
          <Route path="/registerMode/waitlist" element={<RegisterWaitList />} />
          <Route path="/waitlist" element={<RegisterWaitList />} />
        </Route>

        <Route
          element={
            <LayoutDoc>
              <Suspense fallback={<LazyLoadingDoc />}>
                <Outlet />
              </Suspense>
            </LayoutDoc>
          }>
          <Route path="/documentation" element={<HomeDoc />} />
          <Route path="/first-step" element={<FirstStepDoc />} />
          <Route path="/patients" element={<PatientsDoc />} />
          <Route path="/patients/search" element={<SearchPatientsDoc />} />
          <Route path="/patients/register" element={<RegisterPatientsDoc />} />
          <Route path="/patients/delete" element={<DeletePatientsDoc />} />
          <Route path="/forms" element={<FormsMainDoc />} />
          <Route path="/forms/create" element={<CreateFormsDoc />} />
          <Route path="/forms/delete" element={<DeleteFormsDoc />} />
          <Route path="/forms/export" element={<ExportFormsDoc />} />
          <Route path="/calendarDoc" element={<CalendarDoc />} />
          <Route path="/calendarDoc/new" element={<CreateCalendarDoc />} />
          <Route path="/calendarDoc/list" element={<CalendarWaitListDoc />} />
          <Route path="/register-mode" element={<RegisterModeDoc />} />
          <Route path="/notifications" element={<NotificationsDoc />} />
          <Route path="/medicines-secures" element={<MedicinesSecuresDoc />} />
          <Route path="/export-data" element={<ExportDataDoc />} />
        </Route>

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
