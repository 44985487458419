import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    backgroundFooter: {
      backgroundColor: '#2C2C2C',
      padding: '30px',
      '@media only screen and (max-width : 600px)': {
        padding: '25px',
      },
      '@media only screen and (max-width : 500px)': {
        padding: '20px',
      },
      '@media only screen and (max-width : 400px)': {
        padding: '15px',
      },
      '@media only screen and (max-width : 300px)': {
        padding: '10px',
      },
    },
    footerMsg: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: '600',
      fontSize: '16px',
      letterSpacing: '0.04em',
      color: '#FFFFFF',
      '@media only screen and (max-width : 850px)': {
        fontSize: '14px',
      },
      '@media only screen and (max-width : 600px)': {
        fontSize: '12px',
      },
      '@media only screen and (max-width : 500px)': {
        fontSize: '10px',
      },
      '@media only screen and (max-width : 400px)': {
        fontSize: '8px',
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
