import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    mainBox: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 150,
      paddingBottom: '30px !important',
      [theme.breakpoints.up('md')]: {
        padding: 30,
        paddingTop: 60,
      },
      [theme.breakpoints.down('md')]: {
        padding: 30,
        margin: 0,
      },
      [theme.breakpoints.down('sm')]: {
        padding: 10,
        margin: 0,
      },
    },
    title: {
      letterSpacing: '0.05em',
      textAlign: 'center',
      marginBottom: 50,
      color: theme.palette.home.titleText,
      [theme.breakpoints.down('md')]: {
        marginBottom: 30,
      },
    },
    buttonModules: {
      padding: 10,
      background: '#4E89AE',
      border: '1px solid #2A4D75',
      transition: '0.5s',
      width: 130,
      height: 130,
      margin: '0 30px',
      boxShadow: theme.shadows[3],
      '&:hover': {
        background: '#4E89AE',
        opacity: 0.9,
      },
      [theme.breakpoints.down('md')]: {
        width: 100,
        height: 100,
        margin: '0 15px',
      },
      [theme.breakpoints.down('sm')]: {
        width: 80,
        height: 80,
        margin: '0 10px',
      },
    },
    imageButton: {
      width: 75,
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        width: 55,
      },
      [theme.breakpoints.down('sm')]: {
        width: 45,
      },
    },
    dateBox: {
      background: theme.palette.home.dateInfoBox,
      boxShadow: theme.shadows[3],
      borderRadius: 27,
      flexDirection: 'column',
      padding: 16,
      justifyContent: 'space-between',
    },
    dateDataText: {
      fontSize: 16,
      color: '#FFF',
      wordBreak: 'break-word',
      '@media (max-width:600px)': {
        fontSize: 15,
      },
    },
    buttonCalendarDetail: {
      padding: '4px 12px',
      marginLeft: 10,
    },
    intro: {
      transform: 'rotateX(45deg)',
    },
    anyDates: {
      fontWeight: 600,
      borderRadius: 9999,
      color: theme.palette.home.titleText,
      border: `2px solid ${theme.palette.home.dateInfoBox}`,
      padding: '20px 30px',
      textAlign: 'center',
    },
  }),
  { index: 1 },
);

export default useStyles;
