import React from 'react';
import { useFooterStyles } from 'styles';

const Footer = () => {
  const styles = useFooterStyles();

  return (
    <footer className={styles.backgroundFooter}>
      <p className={styles.footerMsg}>
        Todos los derechos reservados &copy; 2022 TraumApp
      </p>
    </footer>
  );
};

export default Footer;
