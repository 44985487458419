import React from 'react';
import { Typography } from '@mui/material';

const ErrorMessage = ({ errorMsg, ...props }) => (
  <>
    {errorMsg !== '' && (
      <Typography component="p" variant="error" {...props}>
        {errorMsg}
      </Typography>
    )}
  </>
);

export default ErrorMessage;
