import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    imgSize: {
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
        marginTop: 20,
      },
    },
    listItem: {
      marginTop: '7px !important',
    },
    listBox: {
      padding: 20,
    },
  }),
  { index: 1 },
);

export default useStyles;
