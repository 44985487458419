import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    loadingBox: {
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    spinner: {
      height: '40px',
      width: '40px',
      borderRadius: '50%',
      borderBottom: '2px solid #FFF',
      borderLeft: '2px solid #FFF',
      borderTop: '2px solid #FFF',
      borderRight: '2px solid transparent',
      animation: `$spin 1s infinite linear`,
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
