import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    buttonSep: {
      margin: '0px 10px',
    },
    inputSep: {
      marginTop: 30,
      backgroundColor: theme.palette.config.recipeInputBg,
    },
    dscText: {
      color: theme.palette.config.contentText,
    },
  }),
  { index: 1 },
);

export default useStyles;
