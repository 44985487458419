import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    bgHeaderMainBox: {
      backgroundColor: '#2D537D',
      display: 'flex',
      alignItems: 'center',
    },
    titleLoginLogo: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      marginLeft: 20,
      color: '#FFF',
      fontSize: 24,
      zIndex: 999,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        marginLeft: 18,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        marginLeft: 15,
      },
    },
    logoLoginBox: {
      zIndex: 999,
    },
    logoTraumappLogin: {
      margin: '10px 0px 10px 15px',
      width: 60,
      height: 60,
      zIndex: 999,
      [theme.breakpoints.down('sm')]: {
        width: 50,
        height: 50,
      },
    },
    mainBox: {
      flex: '1',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '@media only screen and (max-width : 800px)': {
        flex: '0',
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
    purpleBallsGlobal: {
      position: 'absolute',
      zIndex: '1',
      width: '50%',
      '@media only screen and (max-width : 950px)': {
        width: '55%',
      },
      '@media only screen and (max-width : 850px)': {
        width: '60%',
      },
      '@media only screen and (max-width : 800px)': {
        width: '40%',
      },
    },
    purpleBallsGlobalWithoutPhone: {
      position: 'absolute',
      zIndex: '1',
      width: '30%',
      '@media only screen and (max-width : 950px)': {
        width: '55%',
      },
      '@media only screen and (max-width : 850px)': {
        width: '60%',
      },
      '@media only screen and (max-width : 800px)': {
        width: '40%',
      },
    },
    backgroundBalls1: {
      position: 'absolute',
      zIndex: '0',
      top: '-100px',
      right: '0',
      '@media only screen and (max-width : 800px)': {
        top: '-25px',
      },
    },
    backgroundBalls2: {
      position: 'absolute',
      zIndex: 0,
      bottom: '10px',
      left: 0,
    },
    sectionLoginWithoutPhone: {
      width: '100%',
      '@media only screen and (max-width : 800px)': {
        width: '70%',
      },
      '@media only screen and (max-width : 600px)': {
        width: '80%',
      },
      '@media only screen and (max-width : 500px)': {
        width: '85%',
      },
    },
    sectionPresentationImage: {
      width: '50%',
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '@media only screen and (max-width : 800px)': {
        width: '100% !important',
        marginTop: 50,
        padding: '0px',
        marginBottom: 50,
      },
    },
    sectionPresentationImageWithoutPhone: {
      width: '100%',
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '@media only screen and (max-width : 800px)': {
        width: '100% !important',
        marginTop: 50,
        padding: '0px',
        marginBottom: 50,
      },
    },
    sectionLogin: {
      width: '50%',
      '@media only screen and (max-width : 800px)': {
        width: '70%',
      },
      '@media only screen and (max-width : 600px)': {
        width: '80%',
      },
      '@media only screen and (max-width : 500px)': {
        width: '85%',
      },
    },
    mobile3d: {
      width: '80%',
      maxWidth: 500,
      zIndex: '10',
      '@media only screen and (max-width : 800px)': {
        width: '60%',
      },
    },
    titleLoginImage: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 30,
      padding: '0px 5%',
      letterSpacing: '0.06em',
      zIndex: '9',
      color: '#FFFFFF',
      '@media only screen and (max-width : 800px)': {
        padding: '0px 10%',
        fontSize: 24,
      },
      '@media only screen and (max-width : 400px)': {
        fontSize: 18,
      },
    },
    badgeGoogle: {
      width: 150,
      height: 'auto',
      marginTop: 15,
    },
    badgeBox: {
      alignSelf: 'flex-end',
      zIndex: 10,
    },
    mainLogin: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100vh',
      backgroundColor: '#2D537D',
    },
  }),
  { index: 1 },
);

export default useStyles;
