import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    headerBox: {
      width: '100%',
      padding: '20px 30px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    dividerStyle: {
      margin: '20px 0px',
      borderBottomWidth: 2,
      borderColor: theme.palette.headerStyled.dividerColor,
      [theme.breakpoints.down('sm')]: {
        margin: '10px 0px',
      },
    },
    buttonBack: {
      marginRight: 20,
      color: theme.palette.headerStyled.arrowColor,
      [theme.breakpoints.down('sm')]: {
        marginRight: 5,
      },
    },
    separator: {
      color: theme.palette.headerStyled.separator,
      fontSize: 28,
    },
  }),
  { index: 1 },
);

export default useStyles;
