import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    userName: {
      wordBreak: 'break-word',
    },
    backgroundDrawer: {
      '& .MuiPaper-root': {
        background: `linear-gradient(180deg, ${theme.palette.sidebar.backgroundGradient1} 0%, ${theme.palette.sidebar.backgroundGradient2} 100%)`,
        top: 0,
        minWidth: 150,
        maxWidth: 150,
        [theme.breakpoints.down('md')]: {
          minWidth: 250,
          maxWidth: 250,
        },
      },
    },
    backgroundDrawerMobile: {
      '& .MuiPaper-root': {
        background: `linear-gradient(180deg,${theme.palette.sidebar.backgroundGradient1} 0%, ${theme.palette.sidebar.backgroundGradient2} 100%)`,
        top: 0,
        minWidth: 250,
        maxWidth: 250,
      },
    },
    separatorItem: {
      width: '80%',
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    },
    itemImage: {
      width: 45,
      [theme.breakpoints.down('md')]: {
        width: 30,
        marginRight: 10,
      },
    },
    navButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#FFF',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
      },
    },
    traumAppLogo: {
      width: '70%',
      marginBottom: 6,
      [theme.breakpoints.down('md')]: {
        width: '60%',
      },
    },
    titleLogo: {
      letterSpacing: '0.15em',
      [theme.breakpoints.down('md')]: {
        marginBottom: 8,
      },
    },
    plansItem: {
      backgroundColor: '#09598F !important',
      [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
      },
    },
    plansDirection: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
      },
    },
    menuButton: {
      color: theme.palette.sidebar.hamburguerColor,
      margin: '10px 0px 0px 10px',
    },
    itemMobile: {
      '& span': {
        fontWeight: 500,
        fontSize: 18,
      },
    },
    navBox: {
      padding: 0,
    },
  }),
  { index: 1 },
);

export default useStyles;
