import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    errorBox: {
      borderBottom: '2px solid red',
    },
  }),
  { index: 1 },
);

export default useStyles;
