import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    boxTable: {
      borderRadius: 12,
      border: `2px solid ${theme.palette.patients.borderBoxTable}`,
      '& > .MuiTableContainer-root': {
        borderRadius: '10px 10px 0px 0px',
      },
      '& > .MuiTablePagination-root': {
        borderRadius: '0px 0px 10px 10px',
      },
    },
    rowBox: {
      maxWidth: '20vw',
      wordBreak: 'break-word',
      color: theme.palette.patients.headerTextTable,
      borderWidth: 0,
      [theme.breakpoints.down('md')]: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    headerTextTable: {
      fontWeight: 600,
      color: theme.palette.patients.headerTextTable,
      fontSize: 16,
      borderWidth: 0,
    },
    headerBox: {
      backgroundColor: theme.palette.patients.headerBoxTable,
    },
    footerBox: {
      backgroundColor: theme.palette.patients.footerBgTable,
      color: theme.palette.patients.headerTextTable,
    },
    mainBox: {
      padding: 30,
      background: theme.palette.patients.tableBoxBg,
      boxShadow: theme.shadows[2],
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 16,
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
    },
    inputSearchBox: {
      background: '#EDEDED',
    },
    iconView: {
      color: theme.palette.patients.viewIconColor,
    },
  }),
  { index: 1 },
);

export default useStyles;
