import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    mainBoxFloat: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.config.backgroundBoxDetail,
      borderRadius: 8,
      boxShadow: theme.shadows[2],
      padding: '15px 20px',
      marginBottom: 20,
      height: 'fit-content',
    },
    mainBoxForms: {
      width: '48% !important',
      flexBasis: '48% !important',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        flexBasis: '100% !important',
      },
    },
    inputSeparation: {
      marginTop: 25,
      backgroundColor: theme.palette.config.recipeInputBg,
    },
    buttonSep: {
      marginTop: 30,
    },
  }),
  { index: 1 },
);

export default useStyles;
