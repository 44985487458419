import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import { useBaseDocStyles } from 'styles';
import { settingsSlider } from 'config';

const SliderDoc = ({ children, ...props }) => {
  const baseStyles = useBaseDocStyles();

  return (
    <Slider {...settingsSlider} className={baseStyles.carousel} {...props}>
      {children}
    </Slider>
  );
};

export default SliderDoc;
