import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { documentationItems } from 'config';

import { useHeaderDocStyles } from 'styles';

const DrawerDoc = () => {
  const styles = useHeaderDocStyles();
  const location = useLocation();

  const [open, setOpen] = useState('');

  useEffect(() => {
    documentationItems.map(data => {
      if (data.items.length > 0) {
        if (data.path === location.pathname) {
          setOpen(data.open);
        }
        data.items.map(item => {
          if (item.path === location.pathname) {
            setOpen(data.open);
          }
          return null;
        });
      }
      return null;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = list => {
    if (open === list) {
      setOpen('');
    } else {
      setOpen(list);
    }
  };
  return (
    <Box>
      <List className={styles.rootList} component="nav">
        {documentationItems.map((data, index) => {
          return (
            <div key={`${index.toString()}+${data.path}`}>
              {data.items.length === 0 ? (
                <Link to={data.path}>
                  <ListItem button selected={location.pathname === data.path}>
                    <ListItemText
                      primary={data.name}
                      className={`${styles.listItem} ${
                        location.pathname === data.path && styles.selectedItem
                      }`}
                    />
                  </ListItem>
                </Link>
              ) : (
                <>
                  <Link to={data.path}>
                    <ListItem
                      button
                      onClick={() => handleOpen(data.open)}
                      selected={location.pathname === data.path}
                      key={index.toString()}>
                      <ListItemText
                        primary={data.name}
                        className={`${styles.listItem} ${
                          location.pathname === data.path
                            ? styles.selectedItem
                            : ''
                        }`}
                      />
                      {open === data.open ? (
                        <ExpandLess className={styles.arrowColor} />
                      ) : (
                        <ExpandMore className={styles.arrowColor} />
                      )}
                    </ListItem>
                  </Link>
                  <Collapse
                    in={open === data.open}
                    timeout="auto"
                    unmountOnExit>
                    <List component="div" disablePadding>
                      {data.items.map((item, index) => {
                        return (
                          <Link to={item.path} key={index.toString()}>
                            <ListItem
                              button
                              selected={location.pathname === item.path}>
                              <ListItemText
                                primary={item.name}
                                className={`
                              ${styles.nested}
                              ${styles.listItem}
                              ${
                                location.pathname === item.path &&
                                styles.selectedItem
                              }
                              `}
                              />
                            </ListItem>
                          </Link>
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              )}
            </div>
          );
        })}
      </List>
    </Box>
  );
};

export default DrawerDoc;
