import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    text: {
      color: '#888888',
      textAlign: 'center',
    },
  }),
  { index: 1 },
);

export default useStyles;
